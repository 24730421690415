import { Link } from "react-router-dom";

import classNames from "classnames";

import { Header } from "../Components/Header";
import { CircularArrowIcon } from "../Components/icons/CircularArrowIcon";

import { formatMoneyString } from "../utils/currencyFormatter";

import HowItWorksHeroImage from "../images/HowItWorksHeroImage.png";

const determineConditions = [
  "First, we sort orders by maximum price.",
  "Then, going down that list, we take enough orders to sell our full compute capacity.",
  "Finally, all those orders are executed at the lowest order price of the taken orders.",
];

const orderBookData = [
  { buyer: "James", noOfGPUs: 400, maxPrice: 1.85 },
  { buyer: "Alicia", noOfGPUs: 200, maxPrice: 1.82 },
  { buyer: "Clayton", noOfGPUs: 400, maxPrice: 1.8 },
  { buyer: "Leslie", noOfGPUs: 200, maxPrice: 1.8 },
  { buyer: "Baphomet", noOfGPUs: 100, maxPrice: 1.79 },
];

const exampleInformation = [
  "James’ order would fill, then Alicia’s.",
  "Clayton’s order would fill before Leslie's because although they are the same price, larger orders take precedence over smaller ones.",
  "Leslie’s order will not fill since there is no more capacity. But she is next in line.",
  "Baphomet would be left out in the cold, to contemplate his failings as a technologist.",
];

const executedOrderBookData = [
  { buyer: "James", noOfGPUs: 400, maxPrice: 1.8 },
  { buyer: "Alicia", noOfGPUs: 200, maxPrice: 1.8 },
  { buyer: "Clayton", noOfGPUs: 400, maxPrice: 1.8 },
];

const executedOrderInformation = [
  "Once you receive allocation, you will be notified as soon as your compute is ready and can get started.",
  "Orders don't get allocation, in this case, Leslie’s and Baphomet’s, will stay on the order book until they raise their max price, some running orders finish, or we add more machines.",
  "Orders will not pay over their set max price for the duration of their run.",
];

/*
 * A page containing int18 the "How it Works" page at /how-it-works.
 */
export const HowItWorks = () => {
  return (
    <div className="pb-4 bg-background min-h-page">
      <Header
        title="How It Works"
        description="Voltage Park Exchange is an order book for state-of-the-art compute"
        image={HowItWorksHeroImage}
      />
      <div className="flex justify-center w-full mb-10">
        <div className="px-4 bg-white border rounded-lg shadow-md">
          <div className="flex flex-col items-center justify-center gap-5 px-4 py-8">
            <h3 className="max-w-3xl pt-2 text-base leading-loose text-center md:text-lg text-tertiaryText">
              We run an on-demand spot market for compute.
            </h3>
          </div>
          <div className="flex flex-col items-center justify-center gap-6 px-6 pt-4 pb-8">
            <div className="w-full max-w-4xl space-y-3 text-start">
              <h2 className="text-2xl font-semibold">
                Here’s how winning prices are determined
              </h2>
              <div className="flex flex-col gap-2">
                {determineConditions.map((condition) => (
                  <div className="flex space-x-3">
                    <div className="flex justify-end">
                      <CircularArrowIcon className="mt-0.5 text-brand flex" />
                    </div>
                    <span>{condition}</span>
                  </div>
                ))}
              </div>
              <p className="pt-2">
                All orders pay the same price: the lowest price that allows us
                to sell out our inventory.
              </p>
              <p>
                Here’s an example. Suppose we have 1000 GPUs available, and the
                orders, sorted by descending maximum price, look like this:
              </p>
            </div>
            <div className="w-full max-w-4xl space-y-3 text-start">
              <h2 className="mb-3 text-2xl font-semibold">Order book</h2>
              <div className="relative mb-2 overflow-x-auto rounded-lg shadow-md">
                <table className="w-full text-sm text-left">
                  <thead className="border select-none bg-background">
                    <tr>
                      <th className="p-3 font-semibold">Buyer</th>
                      <th className="p-3 font-semibold"># of GPUs requested</th>
                      <th className="p-3 font-semibold">Maximum $/GPU/hr</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderBookData.map((data, index) => (
                      <tr
                        className={classNames("bg-uiBorder", {
                          "bg-opacity-10": index % 2 === 1,
                          "bg-opacity-30": index % 2 === 0,
                        })}
                      >
                        <td className="p-3 font-medium border-b">
                          {data.buyer}
                        </td>
                        <td className="p-3 font-normal border-b">
                          {data.noOfGPUs}
                        </td>
                        <td className="p-3 font-normal border-b">
                          {formatMoneyString(data.maxPrice)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-full max-w-4xl space-y-3 text-start">
              <h2 className="mb-3 text-2xl font-semibold">In this example</h2>
              <div className="flex flex-col gap-2">
                {exampleInformation.map((condition) => (
                  <div className="flex space-x-3">
                    <div className="flex justify-end">
                      <CircularArrowIcon className="mt-0.5 text-brand flex" />
                    </div>
                    <span>{condition}</span>
                  </div>
                ))}
              </div>
              <blockquote className="pl-4 border-brand border-s-2">
                <p className="italic font-medium leading-relaxed">
                  All of James, Alicia, and Clayton would be charged the lowest
                  price of the set of winning orders, that is, Clayton’s price
                  of $1.90, leaving:
                </p>
              </blockquote>
            </div>
            <div className="w-full max-w-4xl space-y-4 text-start">
              <h2 className="mb-3 text-2xl font-semibold">Allocated Orders</h2>
              <div className="relative mb-2 overflow-x-auto rounded-lg shadow-md">
                <table className="w-full text-sm text-left">
                  <thead className="border select-none bg-background">
                    <tr>
                      <th className="p-3 font-semibold">Buyer</th>
                      <th className="p-3 font-semibold"># of GPUs requested</th>
                      <th className="p-3 font-semibold">Maximum $/GPU/hr</th>
                    </tr>
                  </thead>
                  <tbody>
                    {executedOrderBookData.map((data, index) => (
                      <tr
                        className={classNames("bg-uiBorder", {
                          "bg-opacity-10": index % 2 === 1,
                          "bg-opacity-30": index % 2 === 0,
                        })}
                      >
                        <td className="p-3 font-medium border-b">
                          {data.buyer}
                        </td>
                        <td className="p-3 font-normal border-b">
                          {data.noOfGPUs}
                        </td>
                        <td className="p-3 font-normal border-b">
                          {formatMoneyString(data.maxPrice)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <blockquote className="pl-4 border-brand border-s-2">
                <p className="italic font-medium leading-relaxed">
                  The idea is that on VP Exchange you can bid your maximum price
                  and still be assured that your order will always execute at
                  the lowest price the market is willing to pay.
                </p>
              </blockquote>
              <div className="flex flex-col gap-2">
                {executedOrderInformation.map((condition) => (
                  <div className="flex space-x-3">
                    <div className="flex justify-end">
                      <CircularArrowIcon className="mt-0.5 text-brand flex" />
                    </div>
                    <span>{condition}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full max-w-4xl space-y-3 text-start">
              <p>
                If you have questions, feedback, or a special compute request
                please{" "}
                <Link to="/contact-us" className="text-brand hover:underline">
                  get in touch
                </Link>
                {"."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
