import classNames from "classnames";

/**
 * Loading component
 * Just a shell component for now
 * use when an action is in progress to
 * notify the user that work is happening in the background.
 */
export const Loading = ({
  className,
  description,
}: Partial<{
  description: string;
  className: string;
}>) => (
  <div className={classNames(className)}>
    {description && (
      <span className="font-semibold break-all whitespace-pre-wrap">
        {description}
      </span>
    )}
  </div>
);
