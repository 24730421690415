import { useEffect, useState } from "react";

import { TextInput } from "./forms/TextInput";

import { useSearchParams } from "../hooks/useSearchParams";

/**
 * This component handles introducing a global level search so that the page can set the query params for search item
 */
export const GlobalSearch = () => {
  const { urlSearchParams } = useSearchParams();

  const [globalFilter, setGlobalFilter] = useState(
    urlSearchParams.get("search") || "",
  );

  useEffect(() => {
    if (globalFilter !== urlSearchParams.get("search")) {
      urlSearchParams.set("search", globalFilter);
    }
  }, [globalFilter]);

  return (
    <div className="relative flex items-center mr-2">
      <TextInput
        isSearch={true}
        value={globalFilter}
        placeholder="Search all columns..."
        onChange={(value) => {
          if (globalFilter !== value) {
            setGlobalFilter(value);
          }
        }}
      />
    </div>
  );
};
