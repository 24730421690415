/**
 * Used by GenericInputs to map Key to Label
 */
export const inputKeyToLabel = {
  numberOfGPUs: "# of GPUs",

  pricePerGPU: "Price (GPU/hr)",
  maxPricePerGPU: "Max Price (GPU/hr)",

  numberOfTotalGPUs: "Total GPUs",
  numberOfAllocatedGPUs: "Allocated GPUs",

  sshCreated: "Date Added",

  orderStartDate: "Start Date",
  orderStartBy: "Order Start By",

  orderPriority: "Order Priority",

  accountBalance: "Current Balance",
  newAccountBalance: "New Balance",
  changeBalance: "Delta Balance",

  csvIpAddresses: "CSV of IP Addresses",
  ipAddresses: "IP Addresses",

  description: "Description",

  sshTitle: "Title",
  sshKey: "Key",

  userName: "Name",
  userEmail: "Email Address",

  orderUserId: "Select a user for this new order",

  accountCompany: "Company",

  markOrderAsPaid: "",

  orderAndInstanceNotification: "Order and Instance notifications",
  accountNotification: "Account notifications",
};

/**
 * Used by GenericInputs to map Key to Placeholder
 */
export const inputKeyToPlaceholder: typeof inputKeyToLabel = {
  numberOfGPUs: "Enter # of GPUs - minimum 8",

  pricePerGPU: "Enter price (GPU/hr)",
  maxPricePerGPU: "Enter price (GPU/hr)",

  numberOfTotalGPUs: "Enter total number of GPUs",
  numberOfAllocatedGPUs: "",

  sshCreated: "",

  orderStartDate: "",
  orderStartBy: "",

  orderPriority: "Select priority of the order",

  accountBalance: "",
  newAccountBalance: "",
  changeBalance: "Enter amount",

  csvIpAddresses: "",
  ipAddresses: "Enter IP addresses separated by comma(,)",

  description: "Enter description",

  sshTitle: "Enter title",
  sshKey: "Enter key",

  userName: "Enter name",
  userEmail: "Enter email address",

  orderUserId: "Search by name or email",

  accountCompany: "Enter company name",

  markOrderAsPaid: "",

  orderAndInstanceNotification: "",
  accountNotification: "",
};
