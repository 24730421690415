import { useState } from "react";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";

import {
  useGetUserNotificationPreference,
  useChangeUserNotificationPreference,
} from "../../state/user";

import { InvalidRoute } from "../auth/InvalidRoute";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import { UserNotificationPreference } from "../../types/user";
import {
  InputErrorType,
  InputSelectType,
  InputKeyToInputType,
} from "../forms/types";
import { User } from "../../types/user";

const keyToInput: InputKeyToInputType = {
  orderAndInstanceNotification: "checkbox",
  accountNotification: "checkbox",
};

const keyToOptions: InputSelectType = {
  orderAndInstanceNotification: [
    {
      value: "EmailActionRequiredForOrder",
      required: true,
      disabled: true,
      label: "Email me when an action is required for my order",
      description:
        "Includes when a payment is due, configuring instances, etc.",
    },
    {
      value: "EmailStatusChangedForOrder",
      label: "Email me when my order status has changed",
      description: "Includes new orders, changes to orders, etc.",
    },
    {
      value: "EmailInstanceChanged",
      label: "Email me when my instance has changed",
      description: "Includes when an instance goes online or offline, etc.",
    },
  ],
  accountNotification: [
    {
      value: "EmailBalanceChanged",
      label: "Email me when my balance has changed",
      description:
        "Includes when funds are deposited, balance adjustments, etc.",
    },
    {
      value: "EmailProfileChanged",
      label: "Email me when my profile changed",
      description: "Includes SSH Key changes, etc.",
    },
  ],
};

const NotificationPanel = ({
  userId,
  userEmail,
  userNotificationPreference,
}: {
  userId: string;
  userEmail?: string;
  userNotificationPreference: UserNotificationPreference;
}) => {
  const { changeUserNotificationPreference } =
    useChangeUserNotificationPreference(userId);

  const originalState = {
    orderAndInstanceNotification: {
      EmailActionRequiredForOrder:
        !!userNotificationPreference.EmailActionRequiredForOrder,
      EmailStatusChangedForOrder:
        !!userNotificationPreference.EmailStatusChangedForOrder,
      EmailInstanceChanged: !!userNotificationPreference.EmailInstanceChanged,
    },
    accountNotification: {
      EmailBalanceChanged: !!userNotificationPreference.EmailBalanceChanged,
      EmailProfileChanged: !!userNotificationPreference.EmailProfileChanged,
    },
  };
  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changeUserNotificationPreference({
      ...state.orderAndInstanceNotification,
      ...state.accountNotification,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
  });

  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col p-4 pt-0">
          <div>
            <h1 className="pb-1 text-xl font-semibold leading-7">
              Notifications
            </h1>
            <span className="text-base font-normal leading-tight text-secondaryText">
              {`Update your notification preferences ${
                !!userEmail ? `for ${userEmail}` : "here"
              }.`}
            </span>
          </div>
          <form
            className="p-4 mt-3 bg-white border rounded-lg shadow-md"
            onSubmit={onSubmit}
          >
            <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
              <div className="grid grid-cols-1 gap-4">
                <GenericInputs
                  state={state}
                  originalState={originalState}
                  inputs={keyToInput}
                  options={keyToOptions}
                  errors={errors}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex items-center justify-end gap-6 pt-4 border-t border-uiBorder">
              <Button
                type="button"
                variant="transparent"
                variantClassName="min-w-[120px]"
                disabled={!isSubmitButtonEnabled}
                onClick={() => setState(originalState)}
              >
                Cancel
              </Button>
              <Button
                data-testid="CHANGE_NOTIFICATION_SETTINGS_BUTTON"
                type="submit"
                variant="dark"
                variantClassName="min-w-[160px]"
                disabled={!isSubmitButtonEnabled}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

/**
 * This component shows the full detail of the notification page
 * It mainly shows the checkbox as form allowing for updates related to user's notification preference
 */
export const NotificationsContainer = ({ userInfo }: { userInfo: User }) => {
  const { data, isLoading } = useGetUserNotificationPreference(userInfo.id);

  if (isLoading) {
    return <Loading />;
  }

  if (data && data.notificationPreference) {
    return (
      <NotificationPanel
        userId={userInfo.id}
        userEmail={data.email}
        userNotificationPreference={data.notificationPreference}
      />
    );
  }

  return <InvalidRoute />;
};
