import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import moment from "moment";
import classNames from "classnames";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { PayOrderModal } from "./PayOrderModal";
import { TimerComponent } from "../table/TimerComponent";
import { AgreementComponent } from "../table/AgreementComponent";

import { UpArrowIcon } from "../icons/UpArrowIcon";

import { useGetSshKeys } from "../../state/sshKey";
import { useActivateOrder } from "../../state/order";
import {
  useGetAccountBalances,
  useGetAccountBillingInfo,
} from "../../state/account";

import { statusToTitle } from "./OrderStatusComponent";

import { formatMoneyString } from "../../utils/currencyFormatter";
import { isExpiredOrder } from "../../utils/order";

import { SettingsPathnames } from "../../pages/Settings";
import { DashboardPathnames } from "../../pages/Dashboard";

import { Order } from "../../types/order";
import { BalanceHistory } from "../../types/account";

const getOrderInformation = (order: Order) => {
  const list: {
    label: string;
    testId: string;
    value: string | JSX.Element;
  }[] = [
    {
      label: "Order ID",
      testId: `${order.id}_EXPANDED_ORDER_ID`,
      value: order.id,
    },
    {
      label: "Order Status",
      testId: `${order.id}_EXPANDED_ORDER_STATUS`,
      value: statusToTitle[order.status],
    },
    {
      label: "# of GPUs",
      testId: `${order.id}_EXPANDED_NUMBER_OF_GPUS`,
      value: order.numberOfGPUs.toString(),
    },
    {
      label: "Max Price (GPU/hr)",
      testId: `${order.id}_EXPANDED_MAX_PRICE_PER_GPU`,
      value: formatMoneyString(order.maxPricePerGPU ?? 0),
    },
    {
      label: "Pricing Model",
      testId: `${order.id}_EXPANDED_PRICING_MODEL`,
      value: order.type === "float" ? "Floating" : "Fixed",
    },
  ];

  if (!!order.startAt) {
    const startDateMoment = moment(order.startAt);

    list.push({
      label: "Date Started",
      testId: `${order.id}_EXPANDED_START_DATE`,
      value: (
        <span title={startDateMoment.format("LLL")}>
          {startDateMoment.format("LL")}
        </span>
      ),
    });
  }

  if (!!order.endAt) {
    const endDateMoment = moment(order.endAt);

    list.push({
      label: endDateMoment.isBefore(moment()) ? "Date Ended" : "Date Ending",
      testId: `${order.id}_EXPANDED_END_DATE`,
      value: (
        <span title={endDateMoment.format("LLL")}>
          {endDateMoment.format("LL")}
        </span>
      ),
    });
  }

  return list;
};

const getDetailInformation = (paymentHistory: BalanceHistory) => {
  const list = [
    {
      label: "Transaction ID",
      testId: `${paymentHistory.id}_TRANSACTION_ID`,
      value: <>{paymentHistory.id}</>,
    },
    {
      label: "Amount",
      testId: `${paymentHistory.id}_TRANSACTION_ID`,
      value: formatMoneyString(paymentHistory.updatedAmount ?? 0),
    },
    {
      label: "Type",
      testId: `${paymentHistory.id}_TRANSACTION_TYPE`,
      value: paymentHistory.type,
    },
  ];

  if (!!paymentHistory.createdAt) {
    list.push({
      label: "Date",
      testId: `${paymentHistory.id}_TRANSACTION_DATE`,
      value: (
        <span title={moment(paymentHistory.createdAt).format("L LT")}>
          {moment(paymentHistory.createdAt).format("L")}
        </span>
      ),
    });
  }

  return list;
};

const getOrderDescription = (order: Order) => {
  if (order.status === "not_ready") {
    return "SSH key and payment method or balance required before your order can start.";
  }
  if (order.status === "eligible") {
    return "Won't start until your Max Price is above the Current Price.";
  }
  if (order.status === "allocating" || order.status === "winning") {
    if (!!order.startAt) {
      return `Estimated start time: ${moment(order.startAt).format("LL")}.`;
    } else {
      return "The order will start soon.";
    }
  }
  if (order.status === "paused") {
    return "The order is currently paused. Please activate it now.";
  }
  if (order.status === "active") {
    return "The order is currently active.";
  }
  if (order.status === "closing") {
    return "The order is closing soon.";
  }
  if (order.status === "large") {
    return "The order is too large. Not enough machines are available to fulfill the order.";
  }
  if (order.status === "finished" || order.status === "deleted") {
    if (!!order.endAt) {
      return `The order ended at ${moment(order.endAt).format("LL")}.`;
    } else {
      return "The order has ended.";
    }
  }
  return <></>;
};

const getInformationComponent = (information: {
  label: string;
  testId: string;
  value: string | JSX.Element;
}) => (
  <div key={information.label} className="flex flex-col text-sm">
    <span className="text-xs text-secondaryText">{information.label}</span>
    <span
      data-testid={information.testId}
      className="font-medium break-words whitespace-pre-wrap"
    >
      {information.value}
    </span>
  </div>
);

const CallToActionButton = ({ order }: { order: Order }) => {
  const { pathname } = useLocation();
  const isAdmin = pathname === DashboardPathnames.orders;

  const {
    data: { data: sshKeys } = { data: [] },
    isLoading: isSSHKeysLoading,
  } = useGetSshKeys(order.accountId);
  const { data: billingInfo, isLoading: isBillingInfoLoading } =
    useGetAccountBillingInfo(order.accountId);

  const { activateOrder } = useActivateOrder();

  const [showModal, setShowModal] = useState<string | null>(null);

  if (isSSHKeysLoading || isBillingInfoLoading) {
    return <Loading />;
  }

  if (!isAdmin && sshKeys.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Link to={SettingsPathnames.sshKey}>
          <Button
            data-testid="SETUP_SSH_KEY_BUTTON"
            variant="dark"
            disabled={isAdmin}
            variantClassName="h-[35px] truncate"
          >
            Setup SSH Key
          </Button>
        </Link>
        <span className="flex items-center justify-center pt-2 space-x-2">
          There is no SSH Key associated with the account.
        </span>
      </div>
    );
  }

  if (order.status === "paused") {
    return (
      <>
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Button
            data-testid="ACTIVATE_ORDER_BUTTON"
            variant="dark"
            disabled={isAdmin}
            variantClassName="h-[35px] truncate"
            onClick={() => activateOrder({ id: order.id, unpause: true })}
          >
            Activate Order Now
          </Button>
        </div>
        {!!order.endAt && (
          <div className="flex items-center justify-center pt-2 space-x-2">
            <span>Activate by </span>
            <TimerComponent deadline={order.endAt} />
          </div>
        )}
      </>
    );
  }

  if (isExpiredOrder(order)) {
    return (
      <div className="flex items-center justify-center h-full">
        <div
          data-testid="EXPIRED_INDICATOR"
          className="flex items-center px-2 py-0.5 text-sm font-medium text-center border rounded-lg truncate w-fit text-positive bg-positive bg-opacity-10 border-positive border-opacity-30"
        >
          {isAdmin && `Order has successfully completed!`}
          {!isAdmin && `Your order has successfully completed!`}
        </div>
      </div>
    );
  }

  if (
    (isAdmin && order.status !== "not_ready") ||
    (!isAdmin && billingInfo && !!billingInfo.paymentMethodCount)
  ) {
    return (
      <div className="flex items-center justify-center h-full">
        <a
          href={billingInfo?.url ?? SettingsPathnames.billing}
          data-testid="PAYMENT_METHOD_RECORDED_INDICATOR"
          className={classNames(
            "flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-lg bg-white truncate w-fit",
            {
              "pointer-events-none": isAdmin,
            },
          )}
        >
          {isAdmin && "Check Stripe for payment method!"}
          {!isAdmin && "Payment method on file!"}
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="flex gap-3">
        <Button
          data-testid="PAY_ORDER_MODAL_BUTTON"
          variant="transparent"
          variantClassName="h-[35px] truncate w-[250px]"
          onClick={() => setShowModal("pay-order")}
        >
          Wire Instructions
        </Button>
        <a
          href={billingInfo?.url ?? SettingsPathnames.billing}
          className={classNames("w-full", { "pointer-events-none": isAdmin })}
        >
          <Button
            data-testid="PAYMENT_METHOD_REQUIRED_BUTTON"
            disabled={isAdmin}
            type="button"
            variant="dark"
            variantClassName="h-[35px] w-full truncate"
          >
            Payment Method Required
          </Button>
        </a>
      </div>
      {showModal === "pay-order" && (
        <PayOrderModal
          isOpen={showModal === "pay-order"}
          onClose={() => setShowModal(null)}
          label="Order"
          id={order.id}
        />
      )}
    </>
  );
};

/**
 * This component show order detail component
 * We use this table mostly as sub-component under orders table
 */
export const OrderDetailsComponent = ({ order }: { order: Order }) => {
  const {
    data: { data: paymentHistories } = { data: [] },
    isLoading: isPaymentHistoryLoading,
  } = useGetAccountBalances(order.accountId);
  const orderBalances = paymentHistories.filter(
    (balance) => balance.orderId === order.id,
  );

  const [currentPaymentHistoryIndex, setCurrentPaymentHistoryIndex] =
    useState(0);

  return (
    <>
      <div className="flex w-full gap-5">
        <div className="w-full min-w-[378px]">
          <h3 className="pb-2 text-base font-semibold leading-normal">
            Order Details
          </h3>
          <div className="grid grid-cols-2 gap-2 p-2 px-2 border rounded-lg h-60 bg-background">
            {getOrderInformation(order).map((information) =>
              getInformationComponent(information),
            )}
            <div className="col-start-1 col-end-3 pt-2">
              <AgreementComponent />
            </div>
          </div>
        </div>
        {isPaymentHistoryLoading && <div className="w-full"></div>}
        {!isPaymentHistoryLoading && orderBalances.length === 0 && (
          <div className="w-full min-w-[408px]">
            <h3 className="pb-2 text-base font-semibold leading-normal">
              Order Insight
            </h3>
            <div className="flex flex-col justify-between p-2 px-2 border rounded-lg h-60 bg-background">
              <span
                className={classNames("text-sm leading-tight p-2", {
                  "text-negative": [
                    "not_ready",
                    "eligible",
                    "paused",
                    "large",
                  ].includes(order.status),
                })}
              >
                {getOrderDescription(order)}
              </span>
              <div className="h-16 pt-2">
                <CallToActionButton order={order} />
              </div>
            </div>
          </div>
        )}
        {!isPaymentHistoryLoading &&
          !!orderBalances[currentPaymentHistoryIndex] && (
            <div className="w-full min-w-[408px]">
              <div className="flex justify-between pb-2 text-base font-semibold leading-normal">
                <h3>Payment History</h3>
                <div className="flex items-center justify-center space-x-1">
                  <Button
                    data-testid="PREVIOUS_PAYMENT_HISTORY_BUTTON"
                    disabled={
                      currentPaymentHistoryIndex === paymentHistories.length - 1
                    }
                    onClick={() =>
                      setCurrentPaymentHistoryIndex((index) =>
                        Math.min(paymentHistories.length - 1, index + 1),
                      )
                    }
                  >
                    <UpArrowIcon
                      className={classNames("rotate-90", {
                        "text-name":
                          currentPaymentHistoryIndex ===
                          paymentHistories.length - 1,
                      })}
                    />
                  </Button>
                  <span className="flex justify-end min-w-[35px]">
                    {`${currentPaymentHistoryIndex + 1} / ${
                      paymentHistories.length
                    }`}
                  </span>
                  <Button
                    data-testid="NEXT_PAYMENT_HISTORY_BUTTON"
                    disabled={currentPaymentHistoryIndex === 0}
                    onClick={() =>
                      setCurrentPaymentHistoryIndex((index) =>
                        Math.max(0, index - 1),
                      )
                    }
                  >
                    <UpArrowIcon
                      className={classNames("-rotate-90", {
                        "text-name": currentPaymentHistoryIndex === 0,
                      })}
                    />
                  </Button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 p-2 px-2 border rounded-lg h-60 bg-background">
                {getDetailInformation(
                  paymentHistories[currentPaymentHistoryIndex],
                ).map((information) => getInformationComponent(information))}
                <div className="h-16 col-start-1 col-end-3 pt-2">
                  <CallToActionButton order={order} />
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
