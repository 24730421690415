import { Link } from "react-router-dom";

import { Dialog } from "@headlessui/react";

import { Button } from "../LegacyButton";
import { CloseIcon } from "../icons/CloseIcon";

import { TimerComponent } from "../table/TimerComponent";
import { useAuthentication } from "../auth/AuthUserProvider";

import { formatMoneyString } from "../../utils/currencyFormatter";

const BeneficiaryDetails = (email?: string) => [
  { label: "Business Name", value: "Voltage Park, Inc." },
  { label: "Bank Name", value: "Choice Financial Group" },
  {
    label: "Bank Address",
    value: (
      <span>
        4501 23rd Ave S
        <br />
        Fargo, ND 58104
      </span>
    ),
  },
  { label: "ABA Routing Number", value: "091311229" },
  { label: "Account Number", value: "202362866865" },
  { label: "SWIFT / BIC Code", value: "CHFGUS44021" },
  { label: "Type of Account", value: "Business Checking" },
  { label: "Memo", value: email || "-" },
];

export const PaymentInstructions = (email?: string) => [
  {
    label: "Wire Instructions",
    value: (
      <div className="flex justify-between">
        <div className="w-full p-3 border rounded-md bg-background">
          <div className="flex flex-col pt-1 space-y-3">
            {BeneficiaryDetails(email).map((detail, index) => (
              <div key={index} className="flex flex-col">
                <span className="text-sm leading-none text-tertiaryText">
                  {detail.label}
                </span>
                <span className="pt-1 text-base font-medium leading-normal">
                  {detail.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
  },
  {
    label: "Management Console",
    value:
      "Your account will be credited as soon as funds settle and you will be granted access to the management console.",
  },
  {
    label: "Help",
    value: (
      <div>
        Contact us{" "}
        <Link
          to="/contact-us"
          target="_blank"
          className="text-brand hover:underline"
        >
          here
        </Link>{" "}
        for more information.
      </div>
    ),
  },
];

interface PayOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  label?: string;
  id?: string;
  payBy?: Date;
  costOfGPUs?: number;
}

/**
 * This modal show the user payment instruction when the Pay Now button is clicked
 */
export const PayOrderModal = ({
  isOpen,
  onClose,
  label = "Order",
  id,
  payBy,
  costOfGPUs,
}: PayOrderModalProps) => {
  const { userInfo } = useAuthentication();

  return (
    <Dialog
      id="pay-order-modal"
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          <div className="flex items-center justify-between">
            <h1
              data-testid="PAY_ORDER_MODAL_HEADER"
              className="text-xl font-semibold leading-10 tracking-wide"
            >
              Payment Instructions
            </h1>
            <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
              <CloseIcon className="text-name hover:scale-110" />
            </Button>
          </div>
          {!!payBy && (
            <div className="pb-2">
              <TimerComponent deadline={payBy} />
            </div>
          )}
          <span className="font-normal leading-tight text-secondaryText">
            {!!id && (
              <div>
                {`${label} ID: `}
                <span className="font-semibold">{id}</span>
              </div>
            )}
            {!!costOfGPUs && (
              <div className="pt-1">
                {`${label} Cost: `}
                <span className="font-semibold">
                  {formatMoneyString(costOfGPUs ?? 0)}
                </span>
              </div>
            )}
          </span>
          <div className="block w-full pt-2">
            {PaymentInstructions(userInfo?.email).map((instruction, index) => (
              <div key={index} className="flex flex-col pb-2 text-left">
                <span className="pb-1 font-semibold">{instruction.label}</span>
                <span className="text-base">{instruction.value}</span>
              </div>
            ))}
          </div>

          <div className="flex justify-center">
            <Button
              data-testid="CLOSE_BUTTON"
              type="button"
              variant="transparent"
              variantClassName="w-full"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
