import { useEffect } from "react";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { DeleteIcon } from "../icons/DeleteIcon";

import { useDeleteInstanceMachine } from "../../state/instance";

interface DeleteMachineModalContentProps {
  onClose: () => void;
  instanceId: string;
  machineId: string;
}

/**
 * This component shows content related to deleting a machine
 * Giving you title, description and buttons for action
 */
export const DeleteMachineModalContent = ({
  onClose,
  machineId,
  instanceId,
}: DeleteMachineModalContentProps) => {
  const { deleteInstanceMachine, isSuccess } = useDeleteInstanceMachine();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex items-center justify-between mb-1">
        <div className="p-3 rounded-full bg-negative bg-opacity-20 w-fit">
          <DeleteIcon className="text-negative" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="DELETE_MACHINE_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Delete Machine
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        {`Are you sure you want to delete Machine#${machineId}?`}
      </span>
      <div className="py-4 text-secondaryText">
        <span>
          This action cannot be undone.{" "}
          <span className="font-semibold underline">
            This will permanently delete this instance.
          </span>
        </span>
      </div>
      <div className="flex items-center justify-center gap-4 pb-1">
        <Button
          data-testid="CANCEL_BUTTON"
          type="button"
          variant="transparent"
          variantClassName="w-1/2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="DELETE_MACHINE_BUTTON"
          type="button"
          variant="red"
          variantClassName="w-1/2"
          onClick={() =>
            deleteInstanceMachine({
              id: machineId,
              instanceId,
            })
          }
        >
          Delete
        </Button>
      </div>
    </>
  );
};
