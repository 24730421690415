import classNames from "classnames";

import RCurrencyInput, {
  CurrencyInputProps as RCurrencyInputProps,
} from "react-currency-input-field";

import { Button } from "../LegacyButton";
import { LockIcon } from "../icons/LockIcon";
import { CrossIcon } from "../icons/CrossIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

const StepOfCurrency = 0.5;

const getMaxLength = (value: number) => (value > 0 ? 10 : 11);

export const getInvalidCurrencyMessage = (
  value: string,
  type: "any-currency" | "currency" | "positive-currency",
) => {
  if (
    !value ||
    (!Number.isNaN(+value) && value.length <= getMaxLength(+value))
  ) {
    if (type === "positive-currency" && +value <= 0) {
      return "Value should be greater than zero";
    }
    if (type === "currency" && +value < 0) {
      return "Value should not be less than zero";
    }

    return "";
  }

  if (value.length > 10) {
    return "Value is too long (maximum 10 characters)";
  }

  return "Please enter a valid price";
};

type CurrencyInputProps = {
  id?: string;
  amount?: string;
  originalAmount?: number;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  allowNegativeValue?: boolean;
  setErrorMessage?: (value: string) => void;
  setAmount: (value: string) => void;
  additionalText?: string;
};

/**
 *  Allows user to enter amount which includes:
 * - Currency Input
 * - Cross Button to revert to original amount
 * - Error border if error flag is received
 */
export const CurrencyInput = ({
  id,
  amount = "",
  originalAmount = 0,
  allowNegativeValue = false,
  placeholder = "Enter amount",
  required,
  isError,
  disabled,
  setAmount,
  setErrorMessage,
  additionalText,
}: CurrencyInputProps) => {
  const handleAmountChange: RCurrencyInputProps["onValueChange"] = (
    updatedAmount,
  ) => {
    const newAmount = updatedAmount ?? "";
    let newError = "";

    // Received invalid number
    if (Number.isNaN(+newAmount)) {
      newError = "Please enter a valid price";
    }

    setAmount(newAmount);
    setErrorMessage?.(newError);
  };

  return (
    <div className="relative flex items-center w-full">
      <div className="absolute inset-y-0 right-0 flex items-center p-2.5">
        {!!originalAmount && +amount !== originalAmount && (
          <Button
            type="button"
            onClick={() =>
              setAmount(originalAmount > 0 ? originalAmount.toString() : "")
            }
          >
            <CrossIcon className="text-name" />
          </Button>
        )}
        {!!disabled && <LockIcon className="text-name" />}
        {additionalText && (
          <span className="ml-2 text-secondaryText">{additionalText}</span>
        )}
      </div>
      <RCurrencyInput
        id={id}
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        value={amount}
        disabled={disabled}
        required={required}
        onValueChange={handleAmountChange}
        onFocus={() => amount && setAmount(amount)}
        placeholder={placeholder}
        prefix="$"
        step={StepOfCurrency}
        min={allowNegativeValue ? undefined : 0}
        allowNegativeValue={allowNegativeValue}
        className={classNames(
          "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 flex-1 border",
          {
            "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
              !isError,
            "border-negative focus:ring-negative focus:ring-offset-negative":
              !!isError,
            "pr-8": +amount !== originalAmount,
          },
        )}
      />
    </div>
  );
};
