import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { App } from "./App";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import { queryClient } from "./state/queryClient";
import { NotificationsProvider } from "./context/notification";
import { appVersion, isLocal, isDev } from "./utils/env";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

//Initialize GA4
if (!isDev && !isLocal) ReactGA.initialize("G-GYRKP7CKH6");

root.render(
  <HelmetProvider>
    <React.StrictMode>
      <NotificationsProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </NotificationsProvider>
    </React.StrictMode>
  </HelmetProvider>,
);

const SendAnalytics = () => {
  if (!isDev && !isLocal) {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: document.title,
      search: window.location.search,
      environment: process.env.REACT_APP_ENV,
      version: appVersion,
    });
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
