import { useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { Tooltip } from "../tooltip/Tooltip";
import { CopyIcon } from "../icons/CopyIcon";
import { MachinesTable } from "./MachinesTable";
import { TimerComponent } from "../table/TimerComponent";
import { AgreementComponent } from "../table/AgreementComponent";
import { UnconfiguredClusterComponent } from "./UnconfiguredClusterComponent";

import { useGetSshKeys } from "../../state/sshKey";
import { useActivateOrder } from "../../state/order";
import {
  useGetInstanceConfig,
  useGetInstanceMachines,
} from "../../state/instance";

import { isRunningOrder } from "../../utils/order";
import { copyTextToClipboard } from "../../utils/clipboard";

import { SettingsPathnames } from "../../pages/Settings";

import { Config, Instance } from "../../types/instance";

const getFormattedInfo = (instance: Instance, config?: Config) => {
  return [
    {
      label: "Config Chosen",
      value: config?.[instance.configId] ?? "",
    },
  ];
};

/**
 * This component handles showing Machines Table
 * Also, it verifies if SSH Key exists for the account and machines are actually allocated, else respective message is shown
 */
export const InstancesMachines = ({
  isAdmin = false,
  instance,
  accountId,
}: {
  isAdmin?: boolean;
  instance: Instance;
  accountId: string;
}) => {
  const {
    data: { data: sshKeys } = { data: [] },
    isLoading: isSSHKeysLoading,
  } = useGetSshKeys(accountId);
  const {
    data: { data: machines } = { data: [] },
    isLoading: isMachinesLoading,
  } = useGetInstanceMachines(instance.id);

  const { activateOrder } = useActivateOrder();

  const onCopyButtonClick = () => {
    const ipString = machines?.map((machine) => machine.privateIP).toString();
    copyTextToClipboard(ipString ?? "No valid ips to be copied")
      .then(() => console.log("Copied IPs to clipboard"))
      .catch((err) => console.log("Failed to copy IPs to clipboard " + err));
  };

  const getIPsComponent = () => {
    if (!isAdmin) {
      if (instance.status === "paused") {
        return (
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center justify-center">
              <Button
                data-testid="ACTIVATE_ORDER_BUTTON"
                variant="dark"
                variantClassName="w-full mt-3"
                onClick={() =>
                  activateOrder({ id: instance.id, unpause: true })
                }
              >
                Activate Instance Now
              </Button>
              {!!instance.endAt && (
                <div className="flex items-center justify-center pt-2 space-x-2">
                  <span>Activate by </span>
                  <TimerComponent deadline={instance.endAt} />
                </div>
              )}
            </div>
          </div>
        );
      }

      if (isSSHKeysLoading) {
        return (
          <span className="flex items-center justify-center h-full text-sm font-medium leading-loose text-secondaryText">
            Verifying SSH Key
          </span>
        );
      }

      if (sshKeys.length === 0) {
        return (
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center justify-center">
              <Link to={SettingsPathnames.sshKey} className="w-full mt-3">
                <Button
                  data-testid="SETUP_SSH_KEY_BUTTON"
                  type="button"
                  variant="dark"
                  variantClassName="w-full"
                >
                  Setup SSH Key
                </Button>
              </Link>
              <span className="my-3 text-sm font-normal leading-tight text-center text-tertiaryText">
                There is no SSH Key associated with the account.
              </span>
            </div>
          </div>
        );
      }
    }

    if (!isRunningOrder(instance)) {
      return (
        <span className="flex items-center justify-center h-full text-sm font-medium leading-loose text-secondaryText">
          Inactive Instance Group
        </span>
      );
    }

    if (!machines || machines.length === 0) {
      return (
        <span className="flex items-center justify-center h-full text-sm font-medium leading-loose text-secondaryText">
          {isMachinesLoading && "Fetching assigned IPs"}
          {!isMachinesLoading && "Waiting to be assigned"}
        </span>
      );
    }

    return (
      <MachinesTable
        instanceId={instance.id}
        machines={machines}
        isAdmin={isAdmin}
      />
    );
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between pb-2 h-9">
        <h3 className="pb-2 text-base font-semibold leading-normal">
          {`Total Machines: ${machines.length}`}
        </h3>
        {machines && machines.length !== 0 && (
          <>
            <Button
              className="copyIPAddress flex items-center px-2 py-0.5 text-sm text-center border rounded-lg"
              onMouseOver={() => onCopyButtonClick()}
              onClick={() => onCopyButtonClick()}
            >
              <CopyIcon className="mr-1 hover:scale-110 text-secondaryText" />
              Copy IP Addresses
            </Button>
            <Tooltip id="copyIPAddress" message="Copied" />
          </>
        )}
      </div>
      <div
        className={classNames({
          "h-52": isAdmin,
          "flex flex-col gap-2 p-2 px-2 overflow-y-auto border rounded-lg bg-background":
            !machines || machines.length === 0,
        })}
      >
        {getIPsComponent()}
      </div>
    </div>
  );
};

/**
 * This component handles waiting status after selecting an Image
 */
export const ConfiguredClusterComponent = ({
  isAdmin = false,
  instance,
  accountId,
}: {
  isAdmin?: boolean;
  instance: Instance;
  accountId: string;
}) => {
  const { data: config, isLoading: isConfigLoading } = useGetInstanceConfig();

  const [isDetailsVisible, setIsDetailsVisible] = useState(
    instance.configId > 0,
  );

  if (isConfigLoading) {
    return (
      <Loading
        className="flex justify-center p-4 font-semibold"
        description="Loading"
      />
    );
  }

  return (
    <div className="flex w-full gap-5">
      {!isDetailsVisible && (
        <UnconfiguredClusterComponent
          instance={instance}
          onConfigurationUpdate={() => setIsDetailsVisible(true)}
        />
      )}
      {isDetailsVisible && (
        <div>
          <div className="flex items-center justify-between pb-2 h-9">
            <h3 className="text-base font-semibold leading-normal">Details</h3>
            <AgreementComponent />
          </div>
          <div className="w-96 h-52">
            <div className="flex flex-col gap-2 p-2 px-2 overflow-y-auto border rounded-lg bg-background h-3/4">
              {getFormattedInfo(instance, config).map(({ label, value }) => (
                <div key={value} className="flex flex-col p-2 text-sm">
                  <span className="text-xs text-secondaryText">{label}</span>
                  <span className="font-medium break-words whitespace-pre-wrap">
                    {value}
                  </span>
                </div>
              ))}
            </div>
            <Button
              data-testid="EDIT_CONFIGURATION_BUTTON"
              disabled={!isRunningOrder(instance)}
              type="submit"
              variant="transparent"
              variantClassName="mt-4 w-full"
              onClick={() => setIsDetailsVisible(false)}
            >
              Edit Configuration
            </Button>
          </div>
        </div>
      )}
      <InstancesMachines
        instance={instance}
        isAdmin={isAdmin}
        accountId={accountId}
      />
    </div>
  );
};
