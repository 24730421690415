import { useState } from "react";

import { Button } from "../LegacyButton";

import { useChangeUserName } from "../../state/user";

import { canFormBeSubmitted } from "../forms/validator";
import { GenericInputs } from "../forms/GenericInputs";

import { User } from "../../types/user";
import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";

const keyToInput: InputKeyToInputType = {
  userName: "text",
  userEmail: "disabled",
};

const requiredKey: RequiredInputKeyType = {
  userName: true,
};

/**
 * This component shows the full detail of the profile page
 * It mainly shows the user information as a form allowing for updates
 * Deals with updating name & company of the user
 */
export const ProfileContainer = ({ userInfo }: { userInfo: User }) => {
  const { changeUserName } = useChangeUserName();
  const originalState = {
    userName: userInfo.name ?? "",
    userEmail: userInfo.email ?? "",
  };
  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    changeUserName({ id: userInfo.id, name: state.userName });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col p-4 pt-0">
          <div>
            <h1 className="pb-1 text-xl font-semibold leading-7">
              Profile info
            </h1>
            <span className="text-base font-normal leading-tight text-secondaryText">
              Update your profile details here.
            </span>
          </div>
          <form
            className="p-4 pt-2 mt-3 bg-white border rounded-lg shadow-md"
            onSubmit={onSubmit}
          >
            <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
              <div className="grid grid-cols-1 gap-4">
                <GenericInputs
                  state={state}
                  originalState={originalState}
                  inputs={keyToInput}
                  required={requiredKey}
                  errors={errors}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex items-center justify-end gap-6 pt-4 border-t border-uiBorder">
              <Button
                type="button"
                variant="transparent"
                variantClassName="min-w-[120px]"
                disabled={!isSubmitButtonEnabled}
                onClick={() => setState(originalState)}
              >
                Cancel
              </Button>
              <Button
                data-testid="CHANGE_PROFILE_SETTINGS_BUTTON"
                type="submit"
                variant="dark"
                variantClassName="min-w-[160px]"
                disabled={!isSubmitButtonEnabled}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
