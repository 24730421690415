import React from "react";
import classNames from "classnames";

export const Button = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  const { className, disabled } = props;

  const buttonProps = {
    ...props,
    className: classNames(
      className,
      "px-6 py-2.5 h-10 text-[0.9375rem] bg-[#CBFC55] rounded-[1.25rem] border border-black",
      { ["opacity-40"]: disabled },
    ),
  };

  return <button {...buttonProps} />;
};
