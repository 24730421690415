import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";

import { queryClient } from "./queryClient";

import { genericCacheHandler } from "../utils/cacheHandler";
import { getValidDate } from "../utils/timeLengthFormatter";

import { UserKey } from "./user";

import { User } from "../types/user";
import {
  Account,
  AccountUser,
  AccountStats,
  BalanceDetail,
  AccountBilling,
  BalanceHistory,
  ChangeBalanceRequest,
  DeleteAccountUserRequest,
  InviteAccountUserRequest,
  ChangeAccountCompanyRequest,
  UpdateAccountMembershipRoleRequest,
} from "../types/account";

enum AccountKey {
  /** query key to fetch all accounts for admin */
  ACCOUNT_LIST_ALL = "account-list-all",

  /** query key to get account info */
  ACCOUNT_INFO = "account-info",

  /** query key to get account stats */
  ACCOUNT_STATS = "account-stats",

  /** query key to get account billing info */
  ACCOUNT_BILLING_INFO = "account-billing-info",

  /** query key to get account balance */
  ACCOUNT_BALANCE = "account-balance",

  /** query key to fetch all balance history for an account */
  ACCOUNT_BALANCE_LIST = "account-balance-list",

  /** query key to fetch all balance detail for an account ledger */
  ACCOUNT_BALANCE_DETAIL_LIST = "account-balance-detail-list",

  /** query key to fetch all users in an account */
  ACCOUNT_USER_LIST = "account-user-list",

  /** query key to download all accounts */
  DOWNLOAD_ACCOUNT_LIST = "download-account-list",

  /** change account company */
  CHANGE_ACCOUNT_COMPANY = "change-account-company",

  /** change account balance */
  CHANGE_ACCOUNT_BALANCE = "change-account-balance",

  /** add a new user to the account */
  INVITE_ACCOUNT_USER = "invite-account-user",

  /** delete a user from the account */
  DELETE_ACCOUNT_USER = "delete-account-user",

  UPDATE_MEMBERSHIP_ROLE = "update-membership-role",
}

/** retrieve the list of accounts from the server */
const getAccounts = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/account`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const accounts: Account[] | undefined = response.data?.accounts?.map(
    (item: any) => {
      return {
        id: item.id,
        company: item.company ?? "",
        balance: (item.balance || 0) / 100,
        paymentLimit: (item.cardLimit || 0) / 100,
      };
    },
  );
  return { data: accounts || [] };
};

/** retrieve the list of accounts */
export const useGetAccounts = () => {
  return useQuery<{ data: Account[] }, Error>(
    [AccountKey.ACCOUNT_LIST_ALL],
    getAccounts,
  );
};

const getAccountStats = async () => {
  // make the api-server request
  // const url = `${process.env.REACT_APP_API_URL}/api/account/stats`;
  // const response = await axios.get(url);

  // transform the result to the proper shape
  const userStats: AccountStats = {
    // ordersCount: response.data?.ordersCount ?? 0,
    // instancesCount: response.data?.instancesCount ?? 0,
    ordersCount: 0,
    instancesCount: 0,
  };
  return userStats;
};

/**
 * Retrieves the account stats from the backend
 * !!! DISABLED BY DEFAULT make sure to call refetch when required
 */
export const useGetAccountStats = () => {
  return useQuery<AccountStats, Error>(
    [AccountKey.ACCOUNT_STATS],
    getAccountStats,
    { enabled: false },
  );
};

/** retrieve the account info from the server */
const getAccountInfo = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${accountId}`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const accountInfo: Account = {
    id: response.data?.id,
    company: response.data?.company ?? "",
    balance: (response.data?.balance || 0) / 100,
    paymentLimit: (response.data?.cardLimit || 0) / 100,
  };
  return accountInfo;
};

/** retrieve the account info */
export const useGetAccountInfo = (accountId: string) => {
  return useQuery<Account, Error>([AccountKey.ACCOUNT_INFO, accountId], () =>
    getAccountInfo(accountId),
  );
};

/** retrieve the account's billing info from the server */
const getAccountBillingInfo = async (accountId: string) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${accountId}/billing`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const accountBilling: AccountBilling = {
    url: response.data?.url || "",
    paymentMethodCount: response.data?.paymentMethodCount || 0,
  };

  return accountBilling;
};

/** Retrieves the accounts's billing info from the backend */
export const useGetAccountBillingInfo = (accountId: string) => {
  return useQuery<AccountBilling, Error>(
    [AccountKey.ACCOUNT_BILLING_INFO],
    () => getAccountBillingInfo(accountId),
  );
};

/** retrieve the account balance from the server */
const getAccountBalance = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${accountId}/balance`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const balance: number | null = !!+response.data?.amount
    ? +response.data?.amount / 100
    : 0;
  return balance;
};

/** retrieve the account balance */
export const useGetAccountBalance = (accountId: string) => {
  return useQuery<number, Error>(
    [AccountKey.ACCOUNT_BALANCE, accountId],
    () => getAccountBalance(accountId),
    {
      onSuccess: (request) => {
        genericCacheHandler([AccountKey.ACCOUNT_LIST_ALL], {
          id: accountId,
          balance: request,
        });
      },
    },
  );
};

/** retrieve the list of balance history for an account from the server */
const getAccountBalances = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${accountId}/balance/history`;
  const response = await axios.get(url);

  // Calculate the final amount ie. balance of user
  let balance = 0;
  response.data?.ledger?.forEach((item: any) => {
    balance += item.amount;
  });

  // transform the result to the proper shape
  const balanceHistories: BalanceHistory[] | undefined =
    response.data?.ledger?.map((item: any) => {
      const updatedAmount = item.amount;
      const totalAmount = balance;
      // Calculate the final amount ie. balance of user going against the updated amount
      balance = totalAmount - updatedAmount;
      return {
        id: item.id,
        accountId: item.accountID ?? undefined,
        adminId: item.adminID ?? undefined,
        orderId: item.orderID ?? undefined,
        paymentId: item.paymentID ?? undefined,
        createdAt: getValidDate(item.createdAt),
        type: item.type,
        updatedAmount: updatedAmount / 100,
        totalAmount: totalAmount / 100,
      };
    });
  return { data: balanceHistories || [] };
};

/** retrieve the list of balance history for an account */
export const useGetAccountBalances = (accountId: string) => {
  return useQuery<{ data: BalanceHistory[] }, Error>(
    [AccountKey.ACCOUNT_BALANCE_LIST, accountId],
    () => getAccountBalances(accountId),
  );
};

/** retrieve the list of balance details for an account ledger from the server */
export const getAccountBalanceDetails = async (ledgerId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/ledger/${ledgerId}/detail`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const balanceDetails: BalanceDetail[] | undefined =
    response.data?.balances?.map((item: any) => {
      return {
        orderId: item.orderID,
        chargedAmount: !!item.total ? +item.total / 100 : 0,
      };
    });
  return { data: balanceDetails || [] };
};

/** Get the list of balance details for an account ledger */
export const useGetAccountBalanceDetails = (ledgerId: string) => {
  return useQuery<{ data: BalanceDetail[] }, Error>(
    [AccountKey.ACCOUNT_BALANCE_DETAIL_LIST, ledgerId],
    () => getAccountBalanceDetails(ledgerId),
  );
};

/** retrieve the list of users for an account from the server */
const getAccountUsers = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${accountId}/users`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const accountUsers: AccountUser[] | undefined = response.data?.users?.map(
    (item: any) => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        accountRole: item.accountRole,
      };
    },
  );
  return { data: accountUsers || [] };
};

/** retrieve the list of users for an account */
export const useGetAccountUsers = (accountId: string) => {
  return useQuery<{ data: AccountUser[] }, Error>(
    [AccountKey.ACCOUNT_USER_LIST, accountId],
    () => getAccountUsers(accountId),
  );
};

/** Send a change account's company name request to the server */
const changeAccountCompany = async (params: ChangeAccountCompanyRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${params.id}`;
  await axios.patch(url, params);
  return params;
};

/** Change account company */
export const useChangeAccountCompany = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    ChangeAccountCompanyRequest,
    Error,
    ChangeAccountCompanyRequest
  >([AccountKey.CHANGE_ACCOUNT_COMPANY], changeAccountCompany);
  return { changeAccountCompany: mutate, isError, isSuccess, isLoading };
};

/** Send a change account's company name request to the server */
const updateAccountMembership = async (
  params: UpdateAccountMembershipRoleRequest,
) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${params.id}/membership`;
  await axios.put(url, params);
  return params;
};

export const useUpdateAccountMembership = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateAccountMembershipRoleRequest,
    Error,
    UpdateAccountMembershipRoleRequest
  >([AccountKey.UPDATE_MEMBERSHIP_ROLE], updateAccountMembership);
  return { updateAccountMembership: mutate, isError, isSuccess, isLoading };
};

/** Send a change account balance request to the server */
const changeAccountBalance = async (params: ChangeBalanceRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${params.id}/balance`;
  const body = {
    accountID: params.id,
    amount: params.amount * 100,
  };
  const response = await axios.post(url, body);
  return { ...params, orderIds: response.data?.orderIDs || [] };
};

/** Change account balance */
export const useChangeAccountBalance = () => {
  const { mutate, isError, isSuccess, isLoading, data } = useMutation<
    ChangeBalanceRequest & { orderIds: string[] },
    Error,
    ChangeBalanceRequest
  >([AccountKey.CHANGE_ACCOUNT_BALANCE], changeAccountBalance);
  return { changeAccountBalance: mutate, isError, isSuccess, isLoading, data };
};

/** Send a invite account user request to the server */
const inviteAccountUser = async (params: InviteAccountUserRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${params.id}/user`;
  await axios.post(url, params);
  return params;
};

/** Invite a user to an account */
export const useInviteAccountUser = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    InviteAccountUserRequest,
    Error,
    InviteAccountUserRequest
  >([AccountKey.INVITE_ACCOUNT_USER], inviteAccountUser);

  return { inviteAccountUser: mutate, isError, isSuccess, isLoading };
};

/** Send a delete account user request to the server */
const deleteAccountUser = async (params: DeleteAccountUserRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/account/${params.id}/user?userID=${params.userId}`;
  await axios.delete(url);
  return params;
};

/** Delete a account user */
export const useDeleteAccountUser = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    DeleteAccountUserRequest,
    Error,
    DeleteAccountUserRequest
  >([AccountKey.DELETE_ACCOUNT_USER], deleteAccountUser);

  return { deleteAccountUser: mutate, isError, isSuccess, isLoading };
};

/** Refetch accounts list */
export const refetchAccounts = (accountId: string) => {
  const accounts: { data: Account[] } | undefined = queryClient.getQueryData([
    AccountKey.ACCOUNT_LIST_ALL,
  ]);
  if (accounts?.data?.find((account) => account.id === accountId)) {
    queryClient.invalidateQueries([AccountKey.ACCOUNT_LIST_ALL]);
    queryClient.invalidateQueries([AccountKey.ACCOUNT_BALANCE_LIST, accountId]);
    queryClient.invalidateQueries([AccountKey.ACCOUNT_BALANCE_DETAIL_LIST]);
  }
};

/** Refetch account info */
export const refetchAccountInfo = (accountId: string) => {
  const userInfo: User | undefined = queryClient.getQueryData([
    UserKey.USER_INFO,
  ]);

  if (accountId && userInfo?.accountIds.includes(accountId)) {
    queryClient.invalidateQueries([AccountKey.ACCOUNT_INFO, accountId]);
    queryClient.invalidateQueries([AccountKey.ACCOUNT_BALANCE, accountId]);
  }
};

/** Clear account stats */
export const clearAccountStats = () => {
  queryClient.removeQueries([AccountKey.ACCOUNT_STATS]);
};
