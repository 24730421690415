import moment from "moment";
import classNames from "classnames";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

export const getInvalidTimeMessage = (
  value?: Date,
  isPastTimeAccepted = false,
) => {
  const timeMoment = moment(value);

  if (!timeMoment.isValid()) {
    return "Please enter a valid time";
  }

  if (!isPastTimeAccepted && timeMoment.isBefore(moment())) {
    return "Past Time is not supported";
  }

  return "";
};

type TimePickerProps = {
  id?: string;
  value?: Date;
  required?: boolean;
  originalValue?: Date;
  isError?: boolean;
  placeholder?: string;
  onChange: (value: Date) => void;
};

/**
 *  Allows user to enter respective time
 */
export const TimePicker = ({
  id,
  value,
  required,
  originalValue,
  placeholder = "Enter Time(HH:MM)",
  isError,
  onChange,
}: TimePickerProps) => {
  function onValueChange(timeString: string) {
    const isValidDate = !!(timeString && timeString !== "");
    const newMoment = moment(
      isValidDate ? timeString : (originalValue as Date),
      isValidDate ? "HH:mm" : undefined,
    );
    if (newMoment.isValid()) {
      onChange(newMoment.toDate());
    }
  }

  return (
    <input
      className={classNames(
        "text-[0.9em] rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border",
        {
          "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
            !isError,
          "border-negative focus:ring-negative focus:ring-offset-negative":
            !!isError,
        },
      )}
      id={id}
      data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
      type="time"
      required={required}
      placeholder={placeholder}
      onChange={(e) => onValueChange(e.target.value)}
      onFocus={() => value && onChange(value)}
      value={moment(value as Date).format("HH:mm")}
    />
  );
};
