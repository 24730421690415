import { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { Accordion } from "../Components/Accordion";
import {
  Connectivity,
  ConnectivityOption,
  DefaultGPUCount,
  InvalidInfinibandGPUCounts,
} from "../Components/demand/constants";
import { GPUCountSelect } from "../Components/demand/GPUCountSelect";
import { H100SpecsTable } from "../Components/demand/H100SpecsTable";
import { ConnectivityToggle } from "../Components/demand/ConnectivityToggle";
import { Button } from "../Components/Button";

import { refreshParam } from "../hooks/useRefresh";
import { useDemandOrderParams } from "../Components/demand/useDemandOrderParams";
import { useAuthentication } from "../Components/auth/AuthUserProvider";
import { OrderType } from "../types/order";
import { usePlaceOrder } from "../state/order";

type ResponseError = AxiosError & {
  response: {
    data: {
      code: number;
      message: string;
    };
  };
};

const priceCopy = {
  [Connectivity.INFINIBAND]: "$3.00/GPU/Hr",
  [Connectivity.ETHERNET]: "$2.25/GPU/Hr",
};

export const OnDemand = () => {
  const { login, accountId } = useAuthentication();
  const { placeOrder, isError, error, isLoading, isSuccess, data } =
    usePlaceOrder();
  const history = useHistory();
  const { fromParams, toParams, deleteParams } = useDemandOrderParams();

  const [defaultConnectivity] = useState<ConnectivityOption>(
    fromParams.connectivity || Connectivity.ETHERNET,
  );
  const [connectivity, setConnectivity] =
    useState<ConnectivityOption>(defaultConnectivity);
  const [defaultGPUCount] = useState<number>(
    fromParams.gpus || DefaultGPUCount,
  );
  const [gpuCount, setGPUCount] = useState<number>(defaultGPUCount);
  const [lockOnError, setLockOnError] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess && data) {
      const orderRoute = `/orders?orderId=${data.id}&${refreshParam}`;
      history.push(orderRoute);
    }
  }, [isSuccess, data]);

  const notEnoughMachines =
    isError &&
    (error as ResponseError)?.response?.data?.code === 400 &&
    (error as ResponseError)?.response?.data?.message ===
      "Not enough machines available";

  useEffect(() => {
    if (notEnoughMachines) {
      setLockOnError(true);
    }
  }, [notEnoughMachines]);

  useEffect(() => {
    if (fromParams.connectivity || fromParams.gpus) {
      deleteParams();
    }

    if (notEnoughMachines && lockOnError) {
      setLockOnError(false);
    }
  }, [gpuCount, connectivity]);

  const gpuCountInvalidForInfiniband =
    InvalidInfinibandGPUCounts.includes(gpuCount);

  const onConnectivitySelected = (selectedConnectivity: ConnectivityOption) => {
    setConnectivity(selectedConnectivity);
  };

  const onGPUCountSelected = (selectedCount: string) => {
    setGPUCount(Number(selectedCount));
  };

  const triggerLoginFlow = () => {
    const orderParams = toParams(gpuCount, connectivity);
    const returnPath = `${location.pathname}?${orderParams}`;
    login(returnPath);
  };

  const onSubmit = () => {
    const type: OrderType = "fixed";

    const orderPayload = {
      accountId,
      numberOfGPUs: gpuCount,
      type,
      connectivity,
    };

    if (!accountId) {
      triggerLoginFlow();
    } else {
      placeOrder(orderPayload);
    }
  };

  const connectivityToggleProps = {
    name: "connectivity",
    onSelected: onConnectivitySelected,
    caption: gpuCountInvalidForInfiniband
      ? "A minimum of 16 GPUs is required for InfiniBand."
      : undefined,
    defaultSelected: defaultConnectivity,
    disable: gpuCountInvalidForInfiniband ? Connectivity.INFINIBAND : undefined,
  };

  return (
    <div className="bg-background min-h-page font-neo-grotesque">
      <main className="bg-white px-20 py-8 max-w-page-content mx-auto min-h-page">
        <section className="lg:max-w-[60%]">
          <h1 className="text-2xl font-bold text-black">
            Place an On-Demand Order
          </h1>
          <p className="font-neo-grotesque font-light text-[0.8125rem] text-black mt-1">
            You’ll get access to compute in as little as 15 minutes.
          </p>
          <p className="font-neo-grotesque font-light text-[0.8125rem] text-black mt-1">
            <i>
              Note: the On-Demand platform is currently in beta. If you
              experience any issues, please email &nbsp;
              <a className="underline" href="mailto:support@voltagepark.com">
                support@voltagpark.com
              </a>
              &nbsp;and we will get back to you ASAP.
            </i>
          </p>
        </section>

        <div className="mt-10 flex flex-wrap [&>article]:inline-block">
          <article className="mr-6 mb-2">
            <h2
              className={classNames("mb-2", {
                ["text-error"]: notEnoughMachines,
              })}
            >
              GPUs
            </h2>
            <GPUCountSelect
              defaultSelected={defaultGPUCount}
              onSelected={onGPUCountSelected}
              for={connectivity}
            />
          </article>

          <article className="mr-6 mb-2">
            <h2
              className={classNames("mb-2", {
                ["text-error"]: notEnoughMachines,
              })}
            >
              Interconnectivity
            </h2>
            <ConnectivityToggle {...connectivityToggleProps} />
          </article>

          <article className="mr-6 mb-2">
            <h2 className="mb-2">Price</h2>
            <p className="text-[1.5625rem] font-light">
              {priceCopy[connectivity]}
            </p>
          </article>

          <article>
            <h2 className="mb-2">Model</h2>
            <p className="text-[1.5625rem] font-light">H100 SXM5</p>
          </article>
        </div>

        <Accordion
          className="mt-4"
          title="Detailed Specifications"
          openTitle="Hide Specifications"
        >
          <H100SpecsTable />
        </Accordion>

        <p
          className={classNames(
            "mt-10 max-w-[27.375rem] font-neo-grotesque text-[0.8125rem] text-error",
            {
              ["hidden"]: !notEnoughMachines,
            },
          )}
        >
          Sorry, but no locations fulfill your selected criteria. Please modify
          the interconnectivity or number of GPUs requested.
        </p>
        <Button
          className={classNames({
            ["mt-2.5"]: notEnoughMachines,
            ["mt-10"]: !notEnoughMachines,
          })}
          onClick={onSubmit}
          disabled={isLoading || lockOnError || isSuccess}
        >
          Order Now
        </Button>
      </main>
    </div>
  );
};
