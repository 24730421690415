import { Fragment, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import {
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  ColumnFiltersState,
  getFacetedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";

import { UsersModal } from "./UsersModal";
import { UserStatusComponent } from "./UserStatusComponent";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { UserOptionsComponent } from "./UserOptionsComponent";
import { CompanyDetailComponent } from "../account/CompanyDetailComponent";

import { useGetUsers } from "../../state/user";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { columnListFilter, fuzzyFilter } from "../../utils/tableFilter";

import { User } from "../../types/user";
import { useSearchParams } from "../../hooks/useSearchParams";

const columnHelper = createColumnHelper<User>();
const columns = [
  columnHelper.accessor("id", {
    id: "userId",
    header: "User ID",
    cell: ({ cell }) => (
      <span className="font-semibold">{cell.getValue()}</span>
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("name", {
    id: "userName",
    header: "Name",
    cell: ({ cell }) => (
      <span>{!!cell.getValue() ? cell.getValue() : "-"}</span>
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("email", {
    id: "userEmail",
    header: "Email",
    cell: ({ cell }) => cell.getValue(),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("accountIds", {
    id: "accountId",
    header: "Account",
    cell: ({ cell }) => (
      <CompanyDetailComponent id={cell.row.original.accountIds.join(",")} />
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("type", {
    id: "userStatus",
    header: "Status",
    cell: ({ cell }) => <UserStatusComponent status={cell.getValue()} />,
    filterFn: columnListFilter,
  }),
];

const DefaultData: User[] = [];

/**
 * Display the list of users for admin
 * This component is the heart of User Dashboard page
 * We show the Users Table here with it's subcomponents to be interacted over
 * This follows exactly the same pattern as Instance Table
 */
export const UsersTable = () => {
  const { urlSearchParams } = useSearchParams();

  const { data } = useGetUsers();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "accountId", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [showModal, setShowModal] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  // Scroll to the row if accountId is set in search params
  useEffect(() => {
    const userId = urlSearchParams.get("userId");
    if (!data || data.data.length === 0 || !userId) return;

    const userIndex = data.data.findIndex((user) => user.id === userId);
    if (userIndex < 0) return;

    const element = document.getElementById(`${userIndex}-user-row`);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, [urlSearchParams, data]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      {
        id: "options",
        header: "Actions",
        cell: ({ cell }: { cell: Cell<User, undefined> }) => (
          <div
            onClick={
              (event) => event.stopPropagation() /** prevent parent event */
            }
          >
            <UserOptionsComponent
              setShowModal={(modalType) => {
                setShowModal(modalType);
                setCurrentUser(cell.row.original);
              }}
            />
          </div>
        ),
      },
    ],
    [data?.data],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,
    filterFns: { fuzzy: fuzzyFilter },

    state: {
      sorting,
      globalFilter: urlSearchParams.get("search") || "",
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: (value) => urlSearchParams.set("search", value),
    onColumnFiltersChange: setColumnFilters,

    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const getRowData = (cell: Cell<User, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  return (
    <>
      <div className="px-4 pt-3 mx-auto sm:px-6 lg:px-8">
        <div className="relative overflow-x-auto rounded-lg shadow-md">
          <table className="w-full text-sm text-left">
            {/* Table column names */}
            <thead className="border select-none whitespace-nowrap">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} scope="col" className="p-3 group">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <div
                          className={classNames("flex items-center w-fit", {
                            "cursor-pointer select-none mr-2.5":
                              header.column.getCanSort(),
                            "text-brand": header.column.getIsSorted(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ColumnHeaderIcon column={header.column} />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table data */}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.original.id}>
                  <tr
                    id={`${row.id}-user-row`}
                    data-testid={`${row.original.id}_USER_ROW`}
                    className="bg-white border group"
                  >
                    {row.getVisibleCells().map((cell) => getRowData(cell))}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {!!currentUser && (
        <UsersModal
          isOpen={showModal === "edit-user"}
          onClose={() => {
            setShowModal(null);
            setCurrentUser(null);
          }}
          modalName={showModal}
          user={currentUser}
        />
      )}
    </>
  );
};
