import React, { useEffect } from "react";
import classNames from "classnames";

import { CaretIcon } from "../CaretIcon";
import { GPUCountOption } from "./GPUCountOption";
import { isCountValidFor } from "./utils";
import { countOptions, ConnectivityOption, DefaultGPUCount } from "./constants";

type GPUCountSelectProps = {
  className?: string;
  onSelected?: (v: string) => void;
  defaultSelected?: number;
  for: ConnectivityOption;
};

export const GPUCountSelect = ({
  className,
  onSelected,
  defaultSelected,
  for: countFor,
}: GPUCountSelectProps) => {
  const isDefaultSelectedValid = Boolean(
    defaultSelected && isCountValidFor(defaultSelected, countFor),
  );
  const initialValue =
    (isDefaultSelectedValid && defaultSelected) || DefaultGPUCount;

  useEffect(() => {
    if (onSelected) {
      onSelected(`${initialValue}`);
    }
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onSelected) {
      onSelected(e.target.value);
    }
  };

  const containerClassName = classNames(className, "inline-block relative");

  return (
    <div className={containerClassName}>
      <select
        className="appearance-none border border-black rounded-lg h-[2.625rem] w-[5.4375rem] px-4 py-[0.6875rem] bg-white font-neo-grotesque font-light"
        defaultValue={initialValue}
        onChange={onChange}
      >
        {countOptions[countFor].map((option) => {
          return (
            <GPUCountOption
              key={`gpu-count-option-${option.value}`}
              value={`${option.value}`}
            >
              {option.copy}
            </GPUCountOption>
          );
        })}
      </select>
      <CaretIcon
        className="absolute top-[0.8125rem] right-2.5 pointer-events-none"
        width="1rem"
        height="1rem"
      />
    </div>
  );
};
