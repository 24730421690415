import { Header } from "../Components/Header";
import { Link } from "react-router-dom";

/*
 * The "about us page containing general information about the company and product at /about"
 */

export const About = () => {
  return (
    <div className="pb-4 bg-background min-h-page">
      <Header title="About" />
      <div className="w-full h-56 bg-center bg-no-repeat bg-contain bg-about-background" />
      <div className="p-8 space-y-4 text-base text-justify lg:py-8 lg:px-80">
        <div>
          <h2 className="mb-2 text-3xl font-semibold text-brand">Who we are</h2>
          <p>
            Voltage Park makes compute accessible--no gatekeepers, no
            salespeople, no contracts, just state-of-the-art servers. We have
            23,000 Nvidia H100s in a variety of configurations, from 8 GPUs to
            4088, available instantly for on-demand access.
          </p>
        </div>
        <div>
          <h2 className="mb-2 text-3xl font-semibold text-brand">
            Voltage Park is for every project
          </h2>
          <p>
            Voltage Park's compute clusters are deployed across data centers in
            Washington, Texas, Virginia, and Utah. We have 30MW of power under
            contract and we are happy to substantiate our deployment to any
            interested party.
          </p>
        </div>
        <div>
          <h2 className="mb-2 text-3xl font-semibold text-brand">Contact us</h2>
          <p>
            Contact us{" "}
            <Link to="/contact-us" className="text-brand hover:underline">
              here
            </Link>{" "}
            with any questions about our centers, our hardware, or for any
            compute need (duration, hours, or configuration) you don't see in
            our active exchange listing.
          </p>
        </div>
      </div>
    </div>
  );
};
