import moment from "moment-timezone";

// returns a random whole number between min and max
export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// returns the date if the date is after the past decade
export const getValidDate = (date?: Date) => {
  if (!date) {
    return undefined;
  }

  const momentDate = moment(date);
  if (momentDate.isAfter(moment().subtract(10, "years"))) {
    return momentDate.toDate();
  }

  return undefined;
};

// displays a human friendly duration for the given duration in days.
export const getDurationFromDays = ({
  days,
  startDate,
  endDate,
}: {
  days?: number;
  startDate?: Date;
  endDate?: Date;
}) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = endDate
    ? moment(endDate)
    : moment(startDate).add(days, "d");
  const durationText = momentStartDate.from(momentEndDate, true);
  const daysNumber = Math.round(
    days
      ? +days
      : moment.duration(momentEndDate.diff(momentStartDate)).asDays(),
  );

  return {
    startDate: momentStartDate,
    endDate: momentEndDate,
    duration: durationText,
    days: daysNumber > 1 ? `${daysNumber} days` : `${daysNumber} day`,
  };
};
