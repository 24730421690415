import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import { Tab } from "@headlessui/react";

import { Button } from "../LegacyButton";
import { OrdersModal } from "./OrdersModal";
import { OrderChargesTable } from "./OrderChargesTable";
import { OrderDetailsComponent } from "./OrderDetailsComponent";

import { isExpiredOrder, isRunningOrder } from "../../utils/order";
import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { DashboardPathnames } from "../../pages/Dashboard";

import { Order } from "../../types/order";

const OrderTabLabels = ["Order Details", "Charges"];

const getPanelComponent = (label: string, order: Order) => {
  switch (label) {
    case "Order Details":
      return <OrderDetailsComponent order={order} />;
    case "Charges":
      return <OrderChargesTable order={order} />;
  }
};

interface OrderInfoComponentProps {
  order: Order;
  isAdmin?: boolean;
}

/**
 * This component is incharge of showing the subcomponent of selected order
 */
export const OrderInfoComponent = ({
  isAdmin = false,
  order,
}: OrderInfoComponentProps) => {
  const { push } = useHistory();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showModal, setShowModal] = useState<string | null>(null);

  const getActionButtons = () => {
    return (
      <>
        <div className="p-2 w-[260px] flex flex-wrap content-between justify-between">
          <div className="flex flex-col w-full gap-3">
            <h3 className="text-base font-semibold text-center">Options:</h3>
            {isRunningOrder(order) && (
              <Button
                data-testid="VIEW_INSTANCES_BUTTON"
                variant="dark"
                variantClassName="h-[35px]"
                onClick={() => {
                  if (isAdmin) {
                    push(
                      `${DashboardPathnames.instances}?instanceId=${order.id}`,
                    );
                  } else {
                    push(`/instances?instanceId=${order.id}`);
                  }
                }}
              >
                View Instances
              </Button>
            )}
            <Button
              data-testid="UPDATE_ORDER_MODAL_BUTTON"
              disabled={isExpiredOrder(order)}
              variant="transparent"
              variantClassName="h-[35px]"
              onClick={() => setShowModal("update-order")}
            >
              Update Order
            </Button>
            <Button
              data-testid="DELETE_ORDER_MODAL_BUTTON"
              disabled={isExpiredOrder(order)}
              variant="red"
              invertVariant={true}
              variantClassName="h-[35px]"
              onClick={() => setShowModal("delete-order")}
            >
              Delete Order
            </Button>
          </div>
        </div>
        <OrdersModal
          isOpen={showModal === "update-order" || showModal === "delete-order"}
          onClose={() => setShowModal(null)}
          modalName={showModal}
          order={order}
        />
      </>
    );
  };

  const getTabsComponent = (order: Order) => {
    return (
      <Tab.Group
        vertical={true}
        selectedIndex={currentTabIndex}
        onChange={(index) => setCurrentTabIndex(index)}
      >
        <Tab.List className="flex flex-col items-baseline m-4">
          {OrderTabLabels.map((label) => (
            <Tab key={label} as={Fragment}>
              {({ selected }) => (
                <div
                  className={classNames("border-l-2 py-1", {
                    "border-brand": selected,
                    "border-white hover:border-brandHover": !selected,
                  })}
                >
                  <Button
                    data-testid={`${camelCaseToSnakeCase(label)}_TAB`}
                    textVariant={true}
                    variant={selected ? "brand" : "transparent"}
                    variantClassName={classNames(
                      "truncate min-w-[150px] text-left",
                      {
                        "font-semibold": selected,
                      },
                    )}
                  >
                    {label}
                  </Button>
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full px-2 m-4">
          {OrderTabLabels.map((label) => (
            <Tab.Panel className="flex" key={label}>
              <div className="w-full p-2.5">
                {getPanelComponent(label, order)}
              </div>
              {getActionButtons()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    );
  };

  return (
    <div className="flex m-2 bg-white border rounded-lg shadow-md">
      <div className="flex justify-between w-full">
        {getTabsComponent(order)}
      </div>
    </div>
  );
};
