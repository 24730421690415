import { Fragment, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import classNames from "classnames";
import { Transition } from "@headlessui/react";

import { Button } from "./LegacyButton";
import { DashboardPathnames } from "../pages/Dashboard";
import { ProfileContainer } from "./profile/ProfileContainer";

import { useAuthentication } from "./auth/AuthUserProvider";
import { useGetAccountStats, clearAccountStats } from "../state/account";

import { CloseIcon } from "./icons/CloseIcon";
import { UpArrowIcon } from "./icons/UpArrowIcon";
import { HamburgerIcon } from "./icons/HamburgerIcon";
import { VPAuctionsIcon } from "./icons/VPAuctionsIcon";

import { camelCaseToSnakeCase } from "../utils/textFormatter";

import { User } from "../types/user";
import { AccountStats } from "../types/account";

export type PageType = {
  name: string | JSX.Element;
  pathname: string;
  visible: "always" | "before-login" | "after-login" | "admin-login";
  external?: boolean;
};

export const getFilteredNavLink = (
  page: PageType,
  userInfo?: User,
  isPipelinePresent = false,
  isHeader = false,
) => {
  const unAvailableCondition =
    (page.visible === "before-login" && !!userInfo) ||
    (page.visible === "after-login" && !userInfo) ||
    (page.visible === "admin-login" &&
      (!userInfo || userInfo.type !== "admin"));

  if (unAvailableCondition) {
    return <Fragment key={page.pathname}></Fragment>;
  }

  let path: any = page.pathname;
  if (page.external) {
    path = { pathname: page.pathname };
  }

  return (
    <Fragment key={page.pathname}>
      {isHeader &&
        !isPipelinePresent &&
        page.pathname === DashboardPathnames.orders && (
          <div className="flex w-0 2xl:w-0.5 2xl:mx-2 h-8 bg-uiBorder"></div>
        )}
      <div
        className={classNames({
          "min-w-[90px] flex justify-center": isHeader && !isPipelinePresent,
        })}
      >
        <NavLink
          data-testid={
            typeof page.name === "string"
              ? `${camelCaseToSnakeCase(page.name)}_LINK`
              : undefined
          }
          exact
          to={page}
          target={page.external ? "_blank" : undefined}
          isActive={(match, location) => {
            const pageRoot = page.pathname.split("/")[1];
            const locationRoot = location.pathname.split("/")[1];
            return (
              !!match ||
              !!(locationRoot && pageRoot && locationRoot === pageRoot)
            );
          }}
          activeClassName={classNames("font-semibold", {
            "bg-background rounded-md": isHeader,
            "text-brand": !isHeader,
          })}
          className={classNames(
            "block px-3 py-2 text-base truncate md:text-sm md:flex",
            {
              "hover:underline": typeof page.name === "string",
              "text-secondaryText": isHeader,
            },
          )}
        >
          {page.name}
        </NavLink>
      </div>
      {isPipelinePresent && (
        <span className="py-2 text-base md:text-sm">|</span>
      )}
    </Fragment>
  );
};

const Pages = (accountStats?: AccountStats): PageType[] => [
  {
    name: (
      <span data-testid="DEMAND_LINK">
        <span className="hover:underline">On-Demand</span>
      </span>
    ),
    pathname: "/",
    visible: "always",
  },
  {
    name: "How it works",
    pathname:
      "https://docs.voltagepark.com/voltage-park-exchange/getting-started/how-it-works",
    visible: "before-login",
    external: true,
  },
  { name: "About", pathname: "/about", visible: "before-login" },
  {
    name: (
      <span data-testid="ORDERS_LINK">
        <span className="hover:underline">My Orders</span>{" "}
        {!!accountStats?.ordersCount && (
          <span className="text-xs border rounded-full bg-background px-2 py-0.5">
            {accountStats.ordersCount}
          </span>
        )}
      </span>
    ),
    pathname: "/orders",
    visible: "after-login",
  },

  {
    name: (
      <span data-testid="INSTANCES_LINK">
        <span className="hover:underline">Instances</span>{" "}
        {!!accountStats?.instancesCount && (
          <span className="text-xs border rounded-full bg-background px-2 py-0.5">
            {accountStats.instancesCount}
          </span>
        )}
      </span>
    ),
    pathname: "/instances",
    visible: "after-login",
  },
  {
    name: "Settings",
    pathname: "/settings/profile",
    visible: "after-login",
  },
  {
    name: "Dashboard",
    pathname: DashboardPathnames.orders,
    visible: "admin-login",
  },
];

/**
 * This component check for use authentication and shows the nav items and profile
 * based on what the authentication state it
 */
const AuthNavbar = () => {
  const { isLoading, userInfo, userPicture, accountId, setAccount } =
    useAuthentication();

  const { data: accountStats, refetch: refetchAccountStats } =
    useGetAccountStats();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // When userInfo is fetched and present, then only fetch account stats
  // When userInfo is not present, then clear account stats
  useEffect(() => {
    if (!!userInfo && !accountStats) {
      refetchAccountStats();
    }
    if (!userInfo && !!accountStats) {
      clearAccountStats();
    }
  }, [userInfo, accountStats]);

  return (
    <nav className="bg-white border-[#CCC] border-b-[0.03125rem]">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center h-16">
          <div className="grid w-full grid-cols-2 md:grid-cols-3">
            {/** Header Name */}
            <NavLink to="/" className="flex items-center">
              <VPAuctionsIcon />
            </NavLink>

            {!isLoading && (
              <>
                {/** Web Links */}
                <div className="items-center justify-center hidden md:flex">
                  {Pages(accountStats).map((page) =>
                    getFilteredNavLink(page, userInfo, false, true),
                  )}
                </div>
                {/** Profile Buttons */}
                <div className="flex justify-end">
                  <ProfileContainer
                    user={userInfo}
                    userPicture={userPicture}
                    accountId={accountId!}
                    setAccountId={setAccount}
                  />
                  {/** Mobile Menu Button */}
                  <Button
                    data-testid="MOBILE_MENU_BUTTON"
                    type="button"
                    className="flex items-center justify-center p-2 ml-2 -mr-2 rounded-md text-primaryText md:hidden hover:text-background hover:bg-primaryText focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-uiBorder focus:ring-uiBorder"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <span className="sr-only">Open main menu</span>
                    {!isMenuOpen && <HamburgerIcon />}
                    {isMenuOpen && <CloseIcon />}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/** Mobile Links */}
      <Transition
        show={isMenuOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-1 pb-3 space-y-1 text-right sm:px-3"
            >
              {!isLoading &&
                Pages(accountStats).map((page) =>
                  getFilteredNavLink(page, userInfo),
                )}
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

/**
 * This component just returns the logo and button to redirect to home
 */
const RedirectNavbar = () => {
  return (
    <nav className="bg-white border-[#CCC] border-b-[0.03125rem]">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/** Header Name */}
          <NavLink to="/" className="flex items-center">
            <VPAuctionsIcon />
          </NavLink>
          {/** Go to Home Button */}
          <Link to="/">
            <Button
              data-testid="GO_TO_HOME_BUTTON"
              variant="transparent"
              variantClassName="text-sm min-w-[150px] flex items-center gap-2"
            >
              <UpArrowIcon className="rotate-90" />
              Go to Home
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

/**
 * This component handles the top navbar that is sticky to all the pages in vp-auctions
 * We define multiple components to handle responsiveness
 */
export const Navbar = () => {
  const { pathname } = useLocation();

  if (pathname === "/unsubscribe") {
    return <RedirectNavbar />;
  }

  return <AuthNavbar />;
};
