// env.ts
//
// All environment configurations for the project

const env = process.env.REACT_APP_ENV?.toLocaleLowerCase();

export const isDev = env === "dev" || env === "development";
export const isLocal = env === "local";

export const appVersion = process.env.REACT_APP_VERSION ?? "";
