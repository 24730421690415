import { Fragment, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import {
  Row,
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  ColumnFiltersState,
  getExpandedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { RowExpandIcon } from "../table/RowExpandIcon";
import { OrderInfoComponent } from "./OrderInfoComponent";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { PayConfirmationModal } from "./PayConfirmationModal";
import { OrderStatusComponent } from "./OrderStatusComponent";
import { CustomOrderActionComponent } from "./CustomOrderActionComponent";

import { useGetOrders } from "../../state/order";
import { useSearchParams } from "../../hooks/useSearchParams";

import { columnListFilter } from "../../utils/tableFilter";
import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { formatMoneyString } from "../../utils/currencyFormatter";
import { sortingFunctionForOrderStatus } from "../../utils/tableSort";

import { Order } from "../../types/order";

const columnHelper = createColumnHelper<Order>();
const columns = [
  columnHelper.accessor("id", {
    id: "orderId",
    header: "Order ID",
    cell: ({ cell }) => (
      <span className="font-semibold">{cell.getValue()}</span>
    ),
  }),
  columnHelper.accessor("numberOfGPUs", {
    id: "numberOfGPUs",
    header: "# of GPUs",
    cell: ({ cell }) => cell.getValue(),
  }),
  columnHelper.accessor("maxPricePerGPU", {
    id: "maxPricePerGPU",
    header: "Max Price (GPU/hr)",
    cell: ({ cell }) => (
      <span className="px-2 py-0.5 text-sm text-center border rounded-lg font-semibold">
        {formatMoneyString(cell.getValue())}
      </span>
    ),
  }),
  columnHelper.accessor("type", {
    id: "orderType",
    header: "Pricing Model",
    cell: ({ cell }) => (cell.getValue() === "float" ? "Floating" : "Fixed"),
  }),
  columnHelper.accessor("status", {
    id: "orderStatus",
    header: "Status",
    cell: ({ cell }) => <OrderStatusComponent status={cell.getValue()} />,
    sortingFn: sortingFunctionForOrderStatus,
    filterFn: columnListFilter,
  }),
];

const DefaultData: Order[] = [];

const getDefaultColumnFilters = (orderType?: string | null) => {
  return [
    {
      id: "orderStatus",
      value:
        orderType && orderType.toLowerCase().includes("past")
          ? ["finished", "deleted"]
          : [
              "not_ready",
              "eligible",
              "allocating",
              "winning",
              "active",
              "closing",
              "paused",
              "large",
            ],
    },
  ];
};

/**
 * Display the list of users orders
 * This follows exactly the same pattern as Instance Table
 */
export const OrdersTable = ({ accountId }: { accountId: string }) => {
  const { urlSearchParams } = useSearchParams();
  const orderType = urlSearchParams.get("type");

  const { data } = useGetOrders(accountId);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "orderStatus", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    getDefaultColumnFilters(orderType),
  );

  const [showModal, setShowModal] = useState<string | null>(null);

  // Expand and Scroll to the row if orderId is set in search params
  useEffect(() => {
    const orderId = urlSearchParams.get("orderId");
    const orderIds = urlSearchParams.get("orderIds");

    const wasPaymentSuccessFul = urlSearchParams.get("paymentSuccess");
    if (!data || data.data.length === 0) return;

    if (orderId) {
      expandRowByOrderId(orderId, data.data, "orderId");
    }

    // Expanding the row if single order present
    const paramOrderIds = orderIds?.split(",").map(String);
    if (paramOrderIds && paramOrderIds.length === 1) {
      expandRowByOrderId(paramOrderIds[0], data.data, "orderIds");
    }

    if (wasPaymentSuccessFul !== null) {
      setShowModal("pay-confirmation");
    }
  }, [urlSearchParams, data]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      {
        id: "action",
        header: "Action / Status Date",
        cell: ({ cell }: { cell: Cell<Order, unknown> }) => (
          <CustomOrderActionComponent
            status={cell.row.original.status}
            startAt={cell.row.original.startAt}
            endAt={cell.row.original.endAt}
          />
        ),
      },
      {
        id: "expander",
        cell: ({ row }: { row: Row<Order> }) => <RowExpandIcon row={row} />,
      },
    ],
    [data?.data],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,

    state: {
      sorting,
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
  });

  const getRowData = (cell: Cell<Order, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const getRowActions = (row: Row<Order>, isSubComponent = false) => {
    return {
      onClick: () => {
        if (isSubComponent || !row.getCanExpand()) {
          return;
        }
        if (!row.getIsExpanded()) {
          table.resetExpanded();
        }
        row.toggleExpanded();
      },
    };
  };

  const expandRowByOrderId = (
    orderId: string,
    orders: Order[],
    query?: string,
  ) => {
    const orderIndex = orders.findIndex((order) => order.id === orderId);
    if (orderIndex < 0) return;

    try {
      const row = table.getRow(orderIndex.toString());
      if (row && row.getCanExpand() && !row.getIsExpanded()) {
        table.resetExpanded();
        row.toggleExpanded();

        const element = document.getElementById(`${orderIndex}-order-row`);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    } catch (_) {
      urlSearchParams.delete(query ?? "orderId");
    }
  };

  return (
    <>
      <div className="px-4 pt-3 mx-auto sm:px-6 lg:px-8">
        <div className="relative my-3 overflow-x-auto rounded-lg shadow-md">
          <table className="w-full text-sm text-left">
            {/* Table column names */}
            <thead className="border select-none whitespace-nowrap">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} scope="col" className="p-3 group">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <div
                          className={classNames("flex items-center w-fit", {
                            "cursor-pointer select-none mr-2.5":
                              header.column.getCanSort(),
                            "text-brand": header.column.getIsSorted(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ColumnHeaderIcon column={header.column} />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table data */}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.original.id}>
                  <tr
                    id={`${row.id}-order-row`}
                    data-testid={`${row.original.id}_ORDER_ROW`}
                    className={classNames("border group hover:cursor-pointer", {
                      "bg-white hover:bg-background":
                        row.original.status !== "finished" &&
                        !row.getIsExpanded(),
                      "bg-background": row.getIsExpanded(),
                      "text-secondaryText bg-uiBorder":
                        row.original.status === "finished",
                    })}
                    {...getRowActions(row)}
                  >
                    {row.getVisibleCells().map((cell) => getRowData(cell))}
                  </tr>
                  {/* Table Sub Component */}
                  {row.getIsExpanded() && (
                    <tr
                      className={classNames("border", {
                        "bg-white hover:bg-background":
                          row.original.status !== "finished" &&
                          !row.getIsExpanded(),
                        "bg-background": row.getIsExpanded(),
                        "bg-uiBorder": row.original.status === "finished",
                      })}
                      {...getRowActions(row, true)}
                    >
                      <td
                        colSpan={row.getVisibleCells().length}
                        className="p-0"
                      >
                        <OrderInfoComponent order={row.original} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal === "pay-confirmation" && (
        <PayConfirmationModal
          isOpen={showModal === "pay-confirmation"}
          onClose={() => {
            urlSearchParams.delete("paymentSuccess");
            setShowModal(null);
          }}
          orderIds={urlSearchParams.get("orderIds")?.split(",").map(String)}
        />
      )}
    </>
  );
};
