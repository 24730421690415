import classNames from "classnames";

import { InfoIcon } from "../icons/InfoIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

export type MessageProps = {
  id?: string;
  message: string | null;
  isError?: boolean;
  showIcon?: boolean;
  className?: string;
};

/**
 *  Display the success/error message
 */
export const Message = ({
  id,
  message,
  isError = true,
  showIcon = true,
  className,
}: MessageProps) => {
  if (!message) {
    return <></>;
  }

  return (
    <div
      data-testid={!!id ? `${camelCaseToSnakeCase(id)}_ERROR` : undefined}
      className={classNames("flex text-sm items-center pt-1", className, {
        "text-negative": isError,
        "text-positive": !isError,
      })}
    >
      {showIcon && <InfoIcon />}
      <span>{message}</span>
    </div>
  );
};
