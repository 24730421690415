import classNames from "classnames";
import { Dialog } from "@headlessui/react";

import { Button } from "../LegacyButton";
import { CloseIcon } from "../icons/CloseIcon";

import { useSearchParams } from "../../hooks/useSearchParams";

import { CircularCheckIcon } from "../icons/CircularCheckIcon";
import { TransparentCrossIcon } from "../icons/TransparentCrossIcon";

interface PayConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderIds?: string[];
  orderId?: string;
}

/**
 * The Modal shows Modal and Content related to Pay Confirmation ie.:
 *  Giving you title, description and close button related to payment process
 */
export const PayConfirmationModal = ({
  isOpen,
  onClose,
  orderId,
  orderIds,
}: PayConfirmationModalProps) => {
  const { urlSearchParams } = useSearchParams();
  const wasPaymentSuccessFul = urlSearchParams.get("paymentSuccess");

  const getDescription = () => {
    if (!!orderId) {
      if (wasPaymentSuccessFul === "true") {
        return `Your payment has been processed for Order#${orderId}!`;
      }

      if (wasPaymentSuccessFul === "false") {
        return `Your payment has failed for Order#${orderId}.\nPlease try again in few minutes!`;
      }
    }

    if (!!orderIds && orderIds.length !== 0) {
      if (orderIds.length === 1) {
        if (wasPaymentSuccessFul === "true") {
          return `Your payment has been processed for Order#${orderIds[0]}!`;
        }

        if (wasPaymentSuccessFul === "false") {
          return `Your payment has failed for Order#${orderIds[0]}!`;
        }
      }
      if (orderIds.length > 1) {
        return (
          <div className="flex flex-col gap-2">
            <span>{`Your payment has ${
              wasPaymentSuccessFul === "true" ? "been processed" : "failed"
            } for:`}</span>
            <div className="flex flex-col font-semibold">
              {orderIds.map((orderId) => (
                <span>{`Order #${orderId}`}</span>
              ))}
            </div>
          </div>
        );
      }
    }

    return <></>;
  };

  return (
    <Dialog
      id="pay-confirmation-modal"
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          <div className="flex items-center justify-between mb-2">
            <div
              className={classNames("p-3.5 rounded-full w-fit", {
                "bg-green-100": wasPaymentSuccessFul === "true",
                "bg-red-100": wasPaymentSuccessFul === "false",
              })}
            >
              <>
                {wasPaymentSuccessFul === "true" && (
                  <CircularCheckIcon className="text-positive" />
                )}
                {wasPaymentSuccessFul === "false" && (
                  <TransparentCrossIcon className="text-negative" />
                )}
              </>
            </div>
            <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
              <CloseIcon className="text-name hover:scale-110" />
            </Button>
          </div>
          <div className="flex items-center justify-between">
            <h1
              data-testid="PAY_CONFIRMATION_MODAL_HEADER"
              className="text-xl font-semibold leading-10 tracking-wide"
            >
              {wasPaymentSuccessFul === "true" && "Thanks!"}
              {wasPaymentSuccessFul === "false" && "Sorry!"}
            </h1>
          </div>
          <span
            data-testid="PAY_CONFIRMATION_DESCRIPTION"
            className="font-normal leading-normal whitespace-pre text-secondaryText"
          >
            {getDescription()}
          </span>
          <div className="flex justify-center mt-4">
            <Button
              data-testid="CLOSE_BUTTON"
              type="button"
              variant="transparent"
              variantClassName="w-full"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
