import { FilterFn, Row } from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

/**
 * Function to fuzzy filter
 * To make a ranking/filtering/sorting system work with tables,
 * filterFns can mark results with a filter meta value that can be used later to sort/group/etc the data,
 * done by calling the addMeta function supplied to your custom filterFn.
 */
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({ itemRank });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

/**
 * Function to custom filter for a column
 * It is a modified version of "arrIncludesSome"
 * Handle falsy values as well as all types of value type ie. Date, number, boolean and string
 */
export const columnListFilter = (
  row: Row<any>,
  columnId: string,
  filterValue?: unknown[],
) => {
  const rowValue = row.getValue(columnId);
  if (
    !filterValue ||
    rowValue === "" ||
    rowValue === null ||
    rowValue === undefined ||
    filterValue.length === 0
  ) {
    return true;
  }

  return filterValue.some((value) => {
    if (value instanceof Date && rowValue instanceof Date) {
      return value.getTime() === rowValue.getTime();
    }

    if (typeof value === "number" && typeof rowValue === "number") {
      return value === rowValue;
    }

    if (typeof value === "boolean" && typeof rowValue === "boolean") {
      return value === rowValue;
    }

    return `${rowValue}`
      .toLowerCase()
      .includes(`${value}`.toString().toLowerCase());
  });
};
