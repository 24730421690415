import { Redirect } from "react-router-dom";

import { DashboardPathnames } from "./Dashboard";
import { Loading } from "../Components/Loading";
import { useAuthentication } from "../Components/auth/AuthUserProvider";

/**
 * When User is authenticated through Auth0, we get type from BE to Redirect to respective page
 */
export const RedirectAfterLogin = () => {
  const { userInfo, isLoading } = useAuthentication();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo?.type === "admin") {
    return <Redirect to={DashboardPathnames.orders} />;
  }

  return <Redirect to="/" />;
};
