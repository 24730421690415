import classNames from "classnames";

import { Button } from "../LegacyButton";

import { CrossIcon } from "../icons/CrossIcon";
import { SearchIcon } from "../icons/SearchIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

export const getInvalidEmailMessage = (value: string) => {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return "";
  }

  return "Please enter a valid email";
};

export const getInvalidTextMessage = (value: string) => {
  if (/^[A-Za-z\s0-9-_]{0,20}$/.test(value)) {
    return "";
  }

  if (!/^[A-Za-z\s0-9-_]*$/.test(value)) {
    return "Please enter alphanumeric text";
  }

  return "Text is too long (maximum 20 characters)";
};

type TextInputProps = {
  id?: string;
  value?: string;
  originalValue?: string;
  required?: boolean;
  isError?: boolean;
  placeholder?: string;
  isEmail?: boolean;
  isSearch?: boolean;
  onChange: (value: string) => void;
};

/**
 *  Allows user to enter short text
 */
export const TextInput = ({
  id,
  value,
  originalValue,
  required,
  isError,
  isEmail = false,
  isSearch = false,
  placeholder = "Enter text",
  onChange,
}: TextInputProps) => {
  return (
    <>
      {originalValue && value !== originalValue && (
        <div className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer">
          <Button type="button" onClick={() => onChange(originalValue)}>
            <CrossIcon className="text-name" />
          </Button>
        </div>
      )}
      {isSearch && (
        <div className="absolute inset-y-0 left-0 flex items-center p-2.5">
          <SearchIcon className="text-name" />
        </div>
      )}
      <input
        autoFocus={true}
        className={classNames(
          "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border",
          {
            "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
              !isError,
            "border-negative focus:ring-negative focus:ring-offset-negative":
              !!isError,
            "pr-8": originalValue && value !== originalValue,
            "pl-8": isSearch,
          },
        )}
        id={id}
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        type={isEmail ? "email" : "text"}
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={(e) =>
          onChange(e.target.value.trimStart().replace(/\s\s+/g, " "))
        }
        onFocus={() => value && onChange(value)}
      />
    </>
  );
};
