import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";

import { isInstance } from "../utils/order";

import {
  Config,
  Machine,
  Instance,
  AssignIPsRequest,
  DeleteInstanceRequest,
  UpdateInstanceRequest,
  UpdateInventoryRequest,
  UpdateInstanceConfigRequest,
  DeleteInstanceMachineRequest,
  PowerCycleMachineRequest,
  ReprovisionMachineRequest,
} from "../types/instance";

export enum InstanceKey {
  /** query key to fetch all instances for admin */
  INSTANCE_LIST_ALL = "instance-list-all",

  /** query key to fetch all instances */
  INSTANCE_LIST = "instance-list",

  /** query key to fetch all configs for a instance */
  INSTANCE_CONFIG_LIST = "instance-config-list",

  /** query key to fetch all ips within a instance */
  INSTANCE_MACHINE_LIST = "instance-machine-list",

  INSTANCE_POWER_CYCLE = "instance-power-cycle",

  /** update instance's config */
  UPDATE_INSTANCE_CONFIG = "update-instance-config",

  /** query key to assign ips to an instance */
  ASSIGN_INSTANCE_IPS = "assign-instance-ips",

  /** update an instance */
  UPDATE_INSTANCE = "update-instance",

  /** delete an instance */
  DELETE_INSTANCE = "delete-instance",

  /** delete a machine from the instance */
  DELETE_INSTANCE_MACHINE = "delete-instance-machine",

  /** update the inventory */
  UPDATE_INVENTORY = "update-inventory",

  REPROVISION_INSTANCE_MACHINE = "reprovision-instance-machine",
}

/** retrieve the list of all instances from the server */
const getAllInstances = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order?all=true`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const instances: Instance[] | undefined = response.data?.orders?.map(
    (item: any) => {
      return {
        id: item.id,
        priority: item.priority ?? 0,
        name: item.name,
        numberOfGPUs: item.targetNumGPUs,
        type: item.type,
        status: item.status,
        accountId: item.accountID,
        company: item.company ?? "",
        configId: item.configId,
        maxPricePerGPU: !!item.maxPrice ? +item.maxPrice / 100 : 0,
      };
    },
  );
  const filteredInstances = instances?.filter((instance) =>
    isInstance(instance),
  );

  return { data: filteredInstances || [] };
};

/** Get the list of all instances */
export const useGetAllInstances = () => {
  return useQuery<{ data: Instance[] }, Error>(
    [InstanceKey.INSTANCE_LIST_ALL],
    getAllInstances,
  );
};

/** retrieve the list of instances from the server */
const getInstances = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order?accountID=${accountId}`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const instances: Instance[] | undefined = response.data?.orders?.map(
    (item: any) => {
      return {
        id: item.id,
        priority: item.priority ?? 0,
        name: item.name,
        numberOfGPUs: item.targetNumGPUs,
        type: item.type,
        status: item.status,
        accountId: item.accountID,
        configId: item.configId,
        maxPricePerGPU: !!item.maxPrice ? +item.maxPrice / 100 : 0,
      };
    },
  );
  const filteredInstances = instances?.filter((instance) =>
    isInstance(instance),
  );

  return { data: filteredInstances || [] };
};

/** retrieve the list of instances */
export const useGetInstances = (accountId: string) => {
  return useQuery<{ data: Instance[] }, Error>(
    [InstanceKey.INSTANCE_LIST],
    () => getInstances(accountId),
  );
};

/** retrieve the config from the server */
const getInstanceConfig = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/api/config/list`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const configs = response.data?.configs || [];
  const convertedResponse = configs.reduce(
    (object: Config, item: { id: number; name: string }) => (
      (object[item.id] = item.name), object
    ),
    {},
  );

  return convertedResponse;
};

/** retrieve the config */
export const useGetInstanceConfig = () => {
  return useQuery<Config, Error>(
    [InstanceKey.INSTANCE_CONFIG_LIST],
    getInstanceConfig,
  );
};

/** retrieve the list of ips for a instance from the server */
const getInstanceMachines = async (orderId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${orderId}/machines`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const machines: Machine[] | undefined = response.data?.machines?.map(
    (item: any) => {
      return {
        id: item.id,
        privateIP: item.privateIP,
        publicIP: item.publicIP,
        state: item.state,
      };
    },
  );

  return { data: machines || [] };
};

/** retrieve the list of ips for a instance */
export const useGetInstanceMachines = (orderID: string) => {
  return useQuery<{ data: Machine[] }, Error>(
    [InstanceKey.INSTANCE_MACHINE_LIST, orderID],
    () => getInstanceMachines(orderID),
  );
};

/** retrieve the list of ips for a instance from the server */
const reprovisionMachine = async (params: ReprovisionMachineRequest) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.instanceId}/machines/reprovision`;
  const response = await axios.patch(url, {
    machineIDs: [params.id],
  });
  return params;
};

export const useReprovisionMachines = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    ReprovisionMachineRequest,
    Error,
    ReprovisionMachineRequest
  >([InstanceKey.INSTANCE_POWER_CYCLE], reprovisionMachine);

  return { reprovisionMachine: mutate, isError, isSuccess, isLoading };
};

/** retrieve the list of ips for a instance from the server */
const powerCycleMachine = async (params: PowerCycleMachineRequest) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.instanceId}/machines/cycle`;
  const response = await axios.patch(url, {
    machineIDs: [params.id],
  });
  return params;
};

export const usePowerCycleMachines = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    PowerCycleMachineRequest,
    Error,
    PowerCycleMachineRequest
  >([InstanceKey.INSTANCE_POWER_CYCLE], powerCycleMachine);

  return { powerCycleMachine: mutate, isError, isSuccess, isLoading };
};

/** Sends the new instance config through post request to the server */
const updateInstanceConfig = async (params: UpdateInstanceConfigRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/config`;
  await axios.post(url, params);
  return params;
};

/** Update config of the instance */
export const useUpdateInstanceConfig = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateInstanceConfigRequest,
    Error,
    UpdateInstanceConfigRequest
  >([InstanceKey.UPDATE_INSTANCE_CONFIG], updateInstanceConfig);

  return { updateConfig: mutate, isError, isSuccess, isLoading };
};

/** Sends assign IPs post request to the server */
const assignInstanceIPs = async (params: AssignIPsRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/assign-ips`;
  await axios.post(url, params);
  return params;
};

/** Assign ips to the instance */
export const useAssignInstanceIPs = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    AssignIPsRequest,
    Error,
    AssignIPsRequest
  >([InstanceKey.ASSIGN_INSTANCE_IPS], assignInstanceIPs);

  return { assignIPs: mutate, isError, isSuccess, isLoading };
};

/** Send a update instance request to the server */
const updateInstance = async (params: UpdateInstanceRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/update`;
  const body = {
    id: params.id,
    type: params.type,
    name: params.name,
    targetNumGPUs: params.numberOfGPUs,
    minNumGPUs: params.numberOfGPUs,
    maxPrice: Math.round(params.maxPricePerGPU * 100),
  };
  await axios.post(
    url,
    params.isAdmin ? { ...body, priority: params.priority } : body,
  );
  return params;
};

/** Update a instance */
export const useInstanceUpdate = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateInstanceRequest,
    Error,
    UpdateInstanceRequest
  >([InstanceKey.UPDATE_INSTANCE], updateInstance);

  return { updateInstance: mutate, isError, isSuccess, isLoading };
};

/** Send a delete instance request to the server */
const deleteInstance = async (params: DeleteInstanceRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/delete`;
  await axios.post(url, params);
  return params;
};

/** Delete a instance */
export const useInstanceDelete = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    DeleteInstanceRequest,
    Error,
    DeleteInstanceRequest
  >([InstanceKey.DELETE_INSTANCE], deleteInstance);

  return { deleteInstance: mutate, isError, isSuccess, isLoading };
};

/** Send a delete machine request to the server */
const deleteInstanceMachine = async (params: DeleteInstanceMachineRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/${params.instanceId}/machines/remove`;
  await axios.post(url, params);
  return params;
};

/** Delete a instance machine */
export const useDeleteInstanceMachine = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    DeleteInstanceMachineRequest,
    Error,
    DeleteInstanceMachineRequest
  >([InstanceKey.DELETE_INSTANCE_MACHINE], deleteInstanceMachine);

  return { deleteInstanceMachine: mutate, isError, isSuccess, isLoading };
};

/** Sends updater inventory post request to the server */
const updateInventory = async (params: UpdateInventoryRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/inventory/update`;
  const body = { total: params.numberOfTotalGPUs };
  await axios.post(url, body);
  return params;
};

/** Update the inventory */
export const useUpdateInventory = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateInventoryRequest,
    Error,
    UpdateInventoryRequest
  >([InstanceKey.UPDATE_INVENTORY], updateInventory, {});

  return { updateInventory: mutate, isError, isSuccess, isLoading };
};
