import { camelCaseToSnakeCase } from "../../utils/textFormatter";

type CheckboxOptionProps = {
  id?: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  description?: string;
  required?: boolean;
  onChange: (value: boolean) => void;
};

/**
 *  Allows user to select a checkbox option
 */
export const CheckboxOption = ({
  id,
  checked,
  disabled,
  required,
  label,
  description,
  onChange,
}: CheckboxOptionProps) => {
  return (
    <>
      <label key={label} className="flex items-start gap-1 cursor-pointer">
        <input
          data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
          type="checkbox"
          checked={required ? true : checked}
          required={required}
          disabled={disabled}
          onChange={() => onChange(!checked)}
          className="mt-[6px] mr-1 text-base font-medium leading-normal cursor-pointer accent-brand"
        />
        <div className="flex flex-col">
          {label && (
            <span>
              {label}
              {required && <span className="text-brand">{" (Required)"}</span>}
            </span>
          )}
          {description && (
            <span className="text-base font-light tracking-tight text-tertiaryText">
              {description}
            </span>
          )}
        </div>
      </label>
    </>
  );
};
