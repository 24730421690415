import { Button } from "../LegacyButton";
import { useAuthentication } from "./AuthUserProvider";

/**
 * This component shows the Logout button for Auth0 and returns back to origin
 */
export const LogoutButton = ({
  forcedClassName,
}: {
  forcedClassName?: string;
}) => {
  const { logout } = useAuthentication();

  return (
    <Button
      data-testid="LOGOUT_BUTTON"
      variant={!forcedClassName ? "transparent" : undefined}
      variantClassName="ml-2 text-sm min-w-[95px]"
      className={!!forcedClassName ? forcedClassName : undefined}
      onClick={logout}
    >
      Log Out
    </Button>
  );
};
