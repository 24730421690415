import { Fragment, useMemo } from "react";

import classNames from "classnames";
import { Column } from "@tanstack/react-table";
import { Float } from "@headlessui-float/react";
import { Popover, Transition } from "@headlessui/react";

import { FilterIcon } from "../icons/FilterIcon";
import { MultipleDropdownSelector } from "../forms/DropdownSelector";

export type ColumnHeaderFilterIconProps = {
  column: Column<any, unknown>;
};

/** Shows user the Arrow icon based on the current condition */
export const ColumnHeaderFilterIcon = ({
  column,
}: ColumnHeaderFilterIconProps) => {
  const columnFilterValue = (column.getFilterValue() || []) as string[];

  const options = useMemo(() => {
    const uniqueValues = Array.from(column.getFacetedUniqueValues().keys());

    return uniqueValues
      .filter((value) => value !== null && value !== undefined && value !== "")
      .sort();
  }, [column.getFacetedUniqueValues()]);

  if (options.length <= 1) {
    return <></>;
  }

  return (
    <div
      onClick={(event) => event.stopPropagation() /** prevent parent event */}
    >
      <Popover>
        <Float>
          <Popover.Button className="flex justify-center">
            <FilterIcon
              className={classNames({
                "text-brand": columnFilterValue.length !== 0,
                "text-primaryText opacity-40 group-hover:opacity-100":
                  columnFilterValue.length === 0,
              })}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel>
              <MultipleDropdownSelector
                id={column.id}
                values={columnFilterValue}
                options={options}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Filter... (${options.length})`}
              />
            </Popover.Panel>
          </Transition>
        </Float>
      </Popover>
    </div>
  );
};
