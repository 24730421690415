import { QueryClient } from "@tanstack/react-query";

export const DEFAULT_RETRY_DELAY = 2500;

/** a client for the react-query library, main entry point for the react-query library */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // refresh once an hour
      retryDelay: DEFAULT_RETRY_DELAY,
    },
  },
});
