import { Fragment } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import classNames from "classnames";
import { Tab } from "@headlessui/react";

import { Header } from "../Components/Header";
import { Button } from "../Components/LegacyButton";
import { Loading } from "../Components/Loading";
import { SSHKeyContainer } from "../Components/sshKey/SshKeyContainer";
import { ProfileContainer } from "../Components/user/ProfileContainer";
import { CompanyContainer } from "../Components/account/CompanyContainer";
import { BillingContainer } from "../Components/settings/BillingContainer";
import { PaymentsContainer } from "../Components/settings/PaymentsContainer";
import { NotificationsContainer } from "../Components/settings/NotificationsContainer";

import { useAuthentication } from "../Components/auth/AuthUserProvider";

import { camelCaseToSnakeCase } from "../utils/textFormatter";

import { User } from "../types/user";

export const SettingsPathnames = {
  profile: "/settings/profile",
  sshKey: "/settings/ssh-key",
  notifications: "/settings/notifications",
  billing: "/settings/billing",
  payments: "/settings/payments",
  company: "/settings/company",
};

export const SettingsLabels = {
  [SettingsPathnames.profile]: "Profile",
  [SettingsPathnames.sshKey]: "SSH Key",
  [SettingsPathnames.notifications]: "Notifications",
  [SettingsPathnames.billing]: "Billing",
  [SettingsPathnames.payments]: "Payments",
  [SettingsPathnames.company]: "Company",
};

const getPanelComponent = (name: string, userInfo: User, accountId: string) => {
  switch (name) {
    case SettingsPathnames.profile:
      return <ProfileContainer userInfo={userInfo} />;
    case SettingsPathnames.sshKey:
      return <SSHKeyContainer accountId={accountId} />;
    case SettingsPathnames.notifications:
      return <NotificationsContainer userInfo={userInfo} />;
    case SettingsPathnames.billing:
      return <BillingContainer accountId={accountId} />;
    case SettingsPathnames.payments:
      return <PaymentsContainer accountId={accountId} />;
    case SettingsPathnames.company:
      return <CompanyContainer />;
  }
};

/**
 * This component handles everything related to:
 * - Title
 * - Vertical Tabs
 * - Tabs matching with pathname
 * - Content for the respective tab
 */
const SettingsContent = ({
  userInfo,
  accountId,
}: {
  userInfo: User;
  accountId: string;
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const currentTabIndex = Object.values(SettingsPathnames).findIndex(
    (path) => path === pathname,
  );

  const getTabsComponent = () => {
    return (
      <Tab.Group
        vertical={true}
        selectedIndex={currentTabIndex ?? 0}
        onChange={(index) => {
          const newPathname = Object.values(SettingsPathnames)[index];
          if (newPathname) {
            push(newPathname);
          }
        }}
      >
        <Tab.List className="flex flex-col items-baseline m-4">
          {Object.values(SettingsLabels).map((label) => (
            <Tab key={label} as={Fragment}>
              {({ selected }) => (
                <div
                  className={classNames("border-l-2 py-1", {
                    "border-brand": selected,
                    "border-background hover:border-brandHover": !selected,
                  })}
                >
                  <Button
                    data-testid={`${camelCaseToSnakeCase(label)}_TAB`}
                    textVariant={true}
                    variant={selected ? "brand" : "transparent"}
                    variantClassName={classNames(
                      "truncate min-w-[150px] text-left",
                      {
                        "font-semibold": selected,
                      },
                    )}
                  >
                    {label}
                  </Button>
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full px-2 m-4">
          {Object.values(SettingsPathnames).map((pathname) => (
            <Tab.Panel key={pathname}>
              {getPanelComponent(pathname, userInfo, accountId)}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    );
  };

  return (
    <div className="pb-4 bg-background min-h-page">
      <Header title="Settings" description="Update your info here." />
      <div className="relative flex px-4 pt-3 overflow-x-auto">
        {getTabsComponent()}
      </div>
    </div>
  );
};

/**
 * This component shows the full profile detail for the user
 * It mainly shows the user information and their ssh keys and billing information
 */
export const Settings = () => {
  const { userInfo, isLoading, accountId } = useAuthentication();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo) {
    return <SettingsContent userInfo={userInfo} accountId={accountId!} />;
  }

  return <Redirect to="/" />;
};
