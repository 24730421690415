import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "../LegacyButton";
import { DashboardPathnames } from "../../pages/Dashboard";

import { CloseIcon } from "../icons/CloseIcon";
import { DeleteIcon } from "../icons/DeleteIcon";

import { useDeleteOrder } from "../../state/order";
import { formatMoneyString } from "../../utils/currencyFormatter";

import { Order } from "../../types/order";

const getInformation = (order: Order, isAdmin: boolean) => {
  if (isAdmin) {
    return [
      {
        label: "Account ID",
        value: order.accountId,
      },
      {
        label: "Max Price (GPU/hr)",
        value: `${formatMoneyString(order.maxPricePerGPU ?? 0)} (${
          order.type === "float" ? "Floating" : "Fixed"
        })`,
      },
    ];
  }

  return [
    {
      label: "# of GPUs",
      value: order.numberOfGPUs.toString(),
    },
    {
      label: "Max Price (GPU/hr)",
      value: `${formatMoneyString(order.maxPricePerGPU ?? 0)} (${
        order.type === "float" ? "Floating" : "Fixed"
      })`,
    },
  ];
};

interface DeleteOrderModalContentProps {
  onClose: () => void;
  order: Order;
}

/**
 * This component shows content related to deleting an order
 * Giving you title, description and buttons for action
 */
export const DeleteOrderModalContent = ({
  onClose,
  order,
}: DeleteOrderModalContentProps) => {
  const { pathname } = useLocation();
  const isAdmin = pathname === DashboardPathnames.orders;

  const { deleteOrder, isSuccess } = useDeleteOrder();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex items-center justify-between mb-1">
        <div className="p-3 rounded-full bg-negative bg-opacity-20 w-fit">
          <DeleteIcon className="text-negative" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="DELETE_ORDER_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Delete Order
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        Are you sure you want to delete this order?
      </span>
      <div className="py-4 text-secondaryText">
        <span>
          This action cannot be undone.{" "}
          <span className="font-semibold underline">
            This will permanently delete this order and your data will not be
            recoverable.
          </span>
        </span>
      </div>
      <div className="grid grid-cols-2 gap-2 p-2 mt-2 mb-6 border rounded-lg bg-background">
        {getInformation(order, isAdmin).map((information) => (
          <div key={information.label} className="flex flex-col p-1">
            <span className="text-sm leading-none text-secondaryText">
              {information.label}
            </span>
            <span className="pt-1 text-base font-medium leading-normal">
              {information.value}
            </span>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-4 pb-1">
        <Button
          data-testid="CANCEL_BUTTON"
          type="button"
          variant="transparent"
          variantClassName="w-1/2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="DELETE_ORDER_BUTTON"
          type="button"
          variant="red"
          variantClassName="w-1/2"
          onClick={() => deleteOrder({ id: order.id })}
        >
          Delete Order
        </Button>
      </div>
    </>
  );
};
