import { SshKeysPanel } from "./SshKeysPanel";

/**
 * This component shows the full detail of the ssh-key page
 * It mainly shows the ssh keys information
 * Deals with creating ssh-key, updating and  deleting it
 */
export const SSHKeyContainer = ({ accountId }: { accountId: string }) => {
  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col p-4 pt-0">
          <div>
            <h1 className="pb-1 text-xl font-semibold leading-7">SSH Key</h1>
            <span className="text-base font-normal leading-tight text-secondaryText">
              Update your SSH Key details here.
            </span>
          </div>
          <SshKeysPanel accountId={accountId} />
        </div>
      </div>
    </div>
  );
};
