import { useEffect, useState } from "react";

import { useAuthentication } from "./auth/AuthUserProvider";

import { useGetOrders } from "../state/order";
import { useGetSshKeys } from "../state/sshKey";
import {
  useGetAccountBalance,
  useGetAccountBillingInfo,
} from "../state/account";

import { isDev, isLocal } from "../utils/env";
import { isUnfulfilledOrder } from "../utils/order";

import { InfoIcon } from "./icons/InfoIcon";

type InfoType = { message: string; link?: string } | null;

const InfoBanner = ({ info }: { info: InfoType }) => {
  if (!info) {
    return <></>;
  }

  return (
    <nav className="flex px-4 mx-auto bg-brand sm:px-6 lg:px-8">
      <div className="flex items-center self-center justify-center w-full h-16">
        <InfoIcon className="text-background" />
        <div className="flex gap-1">
          <span className="text-sm font-medium leading-loose tracking-tight truncate text-background md:text-lg">
            {info.message}
          </span>
          {info.link && (
            <a
              href={info.link}
              data-testid="NOTIFICATION_DETAIL_LINK"
              className="flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-lg bg-white truncate w-fit"
            >
              Explore
            </a>
          )}
        </div>
      </div>
    </nav>
  );
};

const DevBanner = () => {
  if (!isDev && !isLocal) {
    return <></>;
  }

  return (
    <nav className="flex px-4 mx-auto bg-brand sm:px-6 lg:px-8">
      <div className="flex items-center self-center justify-center w-full h-6">
        <InfoIcon className="text-primaryText" />
        <span className="text-sm font-medium leading-loose tracking-tight truncate md:text-sm">
          {isDev && "THIS IS THE DEV SITE"}
          {isLocal && "THIS IS LOCAL SITE"}
        </span>
      </div>
    </nav>
  );
};

const AuthenticatedBanner = ({ accountId }: { accountId: string }) => {
  const [info, setInfo] = useState<InfoType>(null);

  const { data: { data: orders } = { data: [] }, isLoading: isOrdersLoading } =
    useGetOrders(accountId);
  const unfulfilledOrdersCount = orders.filter((order) =>
    isUnfulfilledOrder(order),
  ).length;

  const {
    data: { data: sshKeys } = { data: [] },
    isLoading: isSSHKeysLoading,
  } = useGetSshKeys(accountId);
  const sshKeysCount = sshKeys.length;

  const { data: balance = 0, isLoading: isBalanceLoading } =
    useGetAccountBalance(accountId);
  const { data: billingInfo, isLoading: isBillingInfoLoading } =
    useGetAccountBillingInfo(accountId);
  const paymentMethodCount = billingInfo?.paymentMethodCount || 0;

  useEffect(() => {
    if (
      isOrdersLoading ||
      isSSHKeysLoading ||
      isBillingInfoLoading ||
      isBalanceLoading ||
      unfulfilledOrdersCount === 0
    ) {
      return;
    }

    if (sshKeysCount === 0) {
      setInfo({
        link: "/settings/ssh-key",
        message: "SSH key is required before your order can start!",
      });
    }
    if (balance <= 0) {
      if (paymentMethodCount !== 0) {
        return;
      }
      setInfo({
        link: billingInfo?.url,
        message:
          "Payment method or balance required before your order can start!",
      });
    }
  }, [
    isOrdersLoading,
    isSSHKeysLoading,
    isBalanceLoading,
    isBillingInfoLoading,
    unfulfilledOrdersCount,
    sshKeysCount,
    balance,
    paymentMethodCount,
  ]);

  return (
    <>
      <DevBanner />
      <InfoBanner info={info} />
    </>
  );
};

/** Banner which is visible is case:
 * - environment is set to dev
 * - SSH Keys are missing for authenticated users
 * - Payment Method is missing for authenticated users
 */
export const Banner = () => {
  const { accountId } = useAuthentication();

  if (accountId) {
    return <AuthenticatedBanner accountId={accountId} />;
  }

  return <DevBanner />;
};
