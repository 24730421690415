import { useState, useMemo, useEffect } from "react";

import classNames from "classnames";

import { Button } from "../LegacyButton";
import { CloseIcon } from "../icons/CloseIcon";
import { CircularCheckIcon } from "../icons/CircularCheckIcon";

import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";

import {
  useGetAccountBalance,
  useChangeAccountBalance,
} from "../../state/account";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import { Account } from "../../types/account";
import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";

const keyToInput: InputKeyToInputType = {
  accountBalance: "disabled",
  newAccountBalance: "disabled",
  changeBalance: "any-currency",
};

const requiredKey: RequiredInputKeyType = {
  changeBalance: true,
};

const originalState = {
  accountBalance: "",
  newAccountBalance: "",
  changeBalance: "",
};

interface ChangeAccountBalanceModalContentProps {
  onClose: () => void;
  account: Account;
}

/**
 * This component shows content related to Changing balance to account
 * Giving you title, description and buttons for action
 * And Input to accept the amount in correct format
 */
export const ChangeAccountBalanceModalContent = ({
  onClose,
  account,
}: ChangeAccountBalanceModalContentProps) => {
  const { data: balance } = useGetAccountBalance(account.id);

  const { changeAccountBalance, isSuccess, data } = useChangeAccountBalance();

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  const newAccountBalance = useMemo(
    () => (balance || 0) + +state.changeBalance,
    [balance, state.changeBalance],
  );

  useEffect(() => {
    if (typeof balance === "number") {
      setState((prevState) => ({
        ...prevState,
        accountBalance: formatMoneyStringWithSign(balance),
      }));
    }
  }, [balance]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    changeAccountBalance({
      id: account.id,
      amount: +state.changeBalance,
    });
  };

  const getDescription = () => {
    const defaultDescription = (
      <span className="whitespace-break-spaces">
        {`The balance for `}
        <span className="font-semibold">{account.company}</span>
        {` (Account #${account.id}) has been changed. `}
      </span>
    );

    const orderIds = data?.orderIds;
    if (orderIds && orderIds.length !== 0) {
      return (
        <div className="flex flex-col gap-2">
          <div className="whitespace-break-spaces">
            <span>{defaultDescription}</span>
            <span>The following order has been paid:</span>
          </div>
          <div className="flex flex-col font-semibold">
            {orderIds.map((orderId) => (
              <span>{`Order #${orderId}`}</span>
            ))}
          </div>
        </div>
      );
    }

    return defaultDescription;
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      {isSuccess && (
        <div className="flex items-center justify-between">
          <div className="p-3 mb-2 bg-green-100 rounded-full w-fit">
            <CircularCheckIcon className="text-positive" />
          </div>
          <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
            <CloseIcon className="text-name hover:scale-110" />
          </Button>
        </div>
      )}
      <div className="flex items-center justify-between">
        <h1
          data-testid="EDIT_ACCOUNT_BALANCE_MODAL_HEADER"
          className="text-xl font-semibold leading-10 tracking-wide"
        >
          {isSuccess && "Thanks!"}
          {!isSuccess && "Change Balance"}
        </h1>
        {!isSuccess && (
          <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
            <CloseIcon className="text-name hover:scale-110" />
          </Button>
        )}
      </div>
      {isSuccess && (
        <span
          data-testid="BALANCE_SUCCESS_DESCRIPTION"
          className="font-normal leading-normal text-secondaryText"
        >
          {getDescription()}
        </span>
      )}
      <form onSubmit={onSubmit}>
        {!isSuccess && (
          <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
            <div className="grid grid-cols-1 gap-2">
              <GenericInputs
                state={{
                  ...state,
                  newAccountBalance:
                    formatMoneyStringWithSign(newAccountBalance),
                }}
                originalState={originalState}
                inputs={keyToInput}
                required={requiredKey}
                errors={errors}
                onChange={onChange}
              />
            </div>
          </div>
        )}
        <div
          className={classNames("flex items-center justify-center gap-4 pb-1", {
            "pt-4": isSuccess,
          })}
        >
          <Button
            data-testid="CANCEL_BUTTON"
            type="button"
            variant="transparent"
            variantClassName="w-1/2"
            onClick={onClose}
          >
            {isSuccess ? "Close" : "Cancel"}
          </Button>
          {!isSuccess && (
            <Button
              data-testid="CHANGE_BALANCE_BUTTON"
              type="submit"
              variant="dark"
              variantClassName="w-1/2"
              disabled={
                !isSubmitButtonEnabled || newAccountBalance === (balance || 0)
              }
            >
              Change Balance
            </Button>
          )}
        </div>
      </form>
    </>
  );
};
