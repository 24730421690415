import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { Connectivity, ConnectivityOption } from "./constants";

type ConnectivityToggleProps = {
  caption?: string;
  className?: string;
  defaultSelected?: ConnectivityOption;
  disable?: ConnectivityOption;
  name: string;
  onSelected?: (v: ConnectivityOption) => void;
};

const selectionIfDisabled = (disable: ConnectivityOption | undefined) => {
  if (disable === Connectivity.ETHERNET) {
    return Connectivity.INFINIBAND;
  }
  if (disable === Connectivity.INFINIBAND) {
    return Connectivity.ETHERNET;
  }
  return false;
};

export const ConnectivityToggle = ({
  caption,
  className,
  defaultSelected = Connectivity.ETHERNET,
  disable,
  name,
  onSelected,
}: ConnectivityToggleProps) => {
  const [selected, setSelected] = useState<ConnectivityOption>(
    selectionIfDisabled(disable) || defaultSelected,
  );

  useEffect(() => {
    if (onSelected) {
      onSelected(selected);
    }
  }, [selected]);

  useEffect(() => {
    const targetSelection = selectionIfDisabled(disable);

    if (targetSelection) {
      setSelected(targetSelection);
    }
  }, [disable]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value as ConnectivityOption);
  };

  const containerClassName = classNames(
    className,
    "relative mb-2 inline-block rounded-lg border border-black [&:has(:focus-visible)]:ring-2 h-[2.625rem] bg-[#E2E2E2]",
  );

  const labelBaseClassName = classNames("relative inline-flex flex-wrap h-8", {
    "cursor-pointer": disable === undefined,
  });

  const copyBaseClassName = "inline-block pl-[1.125rem] pt-3 text-[0.9375rem]";
  const copyDisabledClassName = "text-[#959595]";
  const copyEthernetClassName = classNames(copyBaseClassName, "pl-[1.125rem]", {
    [copyDisabledClassName]: disable === Connectivity.ETHERNET,
  });
  const copyInfinibandClassName = classNames(
    copyBaseClassName,
    "pr-[1.125rem]",
    {
      [copyDisabledClassName]: disable === Connectivity.INFINIBAND,
    },
  );

  const buttonBaseClassName =
    "bg-white h-8 absolute top-1 rounded-md transition-left transition-width ease-in duration-100 motion-reduce:transition-none";
  const buttonInfinibandClassName = classNames(
    buttonBaseClassName,
    "left-[10.25rem] w-[10.1875rem]",
  );
  const buttonEthernetClassName = classNames(
    buttonBaseClassName,
    "left-1 w-[9.875rem]",
  );
  const buttonClassName = classNames({
    [buttonInfinibandClassName]: selected === Connectivity.INFINIBAND,
    [buttonEthernetClassName]: selected === Connectivity.ETHERNET,
  });

  return (
    <fieldset className={containerClassName}>
      <div className={buttonClassName} />
      <label className={labelBaseClassName}>
        <input
          type="radio"
          className="appearance-none focus-visible:outline-none focus-visible:shadow-none"
          name={name}
          value={Connectivity.ETHERNET}
          onChange={onChange}
          defaultChecked={defaultSelected === Connectivity.ETHERNET}
          disabled={disable === Connectivity.ETHERNET}
        />
        <span className={copyEthernetClassName}>100 Gbps Ethernet</span>
      </label>
      <label className={classNames(labelBaseClassName, "ml-3.5")}>
        <input
          type="radio"
          className="appearance-none focus-visible:outline-none focus-visible:shadow-none"
          name={name}
          value={Connectivity.INFINIBAND}
          onChange={onChange}
          defaultChecked={defaultSelected === Connectivity.INFINIBAND}
          disabled={disable === Connectivity.INFINIBAND}
        />
        <span className={copyInfinibandClassName}>3.2 Tbps InfiniBand</span>
      </label>
      {caption !== undefined && (
        <p className=" mt-2 text-[#757575] text-[0.8125rem]">{caption}</p>
      )}
    </fieldset>
  );
};
