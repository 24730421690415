import moment from "moment";

import { OrderStatus } from "../../types/order";

// List of actions to be displayed from status
const actionFromStatus = {
  not_ready: "No Estimated Start",
  eligible: "No Estimated Start",
  large: "No Estimated Start",
  allocating: "Estimated Start",
  winning: "Estimated Start",
  paused: "Activate by",
  active: "Ends on",
  closing: "Closing on",
  finished: "Finished on",
  deleted: "Deleted on",
};

// List of descriptions to be displayed from status
const descriptionFromStatus = (
  status: OrderStatus,
  startAt?: Date,
  endAt?: Date,
) => {
  switch (status) {
    case "not_ready":
      return "Requires SSH key and payment method or balance";
    case "eligible":
      return "Max Price is below current price";
    case "large":
      return "Not enough machines available";
    case "allocating":
    case "winning":
      return !!startAt ? moment(startAt).format("LL") : "Soon";
    case "paused":
    case "active":
    case "closing":
    case "finished":
    case "deleted":
      return !!endAt ? moment(endAt).format("LL") : "Not Available";
    default:
      return "Not Available";
  }
};

/**
 *  Display Custom Date component for Orders
 */
export const CustomOrderActionComponent = ({
  status,
  startAt,
  endAt,
}: {
  status: OrderStatus;
  startAt?: Date;
  endAt?: Date;
}) => {
  const action = actionFromStatus[status];
  const description = descriptionFromStatus(status, startAt, endAt);

  return (
    <div className="flex flex-col">
      <span className="text-secondaryText">{action ?? ""}</span>
      <span className="font-medium">{description ?? ""}</span>
    </div>
  );
};
