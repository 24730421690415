import { useState } from "react";

import { Button } from "../LegacyButton";
import { InstancesModal } from "./InstancesModal";
import { InstancesMachines } from "./ConfiguredClusterComponent";

import { isExpiredOrder } from "../../utils/order";

import { Instance } from "../../types/instance";

interface InstanceInfoComponentProps {
  isAdmin: boolean;
  instance: Instance;
  accountId: string;
}

/**
 * This component is incharge of showing the subcomponent of selected instance
 * We show Component based on the current status of instance
 */
export const InstanceInfoComponent = ({
  isAdmin,
  instance,
  accountId,
}: InstanceInfoComponentProps) => {
  const [showModal, setShowModal] = useState<string | null>(null);

  const getActionButtons = () => {
    if (!isAdmin) {
      return <></>;
    }

    return (
      <>
        <div className="pl-8 px-2 w-[260px] flex flex-wrap content-between justify-between">
          <div className="flex flex-col w-full gap-3">
            <h3 className="text-base font-semibold text-center">Options:</h3>
            <Button
              data-testid="ASSIGN_IPS_MODAL_BUTTON"
              disabled={isExpiredOrder(instance)}
              variant="dark"
              variantClassName="h-[35px]"
              onClick={() => setShowModal("assign-ips")}
            >
              Assign IPs
            </Button>
            <Button
              data-testid="UPDATE_INSTANCE_MODAL_BUTTON"
              disabled={isExpiredOrder(instance)}
              variant="transparent"
              variantClassName="h-[35px]"
              onClick={() => setShowModal("update-instance")}
            >
              Update Instance
            </Button>
            <Button
              data-testid="DELETE_INSTANCE_MODAL_BUTTON"
              disabled={isExpiredOrder(instance)}
              variant="red"
              invertVariant={true}
              variantClassName="h-[35px]"
              onClick={() => setShowModal("delete-instance")}
            >
              Delete Instance
            </Button>
          </div>
        </div>
        <InstancesModal
          isOpen={
            showModal === "delete-instance" ||
            showModal === "update-instance" ||
            showModal === "assign-ips"
          }
          onClose={() => setShowModal(null)}
          modalName={showModal}
          instance={instance}
        />
      </>
    );
  };

  // TODO: The config list and configId does not exist in BE, therefore the logic to select configId is disabled
  // return (
  //   <div className="flex m-2 bg-white border rounded-lg shadow-md">
  //     <div className="flex justify-between w-full p-6">
  //       {!isAdmin && instance.configId <= 0 && (
  //         <UnconfiguredClusterComponent instance={instance} />
  //       )}
  //       {(isAdmin || instance.configId > 0) && (
  //         <ConfiguredClusterComponent isAdmin={isAdmin} instance={instance} />
  //       )}
  //       {getActionButtons()}
  //     </div>
  //   </div>
  // );

  return (
    <div className="flex m-2 bg-white border rounded-lg shadow-md">
      <div className="flex justify-between w-full p-6">
        <InstancesMachines
          isAdmin={isAdmin}
          instance={instance}
          accountId={accountId}
        />
        {getActionButtons()}
      </div>
    </div>
  );
};
