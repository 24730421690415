import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import {
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { Button } from "../LegacyButton";
import { DeleteIcon } from "../icons/DeleteIcon";
import { AccountUsersModal } from "./AccountUsersModal";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { UserDetailComponent } from "../user/UserDetailComponent";

import {
  useGetAccountUsers,
  useUpdateAccountMembership,
} from "../../state/account";
import { useAuthentication } from "../auth/AuthUserProvider";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { AccountUser } from "../../types/account";
import { DropdownSelector } from "../forms/DropdownSelector";

const columnHelper = createColumnHelper<AccountUser>();

const DefaultData: AccountUser[] = [];

/**
 * This component show account's user table
 * We use this table mostly as sub-component under Company page
 */
export const AccountUsersTable = ({ accountId }: { accountId: string }) => {
  const { userInfo } = useAuthentication();

  const { data, refetch } = useGetAccountUsers(accountId);
  const { updateAccountMembership, isSuccess } = useUpdateAccountMembership();
  const [sorting, setSorting] = useState<SortingState>([
    { id: "userStatus", desc: true },
  ]);

  const [showModal, setShowModal] = useState<string | null>(null);

  const columns = [
    columnHelper.accessor("name", {
      id: "userName",
      header: "User",
      cell: ({ cell }) => (
        <UserDetailComponent
          name={cell.row.original.name}
          email={cell.row.original.email}
        />
      ),
    }),
  ];

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      columnHelper.accessor("accountRole", {
        id: "accountRole",
        header: "Account Role",
        cell: ({ cell }) => {
          const onRoleChanged = (role: string) => {
            updateAccountMembership({
              id: accountId,
              userID: cell.row.original.id,
              role,
            });
          };

          return (
            <div>
              <DropdownSelector
                id="role-selector"
                value={cell.row.original.accountRole}
                required={true}
                disabled={userInfo && userInfo.id === cell.row.original.id}
                options={[
                  {
                    value: "collaborator",
                    label: "Collaborator",
                  },
                  {
                    value: "account_admin",
                    label: "Admin",
                  },
                ]}
                onChange={onRoleChanged}
              />
            </div>
          );
        },
      }),
      {
        id: "options",
        header: "Actions",
        cell: ({ cell }: { cell: Cell<AccountUser, undefined> }) => (
          <>
            <Button
              data-testid="DELETE_ACCOUNT_USER_MODAL_BUTTON"
              className="p-1"
              disabled={userInfo && userInfo.id === cell.row.original.id}
              onClick={() => setShowModal("delete-user")}
            >
              <DeleteIcon className="hover:scale-110 text-secondaryText" />
            </Button>
            <AccountUsersModal
              isOpen={showModal === "delete-user"}
              onClose={() => setShowModal(null)}
              modalName={showModal}
              accountId={accountId}
              user={cell.row.original}
            />
          </>
        ),
      },
    ],
    [data?.data, showModal, userInfo],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,

    state: {
      sorting,
    },

    getCoreRowModel: getCoreRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const getRowData = (cell: Cell<AccountUser, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  return (
    <div className="relative overflow-x-auto max-h-[20rem] rounded-lg shadow-md">
      <table className="w-full text-sm text-left">
        {/* Table column names */}
        <thead className="border select-none bg-background whitespace-nowrap">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} scope="col" className="p-3 group">
                  {header.isPlaceholder && <></>}
                  {!header.isPlaceholder && (
                    <div
                      className={classNames("flex items-center w-fit", {
                        "cursor-pointer select-none mr-2.5":
                          header.column.getCanSort(),
                        "text-brand": header.column.getIsSorted(),
                      })}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <ColumnHeaderIcon column={header.column} />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* Table data */}
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.original.id}
              data-testid={`${row.original.id}_ACCOUNT_USER_ROW`}
              className="bg-white border border-background"
            >
              {row.getVisibleCells().map((cell) => getRowData(cell))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
