import { IMessageEvent, w3cwebsocket as WebSocketClient } from "websocket";

import { refetchSshKeys } from "../state/sshKey";
import { refetchUsers, refetchUserInfo } from "../state/user";
import { refetchOrders, refetchOrderbook } from "../state/order";
import { refetchAccounts, refetchAccountInfo } from "../state/account";

import { isDev, isLocal } from "../utils/env";

const getServerUrl = () => {
  const httpUrl = process.env.REACT_APP_API_URL ?? "";
  return (
    httpUrl.replace("http://", "ws://").replace("https://", "wss://") + "/ws"
  );
};

const MessageType = {
  UserNameChanged: "UserNameChanged", // on post -> /api/user/user/set-name (USER)
  UserTypeChanged: "UserTypeChanged", // on post -> /api/user/change-type (ADMIN)

  AccountCompanyChanged: "AccountCompanyChanged", // on post -> /api/account/set-company (USER)
  AccountBalanceChanged: "AccountBalanceChanged", // on post -> /api/account/change-balance (ADMIN)

  OrderCreated: "OrderCreated", // on post -> /api/order/create (USER/ADMIN)
  OrderDeleted: "OrderDeleted", // on post -> /api/order/delete (USER/ADMIN)
  OrderUpdated: "OrderUpdated", // on post -> /api/order/update (USER/ADMIN)
  OrderConfigUpdated: "OrderConfigUpdated", // on post -> /api/order/config (USERS/ADMIN)
  OrderIPsAssigned: "OrderIPsAssigned", // on post -> /api/order/assign-ips (ADMIN)

  UpdatesOnOrders: "UpdatesOnOrders", // when multiple orders receive some updates
  UpdatesOnSSHKeys: "UpdatesOnSSHKeys", // when an account's ssh keys receive some updates

  OrdersPaid: "OrdersPaid", // when multiple orders are marked as paid
  OrdersNotPaid: "OrdersNotPaid", // when multiple orders could not be marked as paid
  OrderPaid: "OrderPaid", // when order status is marked as paid
  OrderNotPaid: "OrderNotPaid", // when order status could not be marked as paid
  OrderBookUpdated: "OrderBookUpdated", // when order book is updated
};

const processMessage = (message: IMessageEvent) => {
  const body = JSON.parse(message.data as string);

  if (isDev || isLocal) {
    console.log("wss msg:", body);
  }

  switch (body.type) {
    case MessageType.UserNameChanged:
    case MessageType.UserTypeChanged: {
      const canBeRedirectedToHome = body.type === MessageType.UserTypeChanged;
      refetchUserInfo(body.data);
      refetchUsers(body.data, canBeRedirectedToHome);
      break;
    }
    case MessageType.AccountCompanyChanged:
    case MessageType.AccountBalanceChanged: {
      refetchAccountInfo(body.data);
      refetchAccounts(body.data);
      break;
    }
    case MessageType.UpdatesOnSSHKeys: {
      refetchSshKeys(body.data);
      break;
    }
    case MessageType.OrderCreated: {
      refetchOrders();
      break;
    }
    case MessageType.OrderConfigUpdated:
    case MessageType.OrderIPsAssigned:
    case MessageType.OrderUpdated:
    case MessageType.OrderDeleted: {
      refetchOrders([body.data], { refetchInstances: true });
      break;
    }
    case MessageType.UpdatesOnOrders: {
      refetchOrders(body.data, { refetchInstances: true });
      break;
    }
    case MessageType.OrderPaid:
    case MessageType.OrdersPaid:
    case MessageType.OrderNotPaid:
    case MessageType.OrdersNotPaid: {
      refetchAccountInfo(body.data?.accountID);

      const queryParams = new URLSearchParams({
        paymentSuccess: [MessageType.OrderPaid, MessageType.OrdersPaid]
          .includes(body.type)
          .toString(),
        orderIds: body.data?.orderIDs?.toString(),
        orderId: body.data?.orderID,
      });
      refetchOrders(
        !!body.data?.orderID ? [body.data?.orderID] : body.data?.orderIDs,
        {
          refetchInstances: true,
          redirectPage: `/orders?${queryParams.toString()}`,
        },
      );
      break;
    }
    case MessageType.OrderBookUpdated: {
      refetchOrderbook();
      break;
    }
  }
};

let socket: WebSocketClient | null = null;

const reconnect = () => {
  if (socket) {
    return;
  }

  socket = new WebSocketClient(getServerUrl());

  socket.onopen = () => console.log("WebSocket Client Connected", new Date());
  socket.onclose = () => {
    console.log("WebSocket Client Closed", new Date());
    socket = null;
    reconnect();
  };
  socket.onerror = (error: Error) => console.log("socket error: ", error);

  socket.onmessage = processMessage;
};

/** Establishes a websocket connection with the server */
export const openSocket = () => {
  reconnect();
  return socket;
};
