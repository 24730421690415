import { useEffect, useState } from "react";

import { Message } from "../forms/Message";
import { EditNameComponent } from "./EditNameComponent";

import { Instance } from "../../types/instance";
import { useUpdateOrder } from "src/state/order";

export type NameComponentProps = {
  instance: Instance;
};

/**
 * This component renders the name of the instance as well as allowing the ability to edit it.
 */
export const NameComponent = ({ instance }: NameComponentProps) => {
  const { updateOrder, isError, isSuccess } = useUpdateOrder();

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    if (isSuccess) {
      setIsFormDisabled(true);
    }
  }, [isSuccess]);

  const handleSubmit = (newName: string) => {
    if (newName && instance.name !== newName) {
      updateOrder({
        id: instance.id,
        priority: instance.priority,
        name: newName,
        type: instance.type,
        numberOfGPUs: instance.numberOfGPUs,
        maxPricePerGPU: instance.maxPricePerGPU,
        isAdmin: false,
      });
    } else {
      setIsFormDisabled(true);
    }
  };

  return (
    <div className="flex flex-col justify-center h-full">
      <EditNameComponent
        name={instance.name}
        isError={isError}
        isDisabled={isFormDisabled}
        setIsFormDisabled={(value) => setIsFormDisabled(value)}
        handleSubmit={handleSubmit}
      />
      <Message
        id="name"
        message={isError ? "Please try again!" : ""}
        showIcon={false}
      />
    </div>
  );
};
