import { Redirect } from "react-router-dom";

import { Header } from "../Components/Header";
import { Loading } from "../Components/Loading";

import { useAuthentication } from "../Components/auth/AuthUserProvider";

import { useSearchParams } from "../hooks/useSearchParams";

/**
 * This component awaits response from BE to redirect to payment page based on the orderId
 * It shows Loading while it await and if it failed shows the Invalid Route message
 */
const PayOrder = ({ orderId }: { orderId: string }) => {
  return <></>;
};

/*
 * A page containing int18 Checkout page at /checkout.
 */
export const Checkout = () => {
  const { urlSearchParams } = useSearchParams();
  const orderId = urlSearchParams.get("id") ?? undefined;

  const { userInfo, isLoading } = useAuthentication();

  if (!orderId) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo) {
    return (
      <div className="pb-4 bg-background min-h-page">
        <Header
          title="Pay your order"
          description="Please wait while we redirect you!"
        />
        <div className="p-8 mb-48 lg:py-8 lg:px-60">
          <PayOrder orderId={orderId} />
        </div>
      </div>
    );
  }

  return <Redirect to="/" />;
};
