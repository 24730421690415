import React, { ReactNode, useEffect, useState } from "react";

import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";

import { useTabActive } from "../../hooks/useTabActive";
import { clearUserInfo, useGetUserInfo } from "../../state/user";

import { TokenConfig } from "../../utils/AxiosInterceptor";

import { User } from "../../types/user";

type AuthInfo = {
  /**
   * Get the auth0 token used for authentication
   * In the case of not a valid user or user not being logged in with auth0 returns undefined
   */
  getAccessToken: () => Promise<string | undefined>;
  /**
   * Performs a login authorize user trying to gain access to the system
   */
  login: (returnTo?: string) => Promise<void>;
  /**
   * Clears the application session and performs a redirect to /v2/logout to clear the Auth0 session.
   */
  logout: () => Promise<void>;

  setAccount: (accountId: string) => void;
  /**
   * The user picture from Auth0
   */
  userPicture: string | undefined;
  /**
   * If true userInfo is in the process of obtaining the user information,
   * if false all the info is present in userInfo object
   */
  isLoading: boolean;
  /**
   * The User from User Info API
   */
  userInfo: User | undefined;

  accountId: string | null;
};

const defaultValues = {
  getAccessToken: () => Promise.resolve(undefined),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setAccount: (accountId: string) => Promise.resolve(),
  userPicture: undefined,
  isLoading: false,
  userInfo: undefined,
  accountId: null,
};

const AuthContext = React.createContext<AuthInfo>(defaultValues);
const AuthProvider = AuthContext.Provider;

export const useAuthentication = () => React.useContext(AuthContext);

/**
 * Provides a singleton of the useAuthentication. In this way only a single call
 * is made to Auth0 and the Backend. (For every component that uses a hook a separate
 * instance of that hook is running.) In this way we have a single instance and single
 * source of truth for the logged user and their info/role.
 */
export const AuthUserProvider = ({ children }: { children: ReactNode }) => {
  const isTabCurrentlyVisible = useTabActive();

  const {
    user: auth0User,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState<string | null>(
    localStorage.getItem("currentAccountID") == ""
      ? null
      : localStorage.getItem("currentAccountID"),
  );

  // retries the access token from auth0
  const getAccessToken = (params?: GetTokenSilentlyOptions) => {
    // getAccessTokenSilently gets:
    //  - an auth0 access token when the user is logged in
    //  - throws an error when the user is logged out
    // the method has a side effect that:
    //  - if an user logs into tab1
    //  - and tab2 calls the method
    //  - then the user will be logged in tab2 as well
    // we are using this side effect for when the site is open in more than one tab
    return getAccessTokenSilently({ ...TokenConfig, ...params });
  };

  const {
    data: userInfo,
    isFetched: isUserFetched,
    refetch: refetchUserInfo,
  } = useGetUserInfo();

  useEffect(() => {
    // storing input name
    localStorage.setItem("currentAccountID", account || "");
  }, [account]);

  // after auth0 information is obtained:
  //  - if token is present, get the user info from the database
  //  - if token is not present, clear the user info from query cache
  // if auth0 information is not obtained, set loading as false
  useEffect(() => {
    if (!isAuth0Loading && !!auth0User) {
      getAccessToken()
        .then(() => refetchUserInfo())
        .catch(() => clearUserInfo());
    }
    if (!isAuth0Loading && !auth0User) {
      setIsLoading(false);
    }
  }, [isAuth0Loading, auth0User]);

  // if user info is fetched, set loading as false
  useEffect(() => {
    if (!!auth0User && !!isUserFetched) {
      setIsLoading(false);
    }
  }, [auth0User, isUserFetched]);

  // when a tab becomes active and the user is logged in, check to see if there wasn't a logout/login
  // event in some other tab. The check is performed by retrieving the access token and:
  //  - if token is present and user info is not present, get the user info from the database
  //  - if token is not present and user info is present, clear the user info from query cache
  useEffect(() => {
    if (!isLoading && isTabCurrentlyVisible) {
      getAccessToken()
        .then(() => {
          if (!userInfo) refetchUserInfo();
        })
        .catch(() => {
          if (!!userInfo) clearUserInfo();
        });
    }
  }, [isLoading, userInfo, isTabCurrentlyVisible]);

  useEffect(() => {
    if (!userInfo) {
      localStorage.clear();
      return;
    }
    if (userInfo && userInfo.accountIds.length > 0 && !account) {
      setAccount(userInfo.accountIds[0]);
      localStorage.setItem("currentAccountID", userInfo.accountIds[0]);
    }
  }, [userInfo]);

  // handles login with redirect to a certain page or the default page
  const login = (returnTo?: string) => {
    if (returnTo) {
      return loginWithRedirect({ appState: { returnTo } });
    }
    return loginWithRedirect();
  };

  const setAccountID = (accountId: string) => {
    setAccount(accountId);
  };

  const value = {
    getAccessToken,
    login,
    logout: () => {
      localStorage.removeItem("currentAccountID");
      return logout();
    },
    setAccount: setAccountID,
    userPicture: auth0User?.picture,
    isLoading,
    userInfo,
    accountId: account,
  };

  return <AuthProvider value={value}>{children}</AuthProvider>;
};
