import { useRef, useMemo, useState } from "react";

import classNames from "classnames";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  Cell,
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { Button } from "../LegacyButton";
import { MachinesModal } from "./MachinesModal";
import { MachineStateComponent } from "./MachineStateComponent";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { Machine } from "../../types/instance";
import { InventoryIcon } from "../icons/InventoryIcon";
import { usePowerCycleMachines } from "src/state/instance";

const columnHelper = createColumnHelper<Machine>();
const columns = [
  columnHelper.accessor("id", {
    id: "machineId",
    header: "Machine ID",
    cell: ({ cell }) => (
      <span className="font-semibold">{cell.getValue()}</span>
    ),
  }),
  columnHelper.accessor("privateIP", {
    id: "privateIP",
    header: "Private IP",
    cell: ({ cell }) => cell.getValue() || "-",
  }),
  columnHelper.accessor("publicIP", {
    id: "publicIP",
    header: "Public IP",
    cell: ({ cell }) => cell.getValue() || "-",
  }),
  columnHelper.accessor("state", {
    id: "state",
    header: "State",
    cell: ({ cell }) => (
      <MachineStateComponent state={cell.getValue() || "-"} />
    ),
  }),
];

/**
 * This component shows the machine table with ip
 * We use this table mostly as sub-component under Instances table
 */
export const MachinesTable = ({
  instanceId,
  machines = [],
  isAdmin = false,
}: {
  instanceId: string;
  machines: Machine[];
  isAdmin?: boolean;
}) => {
  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { powerCycleMachine, isSuccess } = usePowerCycleMachines();
  const [showModal, setShowModal] = useState<string | null>(null);
  const [currentMachine, setCurrentMachine] = useState<Machine | null>(null);

  const memoColumns = useMemo(
    () => [
      ...columns,
      // {
      //   id: "delete",
      //   header: "Delete",
      //   cell: ({ cell }: { cell: Cell<Machine, undefined> }) => (
      //     <Button
      //       data-testid="DELETE_MACHINE_MODAL_BUTTON"
      //       disabled={true}
      //       onClick={() => {
      //         setCurrentMachine(cell.row.original);
      //         setShowModal("delete-machine");
      //       }}
      //     >
      //       <DeleteIcon className="hover:scale-110 text-secondaryText" />
      //     </Button>
      //   ),
      // },
      {
        id: "power",
        header: "Reboot",
        cell: ({ cell }: { cell: Cell<Machine, undefined> }) => (
          <Button
            data-testid="POWER_CYCLE_MODAL_BUTTON"
            disabled={false}
            onClick={() => {
              setCurrentMachine(cell.row.original);
              powerCycleMachine({ instanceId, id: cell.row.original.id });
            }}
          >
            <InventoryIcon className="hover:scale-110 text-secondaryText" />
          </Button>
        ),
      },
    ],
    [machines, showModal],
  );

  const table = useReactTable({
    data: machines,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 45, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const getRowData = (cell: Cell<Machine, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="flex w-full p-3"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  return (
    <>
      <div
        ref={tableContainerRef}
        className={classNames("relative overflow-x-auto rounded-lg shadow-md", {
          "max-h-52": isAdmin,
        })}
      >
        <table className="grid text-sm text-left">
          {/* Table column names */}
          <thead className="sticky top-0 z-10 grid border select-none bg-background">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="flex w-full">
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="flex w-full p-3">
                    {header.isPlaceholder && <></>}
                    {!header.isPlaceholder && (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Table data */}
          <tbody
            className="relative grid"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <tr
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.original.id}
                  data-testid={`${row.original.id}_MACHINE_ROW`}
                  className="absolute flex items-center w-full bg-white border border-background"
                  style={{
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  }}
                >
                  {row.getVisibleCells().map((cell) => getRowData(cell))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!!currentMachine && showModal === "delete-machine" && (
        <MachinesModal
          isOpen={showModal === "delete-machine"}
          onClose={() => {
            setCurrentMachine(null);
            setShowModal(null);
          }}
          modalName={showModal}
          instanceId={instanceId}
          machine={currentMachine}
        />
      )}
      {!!currentMachine && showModal === "reprovision-machine" && (
        <MachinesModal
          isOpen={showModal === "reprovision-machine"}
          onClose={() => {
            setCurrentMachine(null);
            setShowModal(null);
          }}
          modalName={showModal}
          instanceId={instanceId}
          machine={currentMachine}
        />
      )}
    </>
  );
};
