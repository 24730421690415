import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "../LegacyButton";

import { EditIcon } from "../icons/EditIcon";
import { CloseIcon } from "../icons/CloseIcon";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import { Instance } from "../../types/instance";
import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";
import { useUpdateOrder } from "src/state/order";

interface EditInstanceModalContentProps {
  onClose: () => void;
  instance: Instance;
}

const keyToInput: InputKeyToInputType = {
  numberOfGPUs: "gpu-number",
};

const requiredKey: RequiredInputKeyType = {
  numberOfGPUs: true,
};

/**
 * This component shows content related to updating a instance
 * Giving you title and buttons for action
 * And Inputs to accept noOfGPUs, typeOfGPU, start date and duration
 */
export const EditInstanceModalContent = ({
  onClose,
  instance,
}: EditInstanceModalContentProps) => {
  const { pathname } = useLocation();

  const { updateOrder, isSuccess } = useUpdateOrder();

  const originalState = {
    numberOfGPUs: instance.numberOfGPUs.toString(),
  };
  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateOrder({
      id: instance.id,
      name: instance.name,
      priority: instance.priority,
      type: instance.type,
      numberOfGPUs: +state.numberOfGPUs,
      maxPricePerGPU: instance.maxPricePerGPU,
      isAdmin: false,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="p-3 rounded-full bg-brand bg-opacity-20 w-fit">
          <EditIcon className="text-brand" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="EDIT_INSTANCE_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Edit Instance
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        {`Order ID: ${instance.id}`}
      </span>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col pt-2 pb-6 tracking-tight text-left ">
          <div className="grid grid-cols-1 gap-4">
            <GenericInputs
              state={state}
              originalState={originalState}
              inputs={keyToInput}
              required={requiredKey}
              errors={errors}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-center gap-4 pb-1">
          <Button
            data-testid="CANCEL_BUTTON"
            type="button"
            variant="transparent"
            variantClassName="w-1/2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="UPDATE_INSTANCE_BUTTON"
            type="submit"
            variant="dark"
            variantClassName="w-1/2"
            disabled={!isSubmitButtonEnabled}
          >
            Update Instance
          </Button>
        </div>
      </form>
    </>
  );
};
