export const TooltipMessage = {
  placeNewOrder: "Place new order",

  currentPrices: "Current prices of GPU per hour",

  numberOfTotalGPUs: "Total available GPUs",
  numberOfAllocatedGPUs: "Total allocated GPUs",

  orderId: "Id of the order",
  accountId: "Id of the account",
  userId: "Id of the user",
  adminId: "Id of the admin",
  machineId: "Id of the machine",
  transactionId: "Id of the transaction",
  orderUserId: "Information of the user for whom the order is created",

  privateIP: "Private IP of the MAAS",
  publicIP: "Public IP of the MAAS",

  orderType: "Order Type / Pricing Model",
  transactionType: "Transaction Type / Type of Ledger",

  numberOfGPUs:
    "Each machine has 8 GPUs.\nWe don't subdivide machines so you can only order in multiples of 8.",

  pricePerGPU: "Price of GPU per hour",
  maxPricePerGPU:
    "Your maximum budget per GPU/hr.\nYour order might fill for less, but never more than this.",
  costOfGPUs: "Total cost of the GPUs",

  markOrderAsPaid: "Mark the order as paid",

  account: "Account ID and Company Name",

  orderPriority: "Priority of the order",

  orderStartDate: "Actual Date on which the order should start",
  orderStartAt: "Actual / Estimated Date on which the order will start",
  orderStartBy: "Date at which the order should start",

  orderStatus: "Status of the order",
  instanceStatus: "Status of the instance",
  userStatus: "Status of the user",
  machineStatus: "Status of the machine",

  action: "Available action on the respective item",

  instanceName: "Name of the instance",
  userName: "Name of the user",

  balanceTimestamp: "Timestamp of the changed balance",
  sshCreated: "Date when the SSH Key was added",

  accountPaymentLimit: "Card limit on the account (during payment)",
  accountAllowedDebt: "Allowed Debt ie. negative balance on the account",

  accountBalance: "Balance of the account",
  changeBalance: "Change the balance (+ve/-ve)",
  newAccountBalance: "Balance of the account after change",

  updatedAmount: "Amount that has been changed on the balance",
  totalAmount: "New Balance of the account",

  csvIpAddresses: "Upload IP Addresses from CSV",
  ipAddresses: "IP Addresses for the Machine",

  description: "Additional information",

  ipAddress: "IP Address of the Machine",

  sshTitle: "Title for the SSH",
  sshKey: "Key of the SSH",

  userEmail: "Email of the user",
  accountCompany: "Company of the account",
};
