import { useCallback, useEffect, useMemo, useRef } from "react";

import moment from "moment";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  Cell,
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { Loading } from "../Loading";

import { useGetOrderBalances } from "../../state/order";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { formatMoneyString } from "../../utils/currencyFormatter";

import { Order, OrderBalance } from "../../types/order";

const columnHelper = createColumnHelper<OrderBalance>();
const columns = [
  columnHelper.accessor("createdAt", {
    id: "createdAt",
    header: "Created At",
    cell: ({ cell }) => (
      <span
        className="font-semibold"
        title={moment(cell.getValue()).format("L LT")}
      >
        {moment(cell.getValue()).format("L LT")}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    id: "amount",
    header: "Charged Amount",
    cell: ({ cell }) => formatMoneyString(cell.getValue()),
  }),
];

/**
 * This component show order charges history table
 * We use this table mostly as sub-component under User Orders table
 */
export const OrderChargesTable = ({ order }: { order: Order }) => {
  const { data, fetchNextPage, isFetching, isLoading, hasNextPage } =
    useGetOrderBalances({
      id: order.id,
    });
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page.data) ?? [],
    [data],
  );

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (!containerRefElement) {
        return;
      }

      // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      // once the user has scrolled within 150px of the bottom of the table, fetch more data if we can
      if (
        hasNextPage &&
        scrollHeight - scrollTop - clientHeight < 150 &&
        !isFetching
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isFetching, hasNextPage],
  );

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 45, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const getRowData = (cell: Cell<OrderBalance, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.index}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="flex w-full p-3"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  if (flatData.length === 0) {
    return (
      <div className="flex items-center justify-center m-2 text-center bg-white rounded-lg">
        <p className="p-2 m-auto font-semibold text-secondaryText">
          Selected order does not have any charge!
        </p>
      </div>
    );
  }

  return (
    <div
      ref={tableContainerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      className="relative overflow-x-auto rounded-lg shadow-md max-h-52"
    >
      <table className="grid text-sm text-left">
        {/* Table column names */}
        <thead className="sticky top-0 z-10 grid border select-none bg-background">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="flex w-full">
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="flex w-full p-3">
                  {header.isPlaceholder && <></>}
                  {!header.isPlaceholder && (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* Table data */}
        <tbody
          className="relative grid"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                data-testid={`${row.id}_ORDER_BALANCE_ROW`}
                className="absolute flex items-center w-full bg-white border border-background"
                style={{
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                }}
              >
                {row.getVisibleCells().map((cell) => getRowData(cell))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
