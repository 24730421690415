import { createPortal } from "react-dom";

import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";

import "react-tooltip/dist/react-tooltip.css";

type TooltipProps = {
  id: string;
  message: string;
  direction?: PlacesType;
};

/**
 * A Generic Tooltip Component wrapped in Portal
 *
 * NOTE: You are supposed to pass the same id that you set in className
 *       Only that way anchorSelect will be a valid CSS selector to the element
 *       {@link https://react-tooltip.com/docs/getting-started#using-reacttooltip-props}
 * You are expected to pass the message that the tooltip is shown over as children
 * The tooltip is only shown if message is passed to this component, else just the component is shown
 */
export const Tooltip = ({ id, message, direction }: TooltipProps) => {
  return (
    <>
      {createPortal(
        <ReactTooltip
          anchorSelect={id ? `.${id}` : undefined}
          className="whitespace-pre px-3 py-2.5 font-medium tracking-tight text-xs leading-tight z-20"
          // NOTE: Portal is unable to set the default text size, thus style needs to be forced
          style={{ fontSize: 13 }}
          place={direction}
        >
          {message}
        </ReactTooltip>,
        document.body,
      )}
    </>
  );
};
