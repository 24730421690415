import classNames from "classnames";
import { MachineState } from "src/types/instance";

const stateToStyles = {
  unknown: "text-black bg-black bg-opacity-10 border-black border-opacity-30",
  ready: "text bg-ready bg-opacity-10 border-ready border-opacity-30",
  erasing: "text bg-chaining bg-opacity-10 border-chaining border-opacity-30",
  power_cycle:
    "text bg-chaining bg-opacity-10 border-chaining border-opacity-30",
  deployed:
    "text-positive bg-positive bg-opacity-10 border-positive border-opacity-30",
  deploying: "text bg-ready bg-opacity-10 border-ready border-opacity-30",
  failed:
    "text-negative bg-negative bg-opacity-10 border-negative border-opacity-30",
};

const stateToDotStyles = {
  unknown: "bg-black",
  ready: "bg-ready",
  erasing: "bg-chaining",
  power_cycle: "bg-chaining",
  deployed: "bg-positive",
  deploying: "bg-ready",
  failed: "bg-negative",
};

/**
 * This component handles how state is shown on the Machine Table
 */
export const MachineStateComponent = ({ state }: { state: MachineState }) => {
  if (!state) {
    return "-";
  }

  return (
    <div className="flex">
      <span
        className={classNames(
          "flex items-center px-2 py-0.5 text-sm font-medium text-center border rounded-lg truncate",
          stateToStyles[state],
        )}
      >
        <div
          className={classNames(
            "w-1.5 h-1.5 mr-2 rounded-full",
            stateToDotStyles[state],
          )}
        />
        {capitalizeFirstLetter(state)}
      </span>
    </div>
  );
};

function capitalizeFirstLetter(name: string): string {
  // remove any underscores
  name = name.replace(/_/g, " ");
  return name.charAt(0).toUpperCase() + name.slice(1);
}
