import { useState, useMemo, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import classNames from "classnames";
import {
  Row,
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  ColumnFiltersState,
  getFacetedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";

import { RowExpandIcon } from "../table/RowExpandIcon";
import { OrderInfoComponent } from "./OrderInfoComponent";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { OrderStatusComponent } from "./OrderStatusComponent";
import { CompanyDetailComponent } from "../account/CompanyDetailComponent";

import { useSearchParams } from "../../hooks/useSearchParams";

import { useGetAllOrders } from "../../state/order";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { formatMoneyString } from "../../utils/currencyFormatter";
import { columnListFilter, fuzzyFilter } from "../../utils/tableFilter";
import {
  sortingFunctionForOrderStatus,
  sortingFunctionForOrderStartDate,
} from "../../utils/tableSort";

import { DashboardPathnames } from "../../pages/Dashboard";

import { Order } from "../../types/order";

const columnHelper = createColumnHelper<Order>();
const columns = [
  columnHelper.accessor("id", {
    id: "orderId",
    header: "Order ID",
    cell: ({ cell }) => (
      <span className="font-semibold">{cell.getValue()}</span>
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("numberOfGPUs", {
    id: "numberOfGPUs",
    header: "# of GPUs",
    cell: ({ cell }) => cell.getValue(),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("maxPricePerGPU", {
    id: "maxPricePerGPU",
    header: "Max Price (GPU/hr)",
    cell: ({ cell }) => (
      <span className="px-2 py-0.5 text-sm text-center border rounded-lg font-semibold">
        {formatMoneyString(cell.getValue())}
      </span>
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("type", {
    id: "orderType",
    header: "Pricing Model",
    cell: ({ cell }) => (cell.getValue() === "float" ? "Floating" : "Fixed"),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("startAt", {
    id: "orderStartAt",
    header: "Start Date",
    cell: ({ cell }) => {
      const startAt = cell.getValue();
      if (!!startAt) {
        return (
          <span title={moment(startAt).format("LLL")}>
            {moment(startAt).format("LL")}
          </span>
        );
      }

      return "NA";
    },
    sortingFn: sortingFunctionForOrderStartDate,
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("email", {
    id: "userEmail",
    header: "Email",
    cell: ({ cell }) => {
      const email = cell.getValue();
      if (!email) {
        return "-";
      }

      return (
        <Link
          to={`${DashboardPathnames.users}?search=${email}`}
          className="font-semibold hover:underline"
        >
          {email}
        </Link>
      );
    },
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("accountId", {
    id: "accountId",
    header: "Account",
    cell: ({ cell }) => (
      <CompanyDetailComponent
        id={cell.row.original.accountId}
        company={cell.row.original.company}
      />
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("status", {
    id: "orderStatus",
    header: "Status",
    cell: ({ cell }) => <OrderStatusComponent status={cell.getValue()} />,
    sortingFn: sortingFunctionForOrderStatus,
    filterFn: columnListFilter,
  }),
];

const DefaultData: Order[] = [];

/**
 *  Display the list of orders for admin
 */
export const AdminOrdersTable = () => {
  const { urlSearchParams } = useSearchParams();

  const { data } = useGetAllOrders();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "orderStatus", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // Expand and Scroll to the row if orderId is set in search params
  useEffect(() => {
    const search = urlSearchParams.get("search");
    if (search) return;

    const orderId = urlSearchParams.get("orderId");
    if (!data || data.data.length === 0 || !orderId) return;

    const orderIndex = data.data.findIndex((order) => order.id === orderId);
    if (orderIndex < 0) return;

    try {
      const row = table.getRow(orderIndex.toString());
      if (row && row.getCanExpand() && !row.getIsExpanded()) {
        table.resetExpanded();
        row.toggleExpanded();

        const element = document.getElementById(`${orderIndex}-order-row`);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    } catch (_) {
      urlSearchParams.delete("orderId");
    }
  }, [urlSearchParams, data]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      {
        id: "expander",
        cell: ({ row }: { row: Row<Order> }) => <RowExpandIcon row={row} />,
      },
    ],
    [data?.data],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,
    filterFns: { fuzzy: fuzzyFilter },

    state: {
      sorting,
      globalFilter: urlSearchParams.get("search") || "",
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: (value) => urlSearchParams.set("search", value),
    onColumnFiltersChange: setColumnFilters,

    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const getRowData = (cell: Cell<Order, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const getRowActions = (row: Row<Order>, isSubComponent = false) => {
    return {
      onClick: () => {
        if (isSubComponent || !row.getCanExpand()) {
          return;
        }
        if (!row.getIsExpanded()) {
          table.resetExpanded();
        }
        row.toggleExpanded();
      },
    };
  };

  return (
    <div className="px-4 pt-3 mx-auto sm:px-6 lg:px-8">
      <div className="relative overflow-x-auto rounded-lg shadow-md">
        <table className="w-full text-sm text-left">
          {/* Table column names */}
          <thead className="border select-none whitespace-nowrap">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} scope="col" className="p-3 group">
                    {header.isPlaceholder && <></>}
                    {!header.isPlaceholder && (
                      <div
                        className={classNames("flex items-center w-fit", {
                          "cursor-pointer select-none mr-2.5":
                            header.column.getCanSort(),
                          "text-brand": header.column.getIsSorted(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <ColumnHeaderIcon column={header.column} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Table data */}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.original.id}>
                <tr
                  id={`${row.id}-order-row`}
                  data-testid={`${row.original.id}_ORDER_ROW`}
                  className={classNames("border group hover:cursor-pointer", {
                    "bg-white hover:bg-background":
                      row.original.status !== "finished" &&
                      !row.getIsExpanded(),
                    "bg-background": row.getIsExpanded(),
                    "text-secondaryText bg-uiBorder":
                      row.original.status === "finished",
                  })}
                  {...getRowActions(row)}
                >
                  {row.getVisibleCells().map((cell) => getRowData(cell))}
                </tr>
                {/* Table Sub Component */}
                {row.getIsExpanded() && (
                  <tr
                    className={classNames("border", {
                      "bg-white hover:bg-background":
                        row.original.status !== "finished" &&
                        !row.getIsExpanded(),
                      "bg-background": row.getIsExpanded(),
                      "bg-uiBorder": row.original.status === "finished",
                    })}
                    {...getRowActions(row, true)}
                  >
                    <td colSpan={row.getVisibleCells().length} className="p-0">
                      <OrderInfoComponent isAdmin={true} order={row.original} />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
