import { camelCaseToSnakeCase } from "../../utils/textFormatter";

type DisabledInputProps = {
  id?: string;
  value?: string;
  required?: boolean;
  isValueLarge?: boolean;
};

/**
 *  Shows the styled information without the ability to enter or change the value
 */
export const DisabledInput = ({
  id,
  value,
  required,
  isValueLarge = false,
}: DisabledInputProps) => {
  const props = {
    id,
    required,
    value,
    disabled: true,
    "data-testid": !!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined,
  };

  if (isValueLarge) {
    return (
      <textarea
        className="block w-full py-2 text-base font-semibold bg-transparent h-9 min-h-[36px]"
        {...props}
      />
    );
  }

  return (
    <input
      className="block w-full py-2 text-base font-semibold bg-transparent"
      type="text"
      {...props}
    />
  );
};
