import classNames from "classnames";
import { Dialog } from "@headlessui/react";

import { AssignIPsModalContent } from "./AssignIpsModalContent";
import { EditInstanceModalContent } from "./EditInstanceModalContent";
import { DeleteInstanceModalContent } from "./DeleteInstanceModalContent";

import { Instance } from "../../types/instance";

interface InstancesModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalName: string | null;
  instance: Instance;
}

/**
 * The Modal shows Modal and Content related to Admin Instance Actions ie.:
 * - Delete Instance
 * - Update Instance
 * - Extend Instance
 */
export const InstancesModal = ({
  isOpen,
  onClose,
  modalName,
  instance,
}: InstancesModalProps) => {
  return (
    <Dialog
      id={`${modalName}-modal`}
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div
          className={classNames(
            "inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl",
            {
              "max-w-md": modalName !== "delete-instance",
              "max-w-lg": modalName === "delete-instance",
            },
          )}
        >
          {modalName === "delete-instance" && (
            <DeleteInstanceModalContent onClose={onClose} instance={instance} />
          )}
          {modalName === "update-instance" && (
            <EditInstanceModalContent onClose={onClose} instance={instance} />
          )}
          {modalName === "assign-ips" && (
            <AssignIPsModalContent onClose={onClose} instance={instance} />
          )}
        </div>
      </div>
    </Dialog>
  );
};
