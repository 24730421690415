/**
 * This function handles copying to clipboard
 * Also, if clipboard API does not exist and as a backup it relies on document.execCommand('copy')
 */
export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
