import { Button } from "../LegacyButton";
import { CrossIcon } from "../icons/CrossIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

// Function to handle file upload
const handleFileUpload = (
  event: React.ChangeEvent<HTMLInputElement>,
  setCSVText: (text: string) => void,
) => {
  const file = event.target.files?.[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (e) => {
    if (!e.target) return;

    // Read the content of the uploaded file
    const content = e.target.result as string;
    // Parse CSV content and format it as JSON
    const formattedData = parseCSV(content);
    // Update the state with the formatted JSON
    setCSVText(JSON.stringify(formattedData, null, 2));
  };

  // Read the uploaded file as text
  reader.readAsText(file);
};

// Function to parse CSV content and format it as JSON
const parseCSV = (csvContent: string): Record<string, string[]> => {
  // Split CSV content into lines
  const lines = csvContent.split("\n");

  // Object to hold formatted data
  const data: Record<string, string[]> = {};
  let headers: string[] | undefined;

  lines.forEach((line, index) => {
    const values = line.split(",");
    if (index === 0) {
      // First line contains headers
      headers = values.map((header) => header.trim());
      headers.forEach((header) => (data[header] = []));
    }
    if (
      index !== 0 &&
      line &&
      headers &&
      values &&
      headers.length !== 0 &&
      values.length !== 0
    ) {
      // Push values under their respective headers
      headers.forEach((header, index) => {
        data[header].push(values[index].trim());
      });
    }
  });

  return data;
};

type CSVInputProps = {
  id?: string;
  value?: string;
  required?: boolean;
  onChange: (value: string) => void;
};

/**
 *  Shows the styled information without the ability to upload a csv
 */
export const CSVInput = ({ id, value, required, onChange }: CSVInputProps) => {
  return (
    <>
      {!!value && (
        <div className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer">
          <Button type="button" onClick={() => onChange("")}>
            <CrossIcon className="text-name" />
          </Button>
        </div>
      )}
      <input
        className="block w-full p-2 text-sm font-medium truncate bg-transparent bg-white border rounded-md border-uiBorder text-secondaryText"
        type="file"
        accept=".csv"
        id={id}
        required={required}
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        onChange={(e) => handleFileUpload(e, onChange)}
      />
    </>
  );
};
