import { Fragment, useState } from "react";

import moment from "moment";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { AddSshKeyModal } from "./AddSshKeyModal";
import { EditSshKeyModal } from "./EditSshKeyModal";
import { DeleteSshKeyModal } from "./DeleteSshKeyModal";

import { useGetSshKeys } from "../../state/sshKey";
import { useGetInstances } from "../../state/instance";

import { GenericInputs } from "../forms/GenericInputs";

import { SSHKey } from "../../types/sshKey";
import { InputKeyToInputType } from "../forms/types";

const noop = () => {
  // do nothing
};

const EmptySshKeyPanel = () => {
  const [showModal, setShowModal] = useState<string | null>(null);

  return (
    <div className="p-4 pt-2 mt-3 bg-white border rounded-lg shadow-md">
      <span className="text-sm font-medium leading-loose  min-h-[50px] flex items-center">
        There are no SSH keys associated with your account.
      </span>
      <div className="flex items-center justify-end gap-6 pt-4 border-t border-uiBorder">
        <Button
          data-testid="NEW_SSH_KEY_BUTTON"
          variant="dark"
          variantClassName="flex-1 max-w-[140px]"
          onClick={() => setShowModal("add-ssh-key")}
        >
          New SSH Key
        </Button>
      </div>
      <AddSshKeyModal
        isOpen={showModal === "add-ssh-key"}
        onClose={() => setShowModal(null)}
      />
    </div>
  );
};

const keyToInput: InputKeyToInputType = {
  sshTitle: "disabled",
  sshKey: "disabled",
  sshCreated: "disabled",
};

const SshKeyPanel = ({
  sshKey,
  accountId,
}: {
  sshKey: SSHKey;
  accountId: string;
}) => {
  const { data: { data: instances } = { data: [] }, isLoading } =
    useGetInstances(accountId);

  const originalState = {
    sshTitle: sshKey.name ?? "",
    sshKey: sshKey.publicKey ?? "",
    sshCreated: sshKey.createdAt ? moment(sshKey.createdAt).format("LL") : "",
  };

  const [showModal, setShowModal] = useState<string | null>(null);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <form className="p-4 pt-2 mt-3 bg-white border rounded-lg shadow-md">
        <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
          <div className="grid grid-cols-1 gap-4">
            <GenericInputs
              state={originalState}
              originalState={originalState}
              inputs={keyToInput}
              errors={{}}
              onChange={noop}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 pt-4 border-t border-uiBorder">
          <Button
            data-testid="DELETE_SSH_KEY_MODAL_BUTTON"
            type="button"
            variant="red"
            textVariant={true}
            disabled={instances.length !== 0}
            variantClassName="min-w-[160px]"
            onClick={() => setShowModal("delete-ssh-key")}
          >
            Delete SSH Key
          </Button>
          <Button
            data-testid="UPDATE_SSH_KEY_MODAL_BUTTON"
            type="button"
            variant="dark"
            variantClassName="min-w-[160px]"
            onClick={() => setShowModal("update-ssh-key")}
          >
            Edit SSH Key
          </Button>
        </div>
      </form>
      <EditSshKeyModal
        isOpen={showModal === "update-ssh-key"}
        onClose={() => setShowModal(null)}
        sshKey={sshKey}
      />
      <DeleteSshKeyModal
        isOpen={showModal === "delete-ssh-key"}
        onClose={() => setShowModal(null)}
        keyId={sshKey.id}
      />
    </>
  );
};

/*
 * A component rendered on the profile page (ProfileInfo > SshKeySection) that displays the existing
 * SSH key(s) associated with a user and has a button that opens a modal and facilitates the deletion
 * of keys.
 */
export const SshKeysPanel = ({ accountId }: { accountId: string }) => {
  const { data: { data: keys } = { data: [] }, isLoading } =
    useGetSshKeys(accountId);
  if (isLoading) {
    return <Loading />;
  }

  if (!keys || keys.length === 0) {
    return <EmptySshKeyPanel />;
  }

  return (
    <>
      {keys.map((key, index) => (
        <Fragment key={index}>
          <SshKeyPanel sshKey={key} accountId={accountId} />
        </Fragment>
      ))}
    </>
  );
};
