import classNames from "classnames";
import { UserType } from "../../types/user";

const statusToLabel = {
  admin: "Admin",
  blacklisted: "Blacklisted",
  regular: "Regular",
  trusted: "Trusted",
};

const statusToStyles = {
  admin: "bg-white",
  blacklisted:
    "text-negative bg-negative bg-opacity-10 border-negative border-opacity-30",
  regular: "bg-white",
  trusted: "bg-white",
};

const statusToDotStyles = {
  admin: "bg-brand",
  blacklisted: "bg-negative",
  regular: "bg-tertiaryText",
  trusted: "bg-positive",
};

/**
 * This component handles how status is shown on the User Table
 */
export const UserStatusComponent = ({ status }: { status: UserType }) => {
  return (
    <div className="flex">
      <span
        className={classNames(
          "flex items-center px-2 py-0.5 text-sm text-center border rounded-lg truncate",
          statusToStyles[status],
        )}
      >
        <div
          className={classNames(
            "w-1.5 h-1.5 mr-2 rounded-full",
            statusToDotStyles[status],
          )}
        />
        {statusToLabel[status]}
      </span>
    </div>
  );
};
