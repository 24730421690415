import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { AuthUserProvider } from "./AuthUserProvider";
import { useHistory } from "react-router-dom";

/**
 * Auth0 & User manager
 *
 * Establishes the credentials needed for login with Auth0, get the
 * access token and makes sure that it is refreshed when necessary.
 * Provides the token for all the children to be used when they need
 * to make a call to the server and authenticate the request.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  if (!domain || !clientId || !audience) {
    throw new Error(
      "Missing Auth0 env settings\n" +
        `REACT_APP_AUTH0_DOMAIN=${process.env.REACT_APP_AUTH0_DOMAIN}\n` +
        `REACT_APP_AUTH0_CLIENT_ID=${process.env.REACT_APP_AUTH0_CLIENT_ID}\n` +
        `REACT_APP_AUTH0_AUDIENCE=${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    );
  }

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin + "/redirect-after-login",
        audience,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
    >
      <AuthUserProvider>{children}</AuthUserProvider>
    </Auth0Provider>
  );
};
