import {
  Row,
  Updater,
  SortingState,
  functionalUpdate,
} from "@tanstack/react-table";

import { isFulfillingOrder } from "./order";

import { Instance } from "../types/instance";
import { Order } from "../types/order";

const orderStatusToPriority: Record<string, number> = {
  not_ready: 0,
  eligible: 1,
  allocating: 2,
  winning: 3,
  active: 4,
  paused: 5,
  closing: 6,
  large: 7,
  finished: 8,
  deleted: 9,
};

export function sortingFunctionForOrderStatus(
  rowA: Row<Order>,
  rowB: Row<Order>,
) {
  const rowAPriority = orderStatusToPriority[rowA.original.status];
  const rowBPriority = orderStatusToPriority[rowB.original.status];
  if (rowAPriority !== rowBPriority) {
    return rowAPriority - rowBPriority;
  }

  const { startAt: rowAStartAt } = rowA.original;
  const { startAt: rowBStartAt } = rowB.original;
  if (
    rowAStartAt &&
    rowBStartAt &&
    rowAStartAt.getTime() !== rowBStartAt.getTime()
  ) {
    if (isFulfillingOrder(rowA.original) && isFulfillingOrder(rowB.original)) {
      return rowAStartAt.getTime() - rowBStartAt.getTime();
    }
    return rowBStartAt.getTime() - rowAStartAt.getTime();
  }
  return 0;
}

export function sortingFunctionForInstanceStatus(
  rowA: Row<Instance>,
  rowB: Row<Instance>,
) {
  const rowAPriority = orderStatusToPriority[rowA.original.status];
  const rowBPriority = orderStatusToPriority[rowB.original.status];
  if (rowAPriority !== rowBPriority) {
    return rowAPriority - rowBPriority;
  }

  const { startAt: rowAStartAt } = rowA.original;
  const { startAt: rowBStartAt } = rowB.original;
  if (
    rowAStartAt &&
    rowBStartAt &&
    rowAStartAt.getTime() !== rowBStartAt.getTime()
  ) {
    if (isFulfillingOrder(rowA.original) && isFulfillingOrder(rowB.original)) {
      return rowAStartAt.getTime() - rowBStartAt.getTime();
    }
    return rowBStartAt.getTime() - rowAStartAt.getTime();
  }
  return 0;
}

export function sortingFunctionForOrderStartDate(
  rowA: Row<Order>,
  rowB: Row<Order>,
) {
  // Helper function to get the time value for the active order's start date(if present), else get 1e100
  const orderStartAtTime = (order: Order) =>
    isFulfillingOrder(order) && order.startAt ? order.startAt.getTime() : 1e100;

  // Helper function to extract relevant values for comparison from an order for sorting
  // Sort by (1) start time, then (2) priority
  const orderSortValues = (order: Order) => [
    orderStartAtTime(order),
    orderStatusToPriority[order.status],
  ];

  // Extract sort values for comparison from the orders
  const rowASortValues = orderSortValues(rowA.original);
  const rowBSortValues = orderSortValues(rowB.original);

  // Compare each value and return the result if any differs
  for (let i = 0; i < rowASortValues.length; i++) {
    if (rowASortValues[i] != rowBSortValues[i]) {
      return rowASortValues[i] - rowBSortValues[i];
    }
  }

  // All values are equal, return 0
  return 0;
}

const detailedOrderStatusToPriority: Record<string, number> = {
  scheduled: 0,
  paid: 1,
  fulfilling: 2,
  completed: 3,
  canceled: 4,
  unpaid: 5,
};

export function sortingFunctionForDetailedOrderStatus(
  rowA: Row<Order>,
  rowB: Row<Order>,
) {
  const rowAPriority = detailedOrderStatusToPriority[rowA.original.status];
  const rowBPriority = detailedOrderStatusToPriority[rowB.original.status];

  return rowAPriority - rowBPriority;
}

/**
 * This function is the heart of sorting for tables:
 * - handles updating the sort state based on selected header
 * - preserves certain sort header if mentioned
 * - handles updating the sort header as well
 */
export function onCustomSortingChange(
  newSortingStateUpdater: Updater<SortingState>,
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>,
  preservedSortingState?: SortingState,
) {
  const newSortingState = functionalUpdate(
    newSortingStateUpdater,
    preservedSortingState ?? [],
  );
  const filteredPreservedSortingState = (preservedSortingState ?? []).filter(
    (previousState) =>
      newSortingState.find((newState) => previousState.id !== newState.id),
  );
  setSorting((currentSortingState) => {
    const newPreservedSortingState = filteredPreservedSortingState.map(
      (previousState) => {
        const currentState = currentSortingState.find(
          (currentState) => currentState.id === previousState.id,
        );
        return currentState ?? previousState;
      },
    );
    return [...newPreservedSortingState, ...newSortingState];
  });
}
