import React, { useState } from "react";
import classNames from "classnames";

import { CaretIcon } from "./CaretIcon";

type AccordionProps = {
  title: string;
  openTitle?: string;
  className?: string;
  children?: React.ReactNode;
};

export const Accordion = ({
  title,
  openTitle,
  className,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = (e: React.ChangeEvent<HTMLDetailsElement>) => {
    setIsOpen(e.target.open);
  };

  const accordionClassNames = classNames(
    className,
    "[&_svg]:open:rotate-90 [&_svg]:open:opacity-70",
  );

  const hasOpenTitle = typeof openTitle === "string";
  const displayTitle = hasOpenTitle && isOpen ? openTitle : title;

  return (
    <details className={accordionClassNames} onToggle={onToggle}>
      <summary className="cursor-pointer list-none [&::-webkit-details-marker]:hidden">
        <p className="font-neo-grotesque text-black text-[0.8125rem]">
          {displayTitle}
          <CaretIcon
            className="inline-block ml-0.5"
            width="1rem"
            height="1rem"
          />
        </p>
      </summary>
      {children}
    </details>
  );
};
