import { AccountBalanceHistoriesTable } from "../account/AccountBalanceHistoriesTable";

/**
 * This component shows the full detail of the payments history
 */
export const PaymentsContainer = ({ accountId }: { accountId: string }) => {
  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col p-4 pt-0">
          <div>
            <h1 className="pb-1 text-xl font-semibold leading-7">
              Payment History
            </h1>
            <span className="text-base font-normal leading-tight text-secondaryText">
              View your payment histories here.
            </span>
          </div>
          <AccountBalanceHistoriesTable accountId={accountId} />
        </div>
      </div>
    </div>
  );
};
