import { useEffect, useState } from "react";

import moment from "moment";
import classNames from "classnames";
import RDatePicker from "react-date-picker";

import { useWindowResize } from "../../hooks/useWindowResize";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { CrossIcon } from "../icons/CrossIcon";

import "../../styles/calendar.css";
import "../../styles/datepicker.css";

export const getInvalidDateMessage = (
  value: Date,
  isPastDateAccepted = false,
) => {
  const dateMoment = moment(value);

  if (!dateMoment.isValid()) {
    return "Please enter a valid date";
  }

  if (
    !isPastDateAccepted &&
    dateMoment.isBefore(moment().startOf("day"), "day")
  ) {
    return "Past Date is not supported";
  }

  return "";
};

type DatePickerProps = {
  id?: string;
  value?: Date;
  required?: boolean;
  originalValue?: Date;
  isError?: boolean;
  onChange: (value: Date) => void;
};

/**
 *  Allows user to select Date from Calender Popover
 *  Note: Is used as portal to make sure calender is always on top
 */
export const DatePicker = ({
  id = "",
  value,
  required,
  originalValue,
  isError,
  onChange,
}: DatePickerProps) => {
  const { windowSize } = useWindowResize();

  const [activeStartDate, setActiveStartDate] = useState(value);
  const [isClearIconVisible, setIsClearIconVisible] = useState(false);

  const calendarPortal = document.getElementById("calendar-portal");

  function onSetPortalPosition() {
    if (!calendarPortal) return;

    const datePicker = document.getElementById(id);
    if (!datePicker) return;

    const rect = datePicker.getBoundingClientRect();

    calendarPortal.style.zIndex = "50";
    calendarPortal.style.position = "fixed";

    calendarPortal.style.left = `${rect.x}px`;
    calendarPortal.style.top = `${rect.y + datePicker.offsetHeight}px`;
  }

  function onResetPortalPosition() {
    if (!calendarPortal) return;

    calendarPortal.style.top = "unset";
    calendarPortal.style.left = "unset";
  }

  useEffect(() => {
    if (windowSize.width) onSetPortalPosition();
  }, [JSON.stringify(windowSize)]);

  function onValueChange(value: any) {
    const newMoment = moment(value instanceof Date ? value : originalValue);
    if (newMoment.isValid()) {
      setIsClearIconVisible(!!value);
      setActiveStartDate(newMoment.toDate());
      onChange(newMoment.toDate());
    }
  }

  return (
    <RDatePicker
      className={classNames({
        "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
          !isError,
        "border-negative focus:ring-negative focus:ring-offset-negative":
          !!isError,
      })}
      id={id}
      data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
      required={required}
      portalContainer={calendarPortal}
      clearIcon={
        isClearIconVisible ? <CrossIcon className="text-name" /> : <></>
      }
      value={value}
      onChange={(newValue) => onValueChange(newValue)}
      activeStartDate={activeStartDate}
      onActiveStartDateChange={({ activeStartDate }) =>
        setActiveStartDate(activeStartDate ?? undefined)
      }
      onFocus={() => {
        value && onChange(value);
        onSetPortalPosition();
      }}
      onCalendarOpen={() => onSetPortalPosition()}
      onCalendarClose={() => onResetPortalPosition()}
      maxDate={new Date(9999, 11, 31)} // December 31, 9999
    />
  );
};
