/**
 * This function will always Intl Number format based on the amount passed.
 * Note that the amount has to be valid
 * PS. NumberFormatOptions are accepted as needed
 */
export function formatMoneyString(
  amount?: number | string,
  options?: Omit<Intl.NumberFormatOptions, "style" | "currency">,
) {
  if (typeof amount === "string") {
    amount = +amount;
  }
  if (typeof amount !== "number") {
    return "";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(Math.abs(amount));
}

export function formatMoneyStringWithSign(
  amount: number | string,
  additionalProps?: { showPositiveSign: boolean },
) {
  const formattedString = formatMoneyString(amount);

  if (+amount < 0) return `-${formattedString}`;
  if (+amount > 0 && !!additionalProps?.showPositiveSign)
    return `+${formattedString}`;

  return formattedString;
}
