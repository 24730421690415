import { useEffect } from "react";

import { Dialog } from "@headlessui/react";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { DeleteIcon } from "../icons/DeleteIcon";

import { useDeleteSshKey } from "../../state/sshKey";

interface DeleteSshKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  keyId: string;
}

/**
 * This modal takes the confirmation to take the key id
 * and send the request through rect-query to delete it
 */
export const DeleteSshKeyModal = ({
  isOpen,
  onClose,
  keyId,
}: DeleteSshKeyModalProps) => {
  const { deleteSshKey, isSuccess } = useDeleteSshKey();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Dialog
      id="delete-ssh-key-modal"
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          <div className="flex items-center justify-between">
            <div className="p-3 mb-1 rounded-full bg-negative bg-opacity-20 w-fit">
              <DeleteIcon className="text-negative" />
            </div>
            <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
              <CloseIcon className="text-name hover:scale-110" />
            </Button>
          </div>
          <h1
            data-testid="DELETE_SSH_KEY_MODAL_HEADER"
            className="text-xl font-semibold leading-10 tracking-wide"
          >
            Delete SSH Key
          </h1>
          <span className="font-normal leading-tight text-secondaryText">
            Are you sure you want to delete this SSH key?
          </span>
          <div className="pt-4 pb-8 text-secondaryText">
            <span>
              This action cannot be undone.{" "}
              <span className="font-semibold underline">
                This will permanently delete this SSH key.
              </span>
            </span>
          </div>
          <div className="flex items-center justify-center gap-4 pb-1">
            <Button
              data-testid="CANCEL_BUTTON"
              type="button"
              variant="transparent"
              variantClassName="w-1/2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="DELETE_SSH_KEY_BUTTON"
              type="button"
              variant="red"
              variantClassName="w-1/2"
              onClick={() => {
                deleteSshKey({ id: keyId });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
