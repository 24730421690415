import { useEffect, useState } from "react";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { DownloadIcon } from "../icons/DownloadIcon";

import {
  useAssignInstanceIPs,
  useGetInstanceMachines,
} from "../../state/instance";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import { Instance } from "../../types/instance";
import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";

const keyToInput: InputKeyToInputType = {
  csvIpAddresses: "csv",
  ipAddresses: "description",
};

const requiredKey: RequiredInputKeyType = {
  ipAddresses: true,
};

const originalState = {
  csvIpAddresses: "",
  ipAddresses: "",
};

interface AssignIPsModalContentProps {
  onClose: () => void;
  instance: Instance;
}

/**
 * This component shows content related to assigning ips to a instance
 * Giving you title and buttons for action
 * And Inputs to accept ipAddresses
 */
export const AssignIPsModalContent = ({
  onClose,
  instance,
}: AssignIPsModalContentProps) => {
  const { data: { data: machines } = { data: [] } } = useGetInstanceMachines(
    instance.id,
  );
  const { assignIPs, isSuccess } = useAssignInstanceIPs();

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  useEffect(() => {
    if (machines && machines.length !== 0) {
      const ipString = machines.map((machine) => machine.publicIP).toString();
      setState({ ...state, ipAddresses: ipString });
    }
  }, [machines]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    if (!!newState.csvIpAddresses) {
      try {
        const parsedCSV = JSON.parse(newState.csvIpAddresses);
        const ipKey = Object.keys(parsedCSV).find((key) =>
          key.toLowerCase().includes("ip"),
        );
        if (!ipKey) {
          newErrors.csvIpAddresses =
            "Please upload a csv which has 'IP' in header!";
          setErrors(newErrors);
          setState(newState);
          return;
        }

        const csvIpAddresses = parsedCSV[ipKey].filter((ip: string) => !!ip);
        if (csvIpAddresses.length === 0) {
          newErrors.csvIpAddresses = `Please upload a csv file which has atleast one ip address for Header :${ipKey}!`;
          setErrors(newErrors);
          setState(newState);
          return;
        }

        const stateIpAddresses = !newState.ipAddresses
          ? []
          : newState.ipAddresses.split(",");
        newState.ipAddresses = [
          ...new Set([...stateIpAddresses, ...csvIpAddresses]),
        ].toString();
      } catch (e) {
        newErrors.csvIpAddresses = "Please enter a valid csv file!";
      }
    }
    if (!newState.csvIpAddresses) {
      delete newErrors.csvIpAddresses;
    }

    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    assignIPs({
      id: instance.id,
      ips: state.ipAddresses.split(","),
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="p-3 rounded-full bg-brand bg-opacity-20 w-fit">
          <DownloadIcon className="rotate-180 text-brand" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>

      <h1
        data-testid="EDIT_INSTANCE_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Assign IPs
      </h1>

      <span className="font-normal leading-tight text-secondaryText">
        {`Order ID: ${instance.id}`}
      </span>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col pt-2 pb-6 tracking-tight text-left ">
          <div className="grid grid-cols-1 gap-4">
            <GenericInputs
              state={state}
              originalState={originalState}
              inputs={keyToInput}
              required={requiredKey}
              errors={errors}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-center gap-4 pb-1">
          <Button
            data-testid="CANCEL_BUTTON"
            type="button"
            variant="transparent"
            variantClassName="w-1/2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="ASSIGN_IPS_BUTTON"
            type="submit"
            variant="dark"
            variantClassName="w-1/2"
            disabled={!isSubmitButtonEnabled}
          >
            Assign IPs
          </Button>
        </div>
      </form>
    </>
  );
};
