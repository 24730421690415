import classNames from "classnames";

import { Message } from "./Message";
import { TooltipButton } from "../tooltip/TooltipButton";

type InputWrapperProps = {
  id: string;
  error?: string | null;
  label?: string;
  required?: boolean;
  labelType?: "row" | "column";
  children: React.ReactNode;
};

/**
 * The Wrapper around all the custom inputs
 * It not only shows the respective label, but also handles displaying error message
 */
export const InputWrapper = ({
  id,
  error = "",
  label = "",
  required,
  children,
  labelType = "row",
}: InputWrapperProps) => (
  <div
    className={classNames("w-full", {
      "lg:grid lg:grid-cols-3 lg:space-x-6": labelType === "column",
      "col-start-1 col-end-3": id === "description" || id === "orderStartBy",
    })}
    key={id}
  >
    {label && (
      <label
        htmlFor={id}
        className={classNames(
          "flex h-fit text-sm leading-loose tracking-tight group whitespace-nowrap text-ellipsis",
          {
            "lg:mt-1.5": labelType === "column",
            "lg:mb-7": labelType === "column" && !!error,
          },
        )}
      >
        {`${label}${required ? " *" : ""}`}
        <TooltipButton id={id} className="ml-1.5" />
      </label>
    )}
    <div className="col-span-2">
      <div className="relative flex items-center">{children}</div>
      <Message id={id} message={error} />
    </div>
  </div>
);
