import { Connectivity, ConnectivityOption } from "./constants";
import { isCountValidFor } from "./utils";
import { useSearchParams } from "../../hooks/useSearchParams";

const paramKeys = {
  connectivity: "network",
  gpuCount: "gpus",
};

const connectivityValues = {
  infiniband: "inf",
  ethernet: "eth",
};

const paramsToConnectivity: { [key: string]: ConnectivityOption } = {
  [connectivityValues.infiniband]: Connectivity.INFINIBAND,
  [connectivityValues.ethernet]: Connectivity.ETHERNET,
};

const paramsFromConnectivity = (connectivity: ConnectivityOption) => {
  for (const param in paramsToConnectivity) {
    if (paramsToConnectivity[param] === connectivity) {
      return param;
    }
  }
};

export const useDemandOrderParams = () => {
  const { urlSearchParams } = useSearchParams();

  const connectivityParam = urlSearchParams.get(paramKeys.connectivity);
  const gpuParam = urlSearchParams.get(paramKeys.gpuCount);

  const isParamsValid = Boolean(connectivityParam && gpuParam);

  const isConnectivityValid =
    isParamsValid &&
    (connectivityParam === connectivityValues.infiniband ||
      connectivityParam === connectivityValues.ethernet);

  const connectivity =
    isConnectivityValid && paramsToConnectivity[connectivityParam];

  const isGPUvalid =
    isConnectivityValid &&
    Boolean(Number(gpuParam)) &&
    isCountValidFor(Number(gpuParam), connectivity as ConnectivityOption);

  const gpus = isGPUvalid && Number(gpuParam);

  const fromParams = { connectivity, gpus };

  const toParams = (gpuCount: number, connectivityOption: ConnectivityOption) =>
    `${paramKeys.gpuCount}=${gpuCount}&
    ${paramKeys.connectivity}=${paramsFromConnectivity(
      connectivityOption,
    )}`.replace(/\s/g, "");

  return { fromParams, toParams, deleteParams: urlSearchParams.deleteAll };
};
