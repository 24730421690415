import classNames from "classnames";

import { SEO } from "./SEO";

type HeaderProps = {
  title: string;
  description?: string;
  image?: string;
  className?: string;
  secondaryComponent?: React.ReactNode;
};

/**
 * This component shows a header after the top navbar and the beginning of a page
 * The component is not visible by default and is supposed to be imported as per need
 * Allows you to pass a CTA Button on the page as well
 */
export const Header = ({
  title,
  description,
  image,
  className = "",
  secondaryComponent,
}: HeaderProps) => {
  return (
    <>
      <SEO
        title={`${title} - VP Exchange`}
        description={description}
        image={image}
      />
      <div
        className={classNames(className, "flex flex-col p-4 sm:p-6 lg:p-8", {
          "pb-0 sm:pb-1 lg:pb-2": !!description,
          "justify-center text-center min-h-[250px] items-center bg-center bg-cover":
            !!image,
        })}
        // NOTE: As of 2nd Feb 2024, we hide image in header per Jed's request
        // style={{
        //   backgroundImage: !!image
        //     ? `linear-gradient(rgba(252, 138, 0, 0.60),rgba(252, 138, 0, 0.30)),url(${image})`
        //     : undefined,
        // }}
      >
        <div
          className={classNames({
            "p-8 space-y-3": !!secondaryComponent && !!image,
            "sm:flex space-y-3 justify-between": !!secondaryComponent && !image,
          })}
        >
          <div>
            <h1
              className={classNames("pb-1 font-semibold leading-10", {
                "text-3xl": !image,
                "text-5xl": !!image,
              })}
            >
              {title}
            </h1>
            {description && (
              <span
                className={classNames("font-normal text-tertiaryText", {
                  "text-base leading-normal": !image,
                  "text-xl leading-10": !!image,
                })}
              >
                {description}
              </span>
            )}
          </div>
          {secondaryComponent && <>{secondaryComponent}</>}
        </div>
      </div>
    </>
  );
};
