import { getRandomNumber } from "./timeLengthFormatter";

import { OrderBook, OrderBookStatus } from "../types/order";

export const isRunningOrderBook = (orderBook: OrderBook) =>
  ["active", "paused", "closing"].includes(orderBook.status);

// Default OrderBook object mainly used for unallocated GPU row and dummy row
export const DefaultOrderBook: Omit<OrderBook, "status"> = {
  id: "",
  accountId: "",
  pricePerGPU: 0,
  numberOfGPUs: 0,
};

// this function handles filtering orderbooks based on passed status
// also, if no orders exist matching the status, then it randomly creates dummy orders with 0 numberOfGPUs
function filterOrderBooks(
  orderBooks: OrderBook[],
  statuses: OrderBookStatus[],
  isSkeletonActive = false,
) {
  const filteredOrderBooks = orderBooks.filter((orderBook) =>
    statuses.includes(orderBook.status),
  );

  // If the flag is truthy ie. isSkeletonActive is true
  // Add random no. of orders into the orderbook with 0 no. of GPUs
  if (isSkeletonActive && filteredOrderBooks.length === 0) {
    const randomNumberOfDummyOrderBooks = getRandomNumber(4, 8);
    for (let i = 0; i < randomNumberOfDummyOrderBooks; i++) {
      const randomStatus =
        statuses[getRandomNumber(0, statuses.length)] || statuses[0];
      filteredOrderBooks.push({
        ...DefaultOrderBook,
        status: randomStatus,
      });
    }
  }

  return filteredOrderBooks;
}

// this function handles filtering orderbook by status and sorting it respectively
// it also handles calculation of allocation and unallocated gpus based on passed total number of gpus
// it also format the price ie. sent in cent to dollars
export const getFilteredOrderBooks = ({
  orderBooks = [],
  accountId,
}: {
  orderBooks?: OrderBook[];
  accountId?: string;
}) => {
  const activeOrderBooks = orderBooks.filter((orderBook) =>
    isRunningOrderBook(orderBook),
  );
  const pendingOrderBooks = orderBooks.filter(
    (orderBook) => !isRunningOrderBook(orderBook),
  );

  const closingActiveOrderBooks = filterOrderBooks(activeOrderBooks, [
    "closing",
  ]);
  const runningActiveOrderBooks = filterOrderBooks(activeOrderBooks, [
    "active",
    "paused",
  ]);

  const allocatingPendingOrderBooks = filterOrderBooks(pendingOrderBooks, [
    "winning",
    "allocating",
  ]);
  const notRunningPendingOrderBooks = filterOrderBooks(pendingOrderBooks, [
    "eligible",
  ]);

  return {
    closingOrderBooks: closingActiveOrderBooks.sort((a, b) =>
      sortingFunctionForOrderBook(a, b, accountId),
    ),
    runningOrderBooks: runningActiveOrderBooks.sort((a, b) =>
      sortingFunctionForOrderBook(b, a, accountId),
    ),

    allocatingOrderBooks: allocatingPendingOrderBooks.sort((a, b) =>
      sortingFunctionForOrderBook(b, a, accountId),
    ),
    notRunningOrderBooks: notRunningPendingOrderBooks.sort((a, b) =>
      sortingFunctionForOrderBook(a, b, accountId),
    ),
  };
};

// this function handles sorting orderbook by accountId respectively
const sortingFunctionForOrderBook = (
  orderBookA: OrderBook,
  orderBookB: OrderBook,
  accountId?: string,
) => {
  if (accountId) {
    if (
      orderBookA.accountId === accountId &&
      orderBookB.accountId !== accountId
    ) {
      return -1; // orderBookA comes first
    }
    if (
      orderBookA.accountId !== accountId &&
      orderBookB.accountId === accountId
    ) {
      return 1; // orderBookB comes first
    }
  }

  // If accountId does not exist, or both have accountId === accountId, or neither do, compare normally
  return 0;
};
