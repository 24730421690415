import { useState } from "react";

import classNames from "classnames";
import { Dialog } from "@headlessui/react";

import { Button } from "../LegacyButton";
import { SendIcon } from "../icons/SendIcon";
import { CloseIcon } from "../icons/CloseIcon";

import { useGetUsers } from "../../state/user";
import { useCreateOrder } from "../../state/order";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";
import { useAuthentication } from "../auth/AuthUserProvider";

const keyToInput: InputKeyToInputType = {
  orderUserId: "searchable-text",
  numberOfGPUs: "gpu-number",
  maxPricePerGPU: "positive-currency",
  orderStartDate: "future-date",
  orderPriority: "select",
  markOrderAsPaid: "checkbox",
};

const requiredKey: RequiredInputKeyType = {
  orderUserId: true,
  numberOfGPUs: true,
  maxPricePerGPU: true,
  orderPriority: true,
};

const originalState = {
  orderUserId: "",
  numberOfGPUs: "",
  maxPricePerGPU: "",
  orderStartDate: new Date(),
  markOrderAsPaid: { isPaid: false },
  orderPriority: "0",
};

interface CreateOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * This modal shows the modal to create order details entered by the admin for a user
 */
export const CreateOrderModal = ({
  isOpen,
  onClose,
}: CreateOrderModalProps) => {
  const { data: { data: userData } = { data: [] } } = useGetUsers();
  const { accountId } = useAuthentication();
  const searchableUserOptions = userData.map((user) => {
    return {
      value: user.id.toString(),
      label: `${user.name} (${user.email})`,
    };
  });

  const { createOrder, isSuccess, data } = useCreateOrder();
  const isOrderIdValid = data && data.id != "";

  const keyToOptions = {
    orderUserId: searchableUserOptions,
    orderPriority: [
      { value: "0", label: "Normal" },
      { value: "1", label: "High" },
    ],
    markOrderAsPaid: [
      {
        value: "isPaid",
        label: "Mark as paid",
      },
    ],
  };

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createOrder({
      userId: state.orderUserId,
      startAt: new Date(state.orderStartDate),
      priority: +state.orderPriority,
      isPaid: !!state.markOrderAsPaid.isPaid,
      numberOfGPUs: +state.numberOfGPUs,
      maxPricePerGPU: +state.maxPricePerGPU,
      accountId: accountId!,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
    allowOriginalState: true,
  });

  return (
    <Dialog
      id="create-order-modal"
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          {isSuccess && (
            <div className="flex items-center justify-between">
              <div
                className={classNames("p-3 mb-2 rounded-full w-fit", {
                  "bg-green-100": isOrderIdValid,
                  "bg-red-100": !isOrderIdValid,
                })}
              >
                <SendIcon
                  className={classNames({
                    "text-positive": isOrderIdValid,
                    "text-negative": !isOrderIdValid,
                  })}
                />
              </div>
              <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
                <CloseIcon className="text-name hover:scale-110" />
              </Button>
            </div>
          )}
          <div className="flex items-center justify-between">
            <h1
              data-testid="CREATE_ORDER_MODAL_HEADER"
              className="text-xl font-semibold leading-10 tracking-wide"
            >
              {isSuccess && isOrderIdValid && "Thanks!"}
              {isSuccess && !isOrderIdValid && "Sorry!"}
              {!isSuccess && "Create Order"}
            </h1>
            {!isSuccess && (
              <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
                <CloseIcon className="text-name hover:scale-110" />
              </Button>
            )}
          </div>
          {!isSuccess && (
            <span className="font-normal leading-tight text-secondaryText">
              Please make sure the order information is correct.
            </span>
          )}
          {isSuccess && (
            <span
              data-testid="ORDER_SUCCESS_DESCRIPTION"
              className="font-normal leading-tight text-secondaryText"
            >
              {isOrderIdValid
                ? `Order#${data.id} has been placed!`
                : "Order could not be placed!"}
            </span>
          )}
          <form onSubmit={onSubmit}>
            {!isSuccess && (
              <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
                <div className="grid grid-cols-1 gap-4">
                  <GenericInputs
                    state={state}
                    inputs={keyToInput}
                    required={requiredKey}
                    options={keyToOptions}
                    errors={errors}
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
            <div className="flex items-center justify-center gap-4 py-2 pb-1">
              <Button
                data-testid="CANCEL_BUTTON"
                type="button"
                variant="transparent"
                variantClassName="w-1/2"
                onClick={onClose}
              >
                {isSuccess ? "Close" : "Cancel"}
              </Button>
              {!isSuccess && (
                <Button
                  data-testid="CREATE_ORDER_BUTTON"
                  type="submit"
                  variant="dark"
                  variantClassName="w-1/2"
                  disabled={!isSubmitButtonEnabled}
                >
                  Create
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
