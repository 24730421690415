export const H100SpecsTable = () => {
  return (
    <article className="pt-3">
      <table className="table-fixed w-full border-separate border-spacing-0">
        <tbody className=" [&>tr>th]:w-1/2 sm:[&>tr>th]:w-[30%] [&>tr>th]:border-l [&>tr>th]:border-t [&>tr>th]:border-[#CCC] [&>tr>th]:p-3 [&>tr>th]:align-bottom [&>tr>td]:border-l [&>tr>td]:border-t [&>tr>td]:border-r [&>tr>td]:border-[#CCC] [&>tr>td]:p-3 [&>tr>td]:align-bottom [&>tr:nth-of-type(even)]:bg-[#FAFAFA] [&>tr:last-of-type>th]:border-b [&>tr:last-of-type>td]:border-b [&>tr:first-of-type>th]:rounded-tl-lg [&>tr:first-of-type>td]:rounded-tr-lg [&>tr:last-of-type>th]:rounded-bl-lg [&>tr:last-of-type>td]:rounded-br-lg [&>tr>th]:text-left [&>tr>th]:text-base [&>tr>th]:font-bold [&>tr>td]:text-base [&>tr>td]:font-light sm:[&>tr>th]:text-sm sm:[&>tr>td]:text-sm">
          <tr>
            <th scope="row">
              <p>CPU Configuration</p>
            </th>
            <td>2x 52-core CPUs</td>
          </tr>
          <tr>
            <th scope="row">Ethernet</th>
            <td>100 Gbps</td>
          </tr>
          <tr>
            <th scope="row">Infiniband</th>
            <td>3200 Gbps</td>
          </tr>
          <tr>
            <th scope="row">Flexible Scale</th>
            <td>Over 4000 GPUs Available</td>
          </tr>
          <tr>
            <th scope="row">GPU Memory</th>
            <td>80GB</td>
          </tr>
          <tr>
            <th scope="row">CPUs</th>
            <td>Intel4th Gen Xeon Scalable 8462Y+CPUs (128vCPU)</td>
          </tr>
          <tr>
            <th scope="row">vCPU</th>
            <td>208</td>
          </tr>
          <tr>
            <th scope="row">Memory</th>
            <td>1 TB DDR5 ECC RAM</td>
          </tr>
          <tr>
            <th scope="row">Storage</th>
            <td>19.2TB NVMe PCIe</td>
          </tr>
        </tbody>
      </table>
    </article>
  );
};
