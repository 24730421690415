import { OrderBookContainer } from "./OrderBook";
import { TooltipButton } from "../tooltip/TooltipButton";

export const OrderbookPanel = () => {
  return (
    <div className="px-4 pt-4 mx-auto sm:px-6 lg:px-8">
      <div className="p-4 bg-white border rounded-lg shadow-md">
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div>
            <div className="flex pb-1 group">
              <h1 className="mr-2 text-xl font-semibold leading-7">
                GPU Order Book
              </h1>
              <TooltipButton id="currentPrices" />
            </div>
            <span className="flex flex-col space-y-2 leading-tight text-secondaryText">
              Current active and pending order for our inventory.
            </span>
          </div>
        </div>
        <OrderBookContainer />
      </div>
    </div>
  );
};
