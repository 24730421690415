import { Redirect } from "react-router-dom";

import { Header } from "../Components/Header";
import { Loading } from "../Components/Loading";
import { ActiveTabs } from "../Components/ActiveTabs";
import { OrdersTable } from "../Components/order/OrdersTable";

import { useAuthentication } from "../Components/auth/AuthUserProvider";
import { useRefresh } from "../hooks/useRefresh";

/**
 * This component shows the User's Orders Table
 */
export const Orders = () => {
  const { userInfo, isLoading, accountId } = useAuthentication();
  useRefresh();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo) {
    return (
      <div className="pb-4 bg-background min-h-page">
        <Header
          title="My Orders"
          description="See all your orders."
          secondaryComponent={
            <ActiveTabs
              primaryLabel="Active Orders"
              secondaryLabel="Past Orders"
            />
          }
        />
        <OrdersTable accountId={accountId!} />
      </div>
    );
  }

  return <Redirect to="/" />;
};
