import { useSearchParams } from "./useSearchParams";

const refreshKey = "refresh";
const refreshValue = "true";

export const refreshParam = `${refreshKey}=${refreshValue}`;

/**
 * This is a hacky workaround to the issues of maintaining sync with backend state
 * within this application. It can be used to force a refresh on a particular page,
 * triggered from another page.
 *
 * A proper solution will require reworking the entire state management system,
 * which at this time is a mess. This stop-gap is going to be used for the time
 * being to patch shoddy user experience while we build an application to replace
 * this one.
 */

export const useRefresh = () => {
  const { urlSearchParams } = useSearchParams();

  const refreshParamValue = urlSearchParams.get(refreshKey);

  if (refreshParamValue === refreshValue) {
    urlSearchParams.delete(refreshKey);
    location.reload();
  }

  if (refreshParamValue !== null) {
    urlSearchParams.delete(refreshKey);
  }
};
