import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";

import { queryClient } from "./queryClient";

import { getValidDate } from "../utils/timeLengthFormatter";

import { UserKey } from "./user";

import { User } from "../types/user";
import {
  SSHKey,
  AddSshKeyRequest,
  DeleteSshKeyRequest,
  UpdateSshKeyRequest,
} from "../types/sshKey";

enum SshKeyQueryKey {
  /** fetch a users ssh keys */
  SSH_KEY_LIST = "ssh-key-list",

  /** create an ssh key */
  SSH_KEY_ADD = "ssh-key-add",

  /** update an ssh key */
  SSH_KEY_UPDATE = "ssh-key-update",

  /** delete a users key */
  SSH_KEY_DELETE = "ssh-key-delete",
}

/** retrieve the list of ssh keys from the server */
const getSshKeys = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/sshkey?accountID=${accountId}`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const sshKeys: SSHKey[] | undefined = response.data?.keys?.map(
    (item: any) => {
      return {
        id: item.id,
        name: item.name,
        publicKey: item.publicKey,
        createdAt: getValidDate(item.created),
      };
    },
  );

  return { data: sshKeys || [] };
};

/** retrieve the list of ssh keys */
export const useGetSshKeys = (accountId: string) => {
  return useQuery<{ data: SSHKey[] }, Error>(
    [SshKeyQueryKey.SSH_KEY_LIST],
    () => getSshKeys(accountId),
  );
};

/** Send a add key request to the server */
const addSshKey = async (params: AddSshKeyRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/sshkey`;
  await axios.post(url, params);
  return params;
};

/** Add a ssh key */
export const useAddSshKey = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    AddSshKeyRequest,
    Error,
    AddSshKeyRequest
  >([SshKeyQueryKey.SSH_KEY_ADD], addSshKey);

  return { addSshKey: mutate, isError, isSuccess, isLoading };
};

/** Send a update key request to the server */
const updateSshKey = async (params: UpdateSshKeyRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/sshkey/${params.id}`;
  await axios.patch(url, params);
  return params;
};

/** Update a ssh key */
export const useUpdateSshKey = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateSshKeyRequest,
    Error,
    UpdateSshKeyRequest
  >([SshKeyQueryKey.SSH_KEY_UPDATE], updateSshKey);

  return { updateSshKey: mutate, isError, isSuccess, isLoading };
};

/** Send a delete key request to the server */
const deleteSshKey = async (params: DeleteSshKeyRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/sshkey/${params.id}`;
  await axios.delete(url);
  return params;
};

/** Delete a ssh key */
export const useDeleteSshKey = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    DeleteSshKeyRequest,
    Error,
    DeleteSshKeyRequest
  >([SshKeyQueryKey.SSH_KEY_DELETE], deleteSshKey);

  return { deleteSshKey: mutate, isError, isSuccess, isLoading };
};

/** Refetch ssh keys */
export const refetchSshKeys = (accountId: string) => {
  const userInfo: User | undefined = queryClient.getQueryData([
    UserKey.USER_INFO,
  ]);

  if (userInfo?.accountIds.includes(accountId)) {
    queryClient.invalidateQueries([SshKeyQueryKey.SSH_KEY_LIST]);
  }
};
