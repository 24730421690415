import { Dialog } from "@headlessui/react";

import { ChangeAccountBalanceModalContent } from "./ChangeAccountBalanceModalContent";

import { Account } from "../../types/account";

interface AccountsModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalName: string | null;
  account: Account;
}

/**
 * The Modal shows Modal and Content related to Admin Accounts Actions ie.:
 * - Edit a account's balance
 */
export const AccountsModal = ({
  isOpen,
  onClose,
  modalName,
  account,
}: AccountsModalProps) => {
  return (
    <Dialog
      id={`${modalName}-modal`}
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          {modalName === "change-balance" && (
            <ChangeAccountBalanceModalContent
              onClose={onClose}
              account={account}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
