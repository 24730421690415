import classNames from "classnames";

export const InfoIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-4 h-4 mr-1")}
    viewBox="0 0 16 17"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon Error" clipPath="url(#clip0_374_6641)">
      <path
        id="Vector"
        d="M8 0.5C3.584 0.5 0 4.084 0 8.5C0 12.916 3.584 16.5 8 16.5C12.416 16.5 16 12.916 16 8.5C16 4.084 12.416 0.5 8 0.5ZM8.8 12.5H7.2V10.9H8.8V12.5ZM8.8 9.3H7.2V4.5H8.8V9.3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
