import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import { useVirtualizer } from "@tanstack/react-virtual";
import {
  Cell,
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { Loading } from "../Loading";

import { useGetAccountBalanceDetails } from "../../state/account";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";

import { DashboardPathnames } from "../../pages/Dashboard";

import { BalanceDetail } from "../../types/account";

const columnHelper = createColumnHelper<BalanceDetail>();
const secondaryColumns = [
  columnHelper.accessor("chargedAmount", {
    id: "chargedAmount",
    header: "Charged Amount",
    cell: ({ cell }) => (
      <span className="text-tertiaryText">
        {formatMoneyStringWithSign(cell.getValue())}
      </span>
    ),
  }),
];

const DefaultData: BalanceDetail[] = [];

/**
 * This component show account balance detail table
 * We use this table mostly as sub-component under Transactions table
 */
export const AccountBalanceDetailsTable = ({
  isAdmin = false,
  ledgerId,
}: {
  isAdmin?: boolean;
  ledgerId: string;
}) => {
  const { data, isLoading } = useGetAccountBalanceDetails(ledgerId);

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const memoColumns = useMemo(
    () => [
      columnHelper.accessor("orderId", {
        id: "orderId",
        header: "Order ID",
        cell: ({ cell }) => {
          const orderId = cell.getValue();
          if (!orderId) {
            return <span className="font-semibold">-</span>;
          }

          return (
            <Link
              to={
                isAdmin
                  ? `${DashboardPathnames.orders}?orderId=${orderId}`
                  : `/orders?orderId=${orderId}`
              }
              className="font-semibold hover:underline"
            >
              {orderId}
            </Link>
          );
        },
      }),
      ...secondaryColumns,
    ],
    [data?.data, isAdmin],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 45, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const getRowData = (cell: Cell<BalanceDetail, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.id}_${camelCaseToSnakeCase(cell.column.id)}`}
        key={cell.id}
        className="flex w-full p-3"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  if (isLoading) {
    return (
      <Loading
        className="flex justify-center p-6 m-2 font-semibold bg-white border rounded-lg shadow-md"
        description="Loading"
      />
    );
  }

  return (
    <div className="flex m-2 bg-white border rounded-lg shadow-md">
      <div className="w-full p-6">
        <div
          ref={tableContainerRef}
          className="relative overflow-x-auto rounded-lg shadow-md max-h-52"
        >
          <table className="grid text-sm text-left">
            {/* Table column names */}
            <thead className="sticky top-0 z-10 grid border select-none bg-background">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="flex w-full">
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="flex w-full p-3">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table data */}
            <tbody
              className="relative grid"
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];

                return (
                  <tr
                    data-index={virtualRow.index} //needed for dynamic row height measurement
                    ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                    key={row.id}
                    data-testid={`${row.id}_ACCOUNT_BALANCE_DETAIL_ROW`}
                    className="absolute flex items-center w-full bg-white border border-background"
                    style={{
                      transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    }}
                  >
                    {row.getVisibleCells().map((cell) => getRowData(cell))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
