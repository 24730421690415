import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";
import { useIsMutating } from "@tanstack/react-query";

type VariantType = "brand" | "red" | "green" | "transparent" | "gray" | "dark";

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  /**
   * Pass Custom CSS to the Button
   *
   * NOTE: Is applied by default and trumps variant and variantClassName
   */
  className?: string;
  /**
   * Use the default CSS Variant that is predefined
   */
  variant?: VariantType;
  /**
   * Add extra css over the variant
   *
   * Note: Is only applied if className is not provided and variant is provided
   */
  variantClassName?: string;
  /**
   * Applies Variant CSS only to text and not to the background
   */
  textVariant?: boolean;
  /**
   * Applies Variant CSS inverting the default variant css
   */
  invertVariant?: boolean;
};

/**
 * Generic Button Component
 * Button component is used to render generic button in whole application
 * so can manage button from one file
 */
export const Button = ({
  children,
  onClick,
  disabled,

  className,

  variant,
  variantClassName,
  invertVariant,
  textVariant,

  ...props
}: ButtonProps) => {
  const isMutating = useIsMutating();

  const currentVariant = variant && disabled ? "disabled" : variant;

  return (
    <button
      {...props}
      onClick={onClick}
      disabled={disabled || !!isMutating}
      className={classNames(
        "disabled:pointer-events-none rounded-lg",
        className ? className : variantClassName,
        className
          ? {}
          : {
              // VARIANT DEFAULT STYLE
              "px-4 py-1 hover:font-semibold hover:tracking-normal font-normal tracking-wide focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-uiBorder focus:ring-uiBorder min-h-[36px]":
                !!variant,

              // BORDER FOR VARIANTS
              "border-2": !!variant && !textVariant,

              // DISABLED VARIANT
              "opacity-80 bg-background border-uiBorder text-name font-semibold":
                currentVariant === "disabled" && !textVariant,

              // BRAND VARIANT
              "bg-brand border-brand text-background":
                currentVariant === "brand" && !textVariant && !invertVariant,
              "bg-transparent border-brand text-brand":
                currentVariant === "brand" && !textVariant && invertVariant,
              "text-brand": currentVariant === "brand" && textVariant,

              // GREEN VARIANT
              "bg-positive border-positive text-background":
                currentVariant === "green" && !textVariant && !invertVariant,
              "bg-transparent border-positive text-positive":
                currentVariant === "green" && !textVariant && invertVariant,
              "text-positive": currentVariant === "green" && textVariant,

              // RED VARIANT
              "bg-negative border-negative text-background":
                currentVariant === "red" && !textVariant && !invertVariant,
              "bg-transparent border-negative text-negative":
                currentVariant === "red" && !textVariant && invertVariant,
              "text-negative": currentVariant === "red" && textVariant,

              // DARK VARIANT
              "bg-primaryText border-primaryText text-background":
                currentVariant === "dark" && !textVariant && !invertVariant,
              "bg-transparent border-primaryText text-primaryText":
                currentVariant === "dark" && !textVariant && invertVariant,

              // GRAY VARIANT
              "bg-tertiaryText border-gray-200 text-gray-200":
                currentVariant === "gray" && !textVariant && !invertVariant,
              "bg-gray-200 border-tertiaryText text-tertiaryText":
                currentVariant === "gray" && !textVariant && invertVariant,

              // TRANSPARENT VARIANT
              "border-uiBorder text-secondaryText":
                currentVariant === "transparent" &&
                !textVariant &&
                !invertVariant,
              "bg-secondaryText border-background text-background":
                currentVariant === "transparent" &&
                !textVariant &&
                invertVariant,

              // DARK | GAY | TRANSPARENT VARIANT FOR ONLY TEXT
              "text-primaryText":
                (currentVariant === "dark" ||
                  currentVariant === "gray" ||
                  currentVariant === "transparent") &&
                textVariant,
            },
      )}
    >
      <>{children}</>
    </button>
  );
};
