import { Dialog } from "@headlessui/react";

import { InviteAccountUserModalContent } from "./InviteAccountUserModalContent";
import { DeleteAccountUserModalContent } from "./DeleteAccountUserModalContent";

import { AccountUser } from "../../types/account";

interface CompanyUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalName: string | null;
  accountId: string;
  user?: AccountUser;
}

/**
 * The Modal shows Modal and Content related to Account Users Actions ie.:
 * - Invite a user
 * - Delete a user
 */
export const AccountUsersModal = ({
  isOpen,
  onClose,
  modalName,
  accountId,
  user,
}: CompanyUsersModalProps) => {
  return (
    <Dialog
      id={`${modalName}-modal`}
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={() => console.log("closed")}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        // onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          {modalName === "add-user" && (
            <InviteAccountUserModalContent
              onClose={onClose}
              accountId={accountId}
            />
          )}
          {modalName === "delete-user" && !!user && (
            <DeleteAccountUserModalContent
              onClose={onClose}
              accountId={accountId}
              user={user}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
