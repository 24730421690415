import React from "react";

type GPUCountOptionProps = React.PropsWithChildren & {
  selected?: boolean;
  value: string;
};

export const GPUCountOption = ({
  children,
  selected,
  value,
}: GPUCountOptionProps) => {
  return (
    <option selected={selected} value={value}>
      {children}
    </option>
  );
};
