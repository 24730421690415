import { Link } from "react-router-dom";
import { DashboardPathnames } from "../../pages/Dashboard";

export type CompanyDetailComponent = {
  id?: string;
  company?: string;
};

/**
 * This component returns formatted account information based on the keys sent from the parent
 */
export const CompanyDetailComponent = ({
  id,
  company,
}: CompanyDetailComponent) => {
  return (
    <div className="flex flex-col truncate">
      {company && <span>{company}</span>}
      {id ? (
        <Link
          to={`${DashboardPathnames.accounts}?accountId=${id}`}
          className="font-semibold hover:underline"
        >
          {id}
        </Link>
      ) : (
        "-"
      )}
    </div>
  );
};
