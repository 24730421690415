import moment from "moment";
import { useEffect, useState } from "react";
import { HourGlassIcon } from "../icons/HourGlassIcon";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const formatTime = (value: number, label: string) => {
  if (value < 1) {
    return "";
  }
  return `${Math.floor(value)}`.padStart(2, "0") + label;
};

/**
 * This component accepts deadline (number of milliseconds elapsed since epoch)
 * and return a basic countdown to the deadline
 */
export const TimerComponent = ({
  deadline,
  isDeadlineReached = false,
  renderAfterDeadline,
}: {
  deadline: Date;
  isDeadlineReached?: boolean;
  renderAfterDeadline?: string | JSX.Element;
}) => {
  if (!deadline) {
    return <></>;
  }

  const [time, setTime] = useState(deadline.getTime() - Date.now());

  useEffect(() => {
    const interval = setInterval(
      () => setTime(deadline.getTime() - Date.now()),
      1000,
    );

    return () => clearInterval(interval);
  }, [deadline]);

  const parts = [
    formatTime(time / DAY, "d "), // days
    formatTime((time / HOUR) % 24, "h "), //hours
    formatTime((time / MINUTE) % 60, "m "), // minutes
    formatTime((time / SECOND) % 60, "s"), //seconds
  ];

  if (time < 0 || isDeadlineReached) {
    return (
      <span title={moment(deadline).format("LLL")} className="capitalize">
        {renderAfterDeadline ?? moment(deadline).format("LL")}
      </span>
    );
  }

  return (
    <div className="flex">
      <div className="flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-full bg-background truncate">
        <HourGlassIcon className="mr-1 text-name" />
        <span title={moment(deadline).format("LLL")}>{parts.join("")}</span>
      </div>
    </div>
  );
};
