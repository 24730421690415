import classNames from "classnames";

export const FilterArrowIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-4 h-4")}
    viewBox="0 0 20 20"
    aria-hidden="true"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
);
