import { useState } from "react";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { GenericInputs } from "../forms/GenericInputs";
import { AccountUsersTable } from "./AccountUsersTable";
import { AccountUsersModal } from "./AccountUsersModal";

import { AddUserIcon } from "../icons/AddUserIcon";

import { useAuthentication } from "../auth/AuthUserProvider";
import {
  useGetAccountInfo,
  useChangeAccountCompany,
} from "../../state/account";

import { canFormBeSubmitted } from "../forms/validator";

import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";

const keyToInput: InputKeyToInputType = {
  accountCompany: "text",
};

const requiredKey: RequiredInputKeyType = {
  accountCompany: true,
};

const CompanyPanel = ({
  accountId,
  companyName,
}: {
  accountId: string;
  companyName?: string;
}) => {
  const { changeAccountCompany } = useChangeAccountCompany();

  const originalState = {
    accountCompany: companyName ?? "",
  };
  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  const [showModal, setShowModal] = useState<string | null>(null);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    changeAccountCompany({ id: accountId, company: state.accountCompany });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      <div className="flex flex-col gap-6">
        <form
          className="p-4 pt-2 mt-3 bg-white border rounded-lg shadow-md"
          onSubmit={onSubmit}
        >
          <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
            <div className="grid grid-cols-1 gap-4">
              <GenericInputs
                state={state}
                originalState={originalState}
                inputs={keyToInput}
                required={requiredKey}
                errors={errors}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-6 pt-4 border-t border-uiBorder">
            <Button
              type="button"
              variant="transparent"
              variantClassName="min-w-[120px]"
              disabled={!isSubmitButtonEnabled}
              onClick={() => setState(originalState)}
            >
              Cancel
            </Button>
            <Button
              data-testid="ACCOUNT_SETTINGS_BUTTON"
              type="submit"
              variant="dark"
              variantClassName="min-w-[160px]"
              disabled={!isSubmitButtonEnabled}
            >
              Save Changes
            </Button>
          </div>
        </form>
        <div className="p-4 pt-2 bg-white border rounded-lg shadow-md">
          <div className="flex flex-row items-center justify-between gap-2 pt-3">
            <div>
              <h3 className="font-semibold">Team Members</h3>
              <span className="font-light text-secondaryText">
                Manage your team members here.
              </span>
            </div>
            <Button
              data-testid="ADD_USER_MODAL_BUTTON"
              variant="transparent"
              variantClassName="w-[250px] h-[20px] mr-3 flex justify-center items-center"
              onClick={() => setShowModal("add-user")}
            >
              <AddUserIcon className="mr-3 text-secondaryText" />
              Invite team members
            </Button>
          </div>
          <div className="w-full py-3">
            <AccountUsersTable accountId={accountId} />
          </div>
        </div>
      </div>
      <AccountUsersModal
        isOpen={showModal === "add-user"}
        onClose={() => setShowModal(null)}
        modalName={showModal}
        accountId={accountId}
      />
    </>
  );
};

const AuthenticatedCompanyPanel = ({ accountId }: { accountId: string }) => {
  const { data, isLoading } = useGetAccountInfo(accountId);

  if (isLoading) {
    return <Loading />;
  }
  if (data?.id) {
    return <CompanyPanel accountId={data.id} companyName={data.company} />;
  }

  return <></>;
};

/*
 * A component rendered on the profile page (ProfileInfo > Company) that displays the existing
 * Companies associated with a user and has a button that opens a modal and facilitates the updating
 * the company.
 */
export const CompaniesPanel = () => {
  const { accountId, isLoading } = useAuthentication();
  if (isLoading) {
    return <Loading />;
  }

  if (accountId) {
    return <AuthenticatedCompanyPanel accountId={accountId} />;
  }

  return <></>;
};
