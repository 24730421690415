import classNames from "classnames";
import { PlacesType } from "react-tooltip";

import { Tooltip } from "./Tooltip";

import { TooltipMessage } from "./const";

type TooltipButtonProps = {
  id: string;
  message?: string;
  className?: string;
  direction?: PlacesType;
};

/**
 * A Generic Tooltip Button Component
 * Defines a Tooltip Info button which is shown only when group is defined for it
 * On hovering the info icon, message is shown that is passed to the component
 * If message is not passed, the id will be used to look over the const to set the message
 */
export const TooltipButton = ({
  id,
  message,
  className,
  direction,
}: TooltipButtonProps) => {
  const actualMessage =
    message ?? TooltipMessage[id as keyof typeof TooltipMessage];

  return (
    <>
      <div
        className={classNames(
          id,
          "opacity-0 group-hover:opacity-100 hover:opacity-100 flex justify-center items-center",
          { "cursor-pointer": !!actualMessage },
          className,
        )}
      >
        <svg
          className={classNames("w-3.5 h-3.5", { "opacity-0": !actualMessage })}
          viewBox="0 0 16 16"
          aria-hidden="true"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2531_106403)">
            <path
              d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z"
              fill="currentColor"
            />
            <path
              d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z"
              fill="white"
              fillOpacity="0.3"
            />
          </g>
        </svg>
      </div>
      {!!actualMessage && (
        <Tooltip id={id} direction={direction} message={actualMessage} />
      )}
    </>
  );
};
