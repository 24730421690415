import { Menu, Transition } from "@headlessui/react";

import { LogoutButton } from "../auth/LogoutButton";

import { useGetAccountBalance } from "../../state/account";

import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";
import { getFirstNameAndLastNameInitial } from "../../utils/textFormatter";

import { User } from "../../types/user";

type ProfileMenuProps = {
  user: User;
  userPicture?: string;
  accountId: string;
};

/**
 * This component shows user profile if the user is authenticated
 * Shows the name, email and image if it exists
 */
export const ProfileMenu = ({
  user,
  userPicture,
  accountId,
}: ProfileMenuProps) => {
  const { data: balance, isLoading: isBalanceLoading } =
    useGetAccountBalance(accountId);

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        data-testid="USER_PROFILE_BUTTON"
        className="flex items-center px-2 py-1 border rounded-lg border-uiBorder hover:bg-background focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-uiBorder focus:ring-uiBorder"
      >
        <span className="mx-1 text-base tracking-wide md:text-sm">
          {getFirstNameAndLastNameInitial(user.name)}
        </span>
        <img className="inline w-8 h-8 rounded-full" src={userPicture} alt="" />
      </Menu.Button>
      <Transition
        enter-active-class="transition transform duration-100 ease-out"
        enter-from-class="opacity-0 scale-90"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition transform duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-90"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 overflow-hidden origin-top-right bg-white border divide-y rounded-md shadow-lg divide-background focus:outline-none">
          <Menu.Item>
            <div className="flex flex-col px-3 py-2 text-sm truncate">
              <span>{user.name}</span>
              <span className="text-secondaryText">{user.email}</span>
            </div>
          </Menu.Item>
          {!isBalanceLoading && (
            <Menu.Item>
              <span
                data-testid="USER_BALANCE_MOBILE_CONTAINER"
                className="flex px-3 py-2 text-sm font-normal tracking-wide text-secondaryText lg:hidden"
              >
                Balance:
                <span className="ml-1 font-semibold text-primaryText">
                  {formatMoneyStringWithSign(balance ?? 0)}
                </span>
              </span>
            </Menu.Item>
          )}
          <Menu.Item>
            <LogoutButton forcedClassName="block w-full px-4 py-2 text-sm text-left hover:font-semibold" />
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
