import { createContext, useCallback, useContext, useState } from "react";

export enum NotificationVariant {
  error = "error",
  info = "info",
  success = "success",
  warning = "warning",
}

interface Notification {
  message: string;
  link?: string;
  variant: NotificationVariant;
}

const notificationApi = {
  variants: NotificationVariant,
  notification: null as Notification | null,
  setNotification: (notification: Notification) => {
    console.log(notification);
  },
  clearNotification: () => {
    console.log("clear");
  },
};

export type NotificationsContextValue = typeof notificationApi;

/**
 * Create Context
 */
export const NotificationsContext =
  createContext<NotificationsContextValue>(notificationApi);

/**
 * Custom Notifications Provider
 */
export function NotificationsProvider({ children }: any) {
  // Notification state is managed in local useState
  const [notification, setNotification] = useState<Notification | null>(
    notificationApi.notification,
  );

  // Method to set a new notification
  const updateNotification = useCallback(
    (newNotification: Notification) => setNotification(newNotification),
    [setNotification],
  );

  // Method to clear the notification
  const clearNotification = useCallback(
    () => setNotification(null),
    [updateNotification],
  );

  // Return Provider with full API
  return (
    <NotificationsContext.Provider
      value={{
        variants: NotificationVariant,
        notification,
        setNotification: updateNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

// Convenience import hook
export function useNotifications() {
  return useContext(NotificationsContext);
}
