import { useEffect, useState } from "react";

import { Button } from "../LegacyButton";
import { Tooltip } from "../tooltip/Tooltip";
import { CopyIcon } from "../icons/CopyIcon";
import { CloseIcon } from "../icons/CloseIcon";
import { InputWrapper } from "../forms/InputWrapper";
import { DropdownSelector } from "../forms/DropdownSelector";

import { useChangeUserType } from "../../state/user";

import { copyTextToClipboard } from "../../utils/clipboard";

import { User, UserType } from "../../types/user";

/**
 * This component shows content related to changing user type
 * Giving you title, description, user information and buttons for action
 * And Select to accept the user type
 */
interface EditUserModalContentProps {
  onClose: () => void;
  user: User;
}

const UserTypeInformation = [
  { label: "Admin", value: "admin" },
  { label: "Blacklisted", value: "blacklisted" },
  { label: "Regular", value: "regular" },
  { label: "Trusted", value: "trusted" },
];

export const EditUserModalContent = ({
  onClose,
  user,
}: EditUserModalContentProps) => {
  const { changeUserType, isSuccess } = useChangeUserType();

  const [userTypeToChange, setUserTypeToChange] = useState(user.type);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onCopyButtonClick = () => {
    copyTextToClipboard(user.email)
      .then(() => console.log("Copied email to clipboard"))
      .catch((err) => console.log("Failed to copy email to clipboard " + err));
  };

  const updateUser = () => {
    changeUserType({ id: user.id, type: userTypeToChange });
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1
          data-testid="EDIT_USER_MODAL_HEADER"
          className="text-xl font-semibold leading-10 tracking-wide"
        >
          {user.name}
        </h1>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <span className="font-normal leading-tight text-secondaryText">
        {`User ID: ${user.id}`}
      </span>
      <div className="flex flex-col gap-3 pt-2 pb-6">
        <InputWrapper id="userEmail" label="Email">
          <div className="flex w-full text-base border rounded-md border-uiBorder">
            <span className="w-full p-[0.35em] text-base leading-snug border-0 border-r text-tertiaryText border-uiBorder">
              {user.email}
            </span>
            <div className="flex items-center justify-center mx-4">
              <Button
                className="copyEmail"
                data-testid="COPY_EMAIL_BUTTON"
                onMouseOver={() => onCopyButtonClick()}
                onClick={() => onCopyButtonClick()}
              >
                <CopyIcon className="hover:scale-110 text-secondaryText" />
              </Button>
              <Tooltip id="copyEmail" message="Copied" />
            </div>
          </div>
        </InputWrapper>
        <InputWrapper id="userStatus" label="Status">
          <DropdownSelector
            id="userStatus"
            value={userTypeToChange}
            options={UserTypeInformation}
            onChange={(value) => setUserTypeToChange(value as UserType)}
          />
        </InputWrapper>
      </div>

      <div className="flex items-center justify-center gap-4 pb-1">
        <Button
          data-testid="CANCEL_BUTTON"
          type="button"
          variant="transparent"
          variantClassName="w-1/2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="UPDATE_USER_BUTTON"
          type="button"
          variant="dark"
          variantClassName="w-1/2"
          disabled={user.type === userTypeToChange}
          onClick={() => updateUser()}
        >
          Update User
        </Button>
      </div>
    </>
  );
};
