import classNames from "classnames";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

export const getInvalidDescriptionMessage = (value: string) => {
  if (value.length <= 500) {
    return "";
  }

  if (value.length > 500) {
    return "Text is too long (maximum 500 characters)";
  }

  return "Please enter a valid description";
};

export const getInvalidSSHKeyMessage = (value: string) => {
  if (value.length <= 17000) {
    return "";
  }

  if (value.length > 17000) {
    return "Text is too long (maximum 17000 characters)";
  }

  return "Please enter a valid ssh-key";
};

type DescriptionInputProps = {
  id?: string;
  value?: string;
  required?: boolean;
  isError?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

/**
 *  Allows user to enter long text as input
 */
export const DescriptionInput = ({
  id,
  value,
  required,
  isError,
  placeholder = "Enter description",
  onChange,
}: DescriptionInputProps) => {
  return (
    <textarea
      className={classNames(
        "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full border h-36 min-h-[36px] p-2.5",
        {
          "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
            !isError,
          "border-negative focus:ring-negative focus:ring-offset-negative":
            !!isError,
        },
      )}
      id={id}
      data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
      required={required}
      value={value}
      onChange={(e) =>
        onChange(e.target.value.trimStart().replace(/\s\s+/g, " "))
      }
      onFocus={() => value && onChange(value)}
      placeholder={placeholder}
    />
  );
};
