import { useState } from "react";

import classNames from "classnames";

import { Button } from "../LegacyButton";

import { ExitIcon } from "../icons/ExitIcon";
import { EditIcon } from "../icons/EditIcon";
import { CheckIcon } from "../icons/CheckIcon";

export type EditNameComponentProps = {
  name: string;
  isError: boolean;
  isDisabled: boolean;
  setIsFormDisabled: (value: boolean) => void;
  handleSubmit: (value: string) => void;
};

/**
 * This component is responsible for the actual editing of the name and calling the endpoint on submit.
 */
export const EditNameComponent = ({
  name,
  isError,
  isDisabled,
  setIsFormDisabled,
  handleSubmit,
}: EditNameComponentProps) => {
  const [editName, setEditName] = useState(name);

  const onNameChange = (newName: string) => {
    // Allow only Letters, Numbers and space " "
    if (/^[A-Za-z\s0-9]{0,10}$/.test(newName)) {
      setEditName(newName);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isDisabled) {
      setIsFormDisabled(false);
      return;
    }
    handleSubmit(editName);
  };

  return (
    <form className="flex" onSubmit={onSubmit}>
      <div className="relative flex items-center">
        <div className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer">
          {!isDisabled && (
            <Button
              type="button"
              onClick={() => {
                setEditName(name);
                setIsFormDisabled(true);
              }}
            >
              <ExitIcon className="text-name" />
            </Button>
          )}
        </div>
        <input
          data-testid="NAME_INPUT"
          className={classNames(
            "text-sm block w-full p-1.5 border rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-uiBorder focus:ring-uiBorder",
            {
              "bg-transparent border-transparent border-y-2": isDisabled,
              "pr-8": !isDisabled,
              "border-negative focus:ring-negative focus:ring-offset-negative":
                !isDisabled && !!isError,
            },
          )}
          type="text"
          value={editName}
          disabled={isDisabled}
          onChange={(e) =>
            onNameChange(e.target.value.trimStart().replace(/\s\s+/g, " "))
          }
          placeholder="Enter name"
        />
      </div>
      <Button
        data-testid="EDIT_NAME_BUTTON"
        type="submit"
        className="p-1 pl-2 hover:scale-110"
      >
        {isDisabled && <EditIcon className="text-secondaryText" />}
        {!isDisabled && (
          <div className="px-1 py-0.5 rounded-md bg-secondaryText">
            <CheckIcon className="text-white" />
          </div>
        )}
      </Button>
    </form>
  );
};
