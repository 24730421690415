import classNames from "classnames";

import { Button } from "../LegacyButton";
import { CrossIcon } from "../icons/CrossIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

const getMaxLength = (value: number) => (value > 0 ? 9 : 10);

export const getInvalidNumberMessage = (
  value: string,
  type: "any-number" | "number" | "positive-number" | "gpu-number",
) => {
  if (
    !value ||
    (!Number.isNaN(+value) && value.length <= getMaxLength(+value))
  ) {
    if (type === "gpu-number") {
      if (+value <= 0) {
        return "Value should be greater than zero";
      }
      if (+value % 8 !== 0) {
        return "Value should be  a multiple of 8";
      }
    }
    if (type === "positive-number" && +value <= 0) {
      return "Value should be greater than zero";
    }
    if (type === "number" && +value < 0) {
      return "Value should not be less than zero";
    }
    return "";
  }

  if (value.length > 9) {
    return "Value is too long (maximum 9 characters)";
  }

  return "Please enter a valid number";
};

type NumberInputProps = {
  id?: string;
  value?: string;
  originalValue?: string;
  required?: boolean;
  isError?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

/**
 *  Allows user to enter numbers
 */
export const NumberInput = ({
  id,
  value,
  originalValue,
  required,
  isError,
  placeholder = "Enter number",
  onChange,
}: NumberInputProps) => {
  return (
    <>
      {originalValue && value !== originalValue && (
        <div className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer">
          <Button type="button" onClick={() => onChange(originalValue)}>
            <CrossIcon className="text-name" />
          </Button>
        </div>
      )}
      <input
        autoFocus={true}
        className={classNames(
          "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border",
          {
            "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
              !isError,
            "border-negative focus:ring-negative focus:ring-offset-negative":
              !!isError,
            "pr-8": originalValue && value !== originalValue,
          },
        )}
        id={id}
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        type="text"
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={(e) =>
          /^[0-9\-]*$/.test(e.target.value) && onChange(e.target.value)
        }
        onFocus={() => value && onChange(value)}
      />
    </>
  );
};
