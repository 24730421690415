import classNames from "classnames";

export const ExitIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-4 h-4")}
    viewBox="0 0 16 16"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1336_13582)">
      <path
        d="M5.4535 12L8.3335 9.12L11.2135 12L12.3335 10.88L9.4535 8L12.3335 5.12L11.2135 4L8.3335 6.88L5.4535 4L4.3335 5.12L7.2135 8L4.3335 10.88L5.4535 12ZM8.3335 16C7.22683 16 6.18683 15.7899 5.2135 15.3696C4.24016 14.9493 3.3935 14.3795 2.6735 13.66C1.9535 12.94 1.38363 12.0933 0.963896 11.12C0.544163 10.1467 0.334029 9.10667 0.333496 8C0.333496 6.89333 0.543629 5.85333 0.963896 4.88C1.38416 3.90667 1.95403 3.06 2.6735 2.34C3.3935 1.62 4.24016 1.05013 5.2135 0.6304C6.18683 0.210667 7.22683 0.000533333 8.3335 0C9.44016 0 10.4802 0.210133 11.4535 0.6304C12.4268 1.05067 13.2735 1.62053 13.9935 2.34C14.7135 3.06 15.2836 3.90667 15.7039 4.88C16.1242 5.85333 16.334 6.89333 16.3335 8C16.3335 9.10667 16.1234 10.1467 15.7031 11.12C15.2828 12.0933 14.713 12.94 13.9935 13.66C13.2735 14.38 12.4268 14.9501 11.4535 15.3704C10.4802 15.7907 9.44016 16.0005 8.3335 16Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1336_13582">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
