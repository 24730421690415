import { Row } from "@tanstack/react-table";

import { Button } from "../LegacyButton";

import { UpArrowIcon } from "../icons/UpArrowIcon";

/**
 * This component handles expansion icon which acts only as placeholder as on onClick action takes place
 */
export const RowExpandIcon = ({ row }: { row: Row<any> }) => {
  if (!row.getCanExpand()) {
    return <></>;
  }

  return (
    <Button
      data-testid="EXPAND_BUTTON"
      {...{
        className: "cursor-pointer hover:bg-transparent",
      }}
    >
      {row.getIsExpanded() ? (
        <UpArrowIcon />
      ) : (
        <UpArrowIcon className="-rotate-90" />
      )}
    </Button>
  );
};
