import { Link } from "react-router-dom";

import { Header } from "../Components/Header";
import JoinDiscordImage from "../images/JoinDiscord.png";
import { TwitterLogoLegacy } from "../Components/icons/TwitterLogoLegacy";

/*
 * A page containing int18 Contact Us page at /contact-us.
 */
export const ContactUs = () => {
  return (
    <>
      <div className="pb-4 bg-background min-h-page">
        <Header title="Contact us" />
        <div className="w-full h-56 bg-center bg-no-repeat bg-contain bg-about-background" />
        <div className="p-8 mb-48 lg:py-8 lg:px-80">
          <div className="flex flex-col flex-wrap content-center text-center">
            <p className="my-2 text-base">
              File a support ticket by emailing us at{" "}
              <b>
                <Link
                  to={{ pathname: "support@voltagepark.com" }}
                  className="hover:underline text-brand"
                  target="_blank"
                >
                  support@voltagepark.com
                </Link>
              </b>
            </p>
            <p>
              {" "}
              We will get back to you within an hour on Mon-Fri, 9 AM - 8 PM ET.
            </p>
            <p className="my-2 mt-5 text-base">Follow us on Twitter:</p>
            <Link
              to={{ pathname: "https://twitter.com/VoltagePark" }}
              className="flex justify-center pt-1 pb-4"
              target="_blank"
            >
              <TwitterLogoLegacy className="w-11 h-11" />
            </Link>
            <p className="my-2 mt-5 text-base">Join the Discord server:</p>
            <div className="flex justify-center">
              {" "}
              {/* Added flex and justify-center classes */}
              <Link
                to={{ pathname: "https://discord.gg/22yqpvj6Zc" }}
                target="_blank"
              >
                <img
                  className="hover:scale-105"
                  src={JoinDiscordImage}
                  alt="Join the Voltage Park discord channel for support"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
