export type ConnectivityOption = "infiniband" | "ethernet";
export type GPUCountOptionConfig = {
  copy: string;
  default?: boolean;
  value: number;
};

type CountOptions = {
  [key in ConnectivityOption]: GPUCountOptionConfig[];
};

const INFINIBAND: ConnectivityOption = "infiniband";
const ETHERNET: ConnectivityOption = "ethernet";

export const Connectivity = {
  INFINIBAND,
  ETHERNET,
};

export const InvalidInfinibandGPUCounts = [8];
export const AllGPUCountOptions: GPUCountOptionConfig[] = [
  {
    copy: "8",
    value: 8,
  },
  {
    copy: "16",
    value: 16,
    default: true,
  },
  {
    copy: "24",
    value: 24,
  },
  {
    copy: "32",
    value: 32,
  },
  {
    copy: "40",
    value: 40,
  },
  {
    copy: "48",
    value: 48,
  },
  {
    copy: "56",
    value: 56,
  },
  {
    copy: "64",
    value: 64,
  },
  {
    copy: "72",
    value: 72,
  },
  {
    copy: "80",
    value: 80,
  },
  {
    copy: "88",
    value: 88,
  },
  {
    copy: "96",
    value: 96,
  },
  {
    copy: "104",
    value: 104,
  },
  {
    copy: "112",
    value: 112,
  },
  {
    copy: "120",
    value: 120,
  },
  {
    copy: "128",
    value: 128,
  },
  {
    copy: "192",
    value: 192,
  },
  {
    copy: "256",
    value: 256,
  },
  {
    copy: "512",
    value: 512,
  },
  {
    copy: "800",
    value: 800,
  },
  {
    copy: "1,016",
    value: 1016,
  },
];

export const DefaultGPUCount =
  AllGPUCountOptions.find((option) => option.default)?.value || 16;

export const countOptions: CountOptions = {
  [Connectivity.ETHERNET]: AllGPUCountOptions,
  [Connectivity.INFINIBAND]: AllGPUCountOptions.filter(
    (option) => !InvalidInfinibandGPUCounts.includes(option.value),
  ),
};
