import classNames from "classnames";

export const HamburgerIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-5 h-5")}
    viewBox="0 0 17 14"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1h15M1 7h15M1 13h15"
    />
  </svg>
);
