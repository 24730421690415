import { Helmet } from "react-helmet-async";

type SEOProps = {
  title?: string;
  description?: string;
  name?: string;
  type?: string;
  image?: string;
};

/**
 * This component handles SEO
 * Set title and description to be linked to index.html
 * And, og:tags related to social media (title, description, image)
 *
 */
export const SEO = ({
  title = "VP Exchange",
  description = "VP Exchange is a web application that allows users to buy GPU time",
  name = "VoltagePark",
  type = "website",
  image,
}: SEOProps) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
