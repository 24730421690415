import { Column } from "@tanstack/react-table";

import { ColumnHeaderSortIcon } from "./ColumnHeaderSortIcon";
import { ColumnHeaderFilterIcon } from "./ColumnHeaderFilterIcon";

export type ColumnHeaderIconProps = {
  column: Column<any, unknown>;
};

/** Shows user the Arrow icon based on the current condition */
export const ColumnHeaderIcon = ({ column }: ColumnHeaderIconProps) => {
  return (
    <>
      {column.getCanFilter() && <ColumnHeaderFilterIcon column={column} />}
      <ColumnHeaderSortIcon column={column} />
    </>
  );
};
