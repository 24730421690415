import classNames from "classnames";

export const AscendingArrowIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames("w-3.5 h-3.5 ml-1.5", className)}
    viewBox="0 0 12 12"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99984 10.6667L5.99984 1.33335M5.99984 1.33335L1.33317 6.00002M5.99984 1.33335L10.6665 6.00002"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
