import { useIsFetching } from "@tanstack/react-query";

import classNames from "classnames";

import { Button } from "../LegacyButton";
import { EditIcon } from "../icons/EditIcon";

export type UserOptionsComponentProps = {
  setShowModal: (type: string) => void;
};

/**
 * This component handles editing user information or adding balance for the user
 * Used mainly user Admin Users Table to update users information
 */
export const UserOptionsComponent = ({
  setShowModal,
}: UserOptionsComponentProps) => {
  const isFetching = useIsFetching();

  return (
    <div
      className={classNames("flex space-x-2", {
        "pointer-events-none": isFetching,
      })}
    >
      <Button
        data-testid="UPDATE_USER_MODAL_BUTTON"
        className="p-1"
        onClick={() => setShowModal("edit-user")}
      >
        <EditIcon className="hover:scale-110 text-secondaryText" />
      </Button>
    </div>
  );
};
