import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "../LegacyButton";
import { DashboardPathnames } from "../../pages/Dashboard";
import { OrderConfirmationDescription } from "./OrderConfirmationDescription";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import { EditIcon } from "../icons/EditIcon";
import { CloseIcon } from "../icons/CloseIcon";

import { useUpdateOrder } from "../../state/order";

import { isUnfulfilledOrder } from "../../utils/order";

import { Order } from "../../types/order";
import {
  InputErrorType,
  InputSelectType,
  InputKeyToInputType,
  RequiredInputKeyType,
  InputKeyToValidatorType,
} from "../forms/types";

const defaultRequiredKey: RequiredInputKeyType = {
  numberOfGPUs: true,
  maxPricePerGPU: true,
};

const keyToOptions: InputSelectType = {
  orderPriority: [
    { value: "0", label: "Normal" },
    { value: "1", label: "High" },
  ],
};

interface EditOrderModalContentProps {
  onClose: () => void;
  order: Order;
}

/**
 * This component shows content related to updating an order
 * Giving you title, description and buttons for action
 */
export const EditOrderModalContent = ({
  onClose,
  order,
}: EditOrderModalContentProps) => {
  const { pathname } = useLocation();
  const isAdmin = pathname === DashboardPathnames.orders;

  const { updateOrder, isSuccess } = useUpdateOrder();

  const originalState = {
    numberOfGPUs: order.numberOfGPUs.toString(),
    maxPricePerGPU: order.maxPricePerGPU.toString(),
    orderPriority: order.priority.toString() ?? "0",
  };

  const keyToInput: InputKeyToInputType = {
    numberOfGPUs: "gpu-number",
    maxPricePerGPU:
      order.type === "float" ? "positive-currency" : "fixed-currency",
  };

  const keyToValidator: InputKeyToValidatorType = {
    numberOfGPUs:
      isAdmin || isUnfulfilledOrder(order)
        ? []
        : [{ value: originalState.numberOfGPUs, condition: "===" }],
    maxPricePerGPU:
      !isAdmin && !isUnfulfilledOrder(order)
        ? [{ value: originalState.maxPricePerGPU, condition: ">=" }]
        : [],
  };

  const requiredKey = isAdmin
    ? { ...defaultRequiredKey, orderPriority: true }
    : defaultRequiredKey;

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateOrder({
      id: order.id,
      type: order.type,
      priority: +state.orderPriority,
      name: order.name,
      numberOfGPUs: +state.numberOfGPUs,
      maxPricePerGPU: +state.maxPricePerGPU,
      isAdmin: isAdmin,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="p-3 rounded-full bg-brand bg-opacity-20 w-fit">
          <EditIcon className="text-brand" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="EDIT_ORDER_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Update Order
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        {`Order ID: ${order.id}`}
      </span>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col pt-2 pb-6 tracking-tight text-left ">
          <div className="grid grid-cols-1 gap-4">
            <GenericInputs
              state={state}
              originalState={originalState}
              validators={keyToValidator}
              options={keyToOptions}
              inputs={
                isAdmin
                  ? { ...keyToInput, orderPriority: "select" }
                  : keyToInput
              }
              required={requiredKey}
              errors={errors}
              onChange={onChange}
            />
          </div>
        </div>
        <OrderConfirmationDescription />
        {state.maxPricePerGPU < originalState.maxPricePerGPU && (
          <div className="mb-3 text-sm text-secondaryText">
            Decreasing max price can end the order early, click "Update" to
            continue anyway!
          </div>
        )}
        <div className="flex items-center justify-center gap-4 pb-1">
          <Button
            data-testid="CANCEL_BUTTON"
            type="button"
            variant="transparent"
            variantClassName="w-1/2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="UPDATE_ORDER_BUTTON"
            type="submit"
            variant="dark"
            variantClassName="w-1/2"
            disabled={!isSubmitButtonEnabled}
          >
            {isAdmin ? "Update Order" : "Confirm"}
          </Button>
        </div>
      </form>
    </>
  );
};
