import { Fragment } from "react";

import moment from "moment";
import classNames from "classnames";
import { Float } from "@headlessui-float/react";
import { Listbox, Transition } from "@headlessui/react";

import { CheckIcon } from "../icons/CheckIcon";
import { CrossIcon } from "../icons/CrossIcon";
import { FilterArrowIcon } from "../icons/FilterArrowIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { InputOptionType } from "./types";

export const getInvalidSelectMessage = (
  value: string,
  options: InputOptionType[],
) => {
  if (options.find((option) => option.value === value)) {
    return "";
  }

  return "Please select a valid option";
};

type DropdownSelectorProps = {
  id?: string;
  value?: string;
  options: InputOptionType[];
  disabled?: boolean;
  required?: boolean;
  isError?: boolean;
  extraCss?: string;
  onChange: (value: string) => void;
};

/**
 *  Allows user to select an option from the dropdown
 */
export const DropdownSelector = ({
  id,
  value,
  disabled,
  required,
  options,
  isError,
  extraCss,
  onChange,
}: DropdownSelectorProps) => {
  return (
    <select
      className={classNames(
        "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border",
        {
          "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
            !isError,
          "border-negative focus:ring-negative focus:ring-offset-negative":
            !!isError,
        },
        extraCss || "",
      )}
      id={id}
      data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
      required={required}
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options?.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

type MultipleDropdownSelectorProps = {
  id?: string;
  values?: any[];
  options: any[];
  placeholder?: any;
  onChange: (values: any[]) => void;
};

const formatOptionLabel = (option: any) => {
  if (option === null && option === undefined) {
    return "";
  }
  if (option instanceof Date) {
    return moment(option).format("LLL");
  }
  return option.toString();
};

/**
 *  Allows user to select multiple options
 */
export const MultipleDropdownSelector = ({
  id,
  values,
  options,
  placeholder = "Select text",
  onChange,
}: MultipleDropdownSelectorProps) => {
  const firstOptionLength = formatOptionLabel(options[0].toString()).length;

  return (
    <Listbox multiple={true} value={values} onChange={onChange}>
      <Float portal>
        <Listbox.Button
          className="w-full text-sm text-primaryText rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block p-1.5 border border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder pr-16 bg-white text-left"
          id={id}
          data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        >
          <span
            className="block truncate"
            style={{ width: Math.max(firstOptionLength * 7 + 4, 80) }}
          >
            {(!values || values.length === 0) && placeholder}
            {values &&
              values.length !== 0 &&
              values?.map((value) => formatOptionLabel(value)).join(", ")}
          </span>
          <div
            className={classNames(
              "absolute inset-y-0 right-8 flex items-center p-2.5 cursor-pointer z-10",
              {
                "opacity-0": !values || values.length === 0,
                "opacity-100": values && values.length !== 0,
              },
            )}
            onClick={() => onChange([])}
          >
            <CrossIcon className="text-name" />
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <FilterArrowIcon className="text-name" />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="py-1 mt-1 text-base bg-white border rounded-md shadow-lg sm:text-sm max-h-36">
            {options.map((option) => (
              <Listbox.Option
                key={option}
                value={option}
                className={({ active }) =>
                  classNames("relative cursor-pointer py-2 pl-10 pr-4", {
                    "bg-brand text-background": active,
                  })
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {formatOptionLabel(option)}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brandHover">
                        <CheckIcon className="w-5 h-5" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Float>
    </Listbox>
  );
};
