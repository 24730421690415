import moment from "moment";

import { getInvalidTimeMessage } from "./TimePicker";
import { getInvalidDateMessage } from "./DatePicker";
import { getInvalidNumberMessage } from "./NumberInput";
import { getInvalidCurrencyMessage } from "./CurrencyInput";
import { getInvalidSelectMessage } from "./DropdownSelector";
import { getInvalidTimeLengthMessage } from "./TimeLengthInput";
import { getInvalidSearchableMessage } from "./SearchableInput";
import { getInvalidEmailMessage, getInvalidTextMessage } from "./TextInput";
import {
  getInvalidSSHKeyMessage,
  getInvalidDescriptionMessage,
} from "./DescriptionInput";

import { inputKeyToLabel } from "./const";

import {
  InputErrorType,
  ValidationType,
  InputKeyToInputType,
  InputKeyToValidatorType,
  InputKeyType,
  InputSelectType,
  InputStateType,
  InputValueType,
  RequiredInputKeyType,
} from "./types";

/**
 * This is a generic form submit handler, mainly used to disable the button when required keys are not set
 * If the required keys array is not passed, all the state keys will be marked as required
 */
export const canFormBeSubmitted = ({
  state,
  originalState,
  required = {},
  errors,
  allowOriginalState = false,
}: {
  state: InputStateType;
  originalState: InputStateType;
  required?: RequiredInputKeyType;
  errors: InputErrorType;
  allowOriginalState?: boolean;
}) => {
  if (Object.keys(errors).length !== 0) {
    return false;
  }

  if (
    !allowOriginalState &&
    JSON.stringify(originalState) === JSON.stringify(state)
  ) {
    return false;
  }

  let isFieldMissing = false;

  for (const key of Object.keys(required)) {
    const value = state[key as InputKeyType];

    if (typeof value === "string") {
      if (!value || value.trim().length === 0) {
        isFieldMissing = true;
        break;
      }
    }
  }

  return !isFieldMissing;
};

/**
 * This function handle looping through the state object to identify potential validation errors and update state
 * Handles checking date, string and comparing with other validators
 */
export const validateErrorAndUpdateState = (
  inputs: InputKeyToInputType,
  state: InputStateType,
  errors: InputErrorType,
  options?: InputSelectType,
  validators?: InputKeyToValidatorType,
) => {
  (Object.keys(state) as InputKeyType[]).forEach((stateKey) => {
    const dateValidatedObject = updateAndValidateDate(
      stateKey,
      inputs,
      state,
      errors,
      state[stateKey],
    );
    const stringValidatedObject = updateAndValidateString(
      stateKey,
      inputs,
      dateValidatedObject.state,
      dateValidatedObject.errors,
      state[stateKey],
      options,
    );
    state = { ...stringValidatedObject.state };
    errors = { ...stringValidatedObject.errors };
  });

  if (!!validators) {
    (Object.keys(validators) as InputKeyType[]).forEach((validatorKey) => {
      const comparedObject = updateAndCompare(
        validatorKey,
        state,
        errors,
        state[validatorKey],
        validators[validatorKey],
      );
      state = { ...comparedObject.state };
      errors = { ...comparedObject.errors };
    });
  }

  return { state, errors };
};

/**
 * This functions handles validation for date and updating the state based on date/time
 * Returns the necessary error message & state object
 */
export const updateAndValidateDate = (
  key: InputKeyType,
  inputs: InputKeyToInputType,
  state: InputStateType,
  errors: InputErrorType,
  value?: InputValueType,
) => {
  if (!value) {
    return { state, errors };
  }

  const inputKey = inputs[key];

  if (
    (inputKey === "any-date" ||
      inputKey === "any-time" ||
      inputKey === "future-date" ||
      inputKey === "future-time") &&
    value instanceof Date
  ) {
    // Extract all the keys have input type as -date or -time
    const stateKeys = Object.keys(state) as InputKeyType[];
    const dateKeys = stateKeys.filter(
      (stateKey) => inputs[stateKey]?.includes("-date"),
    );
    const timeKeys = stateKeys.filter(
      (stateKey) => inputs[stateKey]?.includes("-time"),
    );

    // Set key based on the changed key is of type date or time
    let dateKey = inputKey.includes("date") ? key : undefined;
    let timeKey = inputKey.includes("time") ? key : undefined;

    // Set the counter part based on the availability of other part
    // Works by just matching the index for changed key within the above extracted keys
    if (!!dateKey) {
      const dateIndex = dateKeys.findIndex((dateKey) => dateKey === key);
      if (timeKeys[dateIndex]) timeKey = timeKeys[dateIndex];
    }
    if (!!timeKey) {
      const timeIndex = timeKeys.findIndex((timeKey) => timeKey === key);
      if (dateKeys[timeIndex]) dateKey = dateKeys[timeIndex];
    }

    // Set value based on the changed key is of type date or time
    let dateValue = inputKey.includes("date") ? value : undefined;
    let timeValue = inputKey.includes("time") ? value : undefined;

    // Set the counter part based on the availability of other part
    // Works by just extracting the counter part key and applying state value
    if (inputKey.includes("date") && timeKey) {
      timeValue = state[timeKey as InputKeyType] as Date;
    }
    if (inputKey.includes("time") && dateKey) {
      dateValue = state[dateKey as InputKeyType] as Date;
    }

    // Get moment based on the Date & Time value
    const timeMoment = moment(timeValue);
    const dateTimeMoment = moment(dateValue);
    dateTimeMoment.set({
      hour: timeMoment.hours(),
      minute: timeMoment.minutes(),
      second: timeMoment.seconds(),
    });

    const errorMessageForDate = getInvalidDateMessage(
      dateTimeMoment.toDate(),
      !inputKey.includes("future-"),
    );
    const errorMessageForTime = getInvalidTimeMessage(
      dateTimeMoment.toDate(),
      !inputKey.includes("future-"),
    );

    if (errorMessageForDate || errorMessageForTime) {
      if (dateKey) errors[dateKey] = errorMessageForDate;
      if (timeKey) errors[timeKey] = errorMessageForTime;
    } else {
      if (dateKey) {
        state[dateKey] = dateTimeMoment.toDate();
        delete errors[dateKey];
      }
      if (timeKey) delete errors[timeKey];
    }
  }

  return { state, errors };
};

/**
 * This functions handles validation for string based on input type
 * Returns the necessary error message & state object
 */
export const updateAndValidateString = (
  key: InputKeyType,
  inputs: InputKeyToInputType,
  state: InputStateType,
  errors: InputErrorType,
  value?: InputValueType,
  options?: InputSelectType,
) => {
  if (!value) {
    return { state, errors };
  }

  const inputKey = inputs[key];
  let errorMessage = "";

  if (typeof value === "string") {
    switch (inputKey) {
      case "select":
      case "radio":
      case "checkbox":
        errorMessage = getInvalidSelectMessage(value, options?.[key] ?? []);
        break;
      case "time-length":
        errorMessage = getInvalidTimeLengthMessage(value);
        break;
      case "any-currency":
      case "currency":
      case "positive-currency":
        errorMessage = getInvalidCurrencyMessage(value, inputKey);
        break;
      case "text":
        errorMessage = getInvalidTextMessage(value);
        break;
      case "email":
        errorMessage = getInvalidEmailMessage(value);
        break;
      case "searchable-text":
        errorMessage = getInvalidSearchableMessage(value, options?.[key] ?? []);
        break;
      case "any-number":
      case "number":
      case "positive-number":
      case "gpu-number":
        errorMessage = getInvalidNumberMessage(value, inputKey);
        break;
      case "ssh-key":
        errorMessage = getInvalidSSHKeyMessage(value);
        break;
      case "description":
        errorMessage = getInvalidDescriptionMessage(value);
        break;
    }
  }

  if (errorMessage) errors[key] = errorMessage;
  if (!errorMessage) delete errors[key];

  return { state, errors };
};

const validationConditionToName = {
  "===": "same as",
  "!==": "different than",
  "<": "less than",
  "<=": "less than or same as",
  ">": "greater than",
  ">=": "greater than or same as",
};

/**
 * This function handles comparison based validation for input fields
 * Works by looping through the list of validators to check if it needs to:
 * - Compare with other input fields based on condition
 * - Compare with the sent value against what is entered in the field
 */
export const updateAndCompare = (
  key: InputKeyType,
  state: InputStateType,
  errors: InputErrorType,
  value?: InputValueType,
  stateKeyValidators?: ValidationType[],
) => {
  if (
    !stateKeyValidators ||
    stateKeyValidators.length === 0 ||
    !value ||
    !!errors[key]
  ) {
    return { state, errors };
  }

  const stateKeyLabel = inputKeyToLabel[key];

  let errorMessage = "";
  for (let i = 0; i < stateKeyValidators.length; i++) {
    const {
      key: validatorKey,
      value: validatorValue,
      condition: validatorCondition,
    } = stateKeyValidators[i];

    const validatorConditionName =
      validationConditionToName[validatorCondition];

    if (!!validatorKey) {
      const validatorKeyLabel = inputKeyToLabel[validatorKey];
      const validatorKeyValue = state[validatorKey];

      // Prompt user to enter the value for dependent field as it is not entered yet
      if (!validatorKeyValue) {
        errorMessage = `Please enter ${validatorKeyLabel} before ${stateKeyLabel}`;
        break;
      }

      // Prompt user to enter value matching condition for the dependent field
      if (!!validatorKeyValue) {
        const leftHandSide =
          typeof value === "string" && /\d/.test(value) ? +value : value;
        const rightHandSide =
          typeof validatorKeyValue === "string" && /\d/.test(validatorKeyValue)
            ? +validatorKeyValue
            : validatorKeyValue;
        if (!eval(`${leftHandSide} ${validatorCondition} ${rightHandSide}`)) {
          errorMessage = `${stateKeyLabel} should be ${validatorConditionName} ${validatorKeyLabel}`;
          break;
        }
      }
    }

    // Prompt user to enter value matching condition based on the sent value
    if (!!validatorValue) {
      const leftHandSide =
        typeof value === "string" && /\d/.test(value) ? +value : value;
      const rightHandSide =
        typeof validatorValue === "string" && /\d/.test(validatorValue)
          ? +validatorValue
          : validatorValue;
      if (!eval(`${leftHandSide} ${validatorCondition} ${rightHandSide}`)) {
        errorMessage = `${stateKeyLabel} should be ${validatorConditionName} ${validatorValue}`;
        break;
      }
      break;
    }
  }

  if (errorMessage) errors[key] = errorMessage;
  if (!errorMessage) delete errors[key];

  return { state, errors };
};
