import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { InputOptionType } from "./types";

export const getInvalidRadioMessage = (
  value: string,
  options: InputOptionType[],
) => {
  if (options.find((option) => option.value === value)) {
    return "";
  }

  return "Please select a valid option";
};

type RadioOptionProps = {
  id?: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  description?: string;
  required?: boolean;
  onChange: (value: string) => void;
};

/**
 *  Allows user to select a radio option
 */
export const RadioOption = ({
  id,
  value,
  checked,
  disabled,
  required,
  label,
  description,
  onChange,
}: RadioOptionProps) => {
  return (
    <label key={label} className="flex items-center gap-1 cursor-pointer">
      <input
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        type="radio"
        value={value}
        required={required}
        disabled={disabled}
        checked={checked}
        onChange={() => onChange(value)}
        className="text-base font-medium leading-normal cursor-pointer custom-radio"
      />
      <div className="flex flex-col">
        {label && (
          <span>
            {label}
            {required && <span className="text-brand">{" (Required)"}</span>}
          </span>
        )}
        {description && (
          <span className="text-base font-light tracking-tight text-tertiaryText">
            {description}
          </span>
        )}
      </div>
    </label>
  );
};
