import { useEffect } from "react";

import classNames from "classnames";
import { Tab } from "@headlessui/react";

import { useSearchParams } from "../hooks/useSearchParams";

import { camelCaseToSnakeCase } from "../utils/textFormatter";

/**
 * This component shows two tabs to select like a toggle, expecting labels as params
 * On toggling, the label is set in search-params as ?type=
 */
export const ActiveTabs = ({
  primaryLabel = "Active",
  secondaryLabel = "Inactive",
}: {
  primaryLabel?: string;
  secondaryLabel?: string;
}) => {
  const { urlSearchParams } = useSearchParams();

  const labels = [primaryLabel, secondaryLabel];

  const type = urlSearchParams.get("type");
  useEffect(() => {
    if (!type) {
      urlSearchParams.set("type", primaryLabel);
    }
  }, [type]);

  return (
    <div className="flex items-center w-full max-w-xs">
      <Tab.Group
        selectedIndex={type === secondaryLabel ? 1 : 0}
        onChange={(index) => {
          const newLabel = labels[index];
          if (newLabel) {
            urlSearchParams.set("type", newLabel);
          }
        }}
      >
        <Tab.List className="flex w-full p-1 space-x-1 border shadow-md rounded-xl bg-background">
          {labels.map((label) => (
            <Tab
              key={label}
              data-testid={`${camelCaseToSnakeCase(label)}_TAB`}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-brand focus:outline-none focus:ring-2",
                  {
                    "bg-white text-primaryText shadow": selected,
                    "text-tertiaryText/80 hover:bg-white/60 hover:text-tertiaryText":
                      !selected,
                  },
                )
              }
            >
              {label}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};
