import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Loading } from "../Loading";

/**
 * Allow only signed in user to see a route
 *
 * Arguments:
 *    - path: the url slug of the protected path
 *    - component: the component that will render for the above path
 * If the user is not logged in, they will be redirected to a login page
 * and after successful login they will see the destination path
 */
export const ProtectedRoute = ({
  component,
  ...args
}: RouteProps & { component: React.ComponentType<any> }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <Loading
          className="flex justify-center p-4 font-semibold bg-background min-h-page"
          description="Loading"
        />
      ),
    })}
    {...args}
  />
);
