import { useEffect } from "react";

import { useInstanceDelete } from "../../state/instance";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { DeleteIcon } from "../icons/DeleteIcon";

import { Instance } from "../../types/instance";

const getInformation = (instance: Instance) => {
  return [
    {
      label: "Account ID",
      value: instance.accountId,
    },
  ];
};

interface DeleteInstanceModalContentProps {
  onClose: () => void;
  instance: Instance;
}

/**
 * This component shows content related to deleting a instance
 * Giving you title, description and buttons for action
 */
export const DeleteInstanceModalContent = ({
  onClose,
  instance,
}: DeleteInstanceModalContentProps) => {
  const { deleteInstance, isSuccess } = useInstanceDelete();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="p-3 mb-1 rounded-full bg-negative bg-opacity-20 w-fit">
          <DeleteIcon className="text-negative" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="DELETE_INSTANCE_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Delete Instance
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        Are you sure you want to delete this instance?
      </span>
      <div className="py-4 text-secondaryText">
        <span>
          This action cannot be undone.{" "}
          <span className="font-semibold underline">
            This will permanently delete this instance and your data will not be
            recoverable.
          </span>
        </span>
      </div>
      <div className="flex justify-between p-2 mt-2 mb-6 border rounded-lg bg-background">
        {getInformation(instance).map((information) => (
          <div key={information.label} className="flex flex-col p-1">
            <span className="text-sm leading-none text-secondaryText">
              {information.label}
            </span>
            <span className="pt-1 text-base font-medium leading-normal">
              {information.value}
            </span>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-4 pb-1">
        <Button
          data-testid="CANCEL_BUTTON"
          type="button"
          variant="transparent"
          variantClassName="w-1/2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="DELETE_INSTANCE_BUTTON"
          type="button"
          variant="red"
          variantClassName="w-1/2"
          onClick={() => deleteInstance({ id: instance.id })}
        >
          Delete Instance
        </Button>
      </div>
    </>
  );
};
