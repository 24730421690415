import { Link } from "react-router-dom";

import { Button } from "../LegacyButton";

export const InvalidRoute = () => {
  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div className="flex flex-col items-center justify-center gap-3 p-4 my-3 bg-white border rounded-lg shadow-md">
        <span>You have opened invalid/expired link!</span>
        <Link to="/">
          <Button variant="transparent" variantClassName="min-w-[180px]">
            Redirect to Home
          </Button>
        </Link>
      </div>
    </div>
  );
};
