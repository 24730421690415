import { ProfileMenu } from "./ProfileMenu";
import { LoginButton } from "../auth/LoginButton";

import { useGetAccountBalance, useGetAccountInfo } from "../../state/account";

import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";
import { DropdownSelector } from "../forms/DropdownSelector";

import { User } from "../../types/user";

type AuthenticatedProfileContainerProps = {
  user: User;
  userPicture?: string;
  accountId: string;
  setAccountId: (accountId: string) => void;
};

const AuthenticatedProfileContainer = ({
  user,
  userPicture,
  accountId,
  setAccountId,
}: AuthenticatedProfileContainerProps) => {
  const { data: balance, isLoading: isBalanceLoading } =
    useGetAccountBalance(accountId);

  const accountInfo = user.accountIds.map((id) => {
    return useGetAccountInfo(id);
  });

  const isLoadingAccount = accountInfo.some((info) => info.isLoading);

  const onChange = (value: string) => {
    setAccountId(value);
  };
  return (
    <>
      {!isBalanceLoading && (
        <span
          data-testid="USER_BALANCE_CONTAINER"
          className="items-center justify-center hidden px-3 text-sm font-normal tracking-wide lg:flex text-secondaryText"
        >
          Balance:
          <span className="ml-1 font-semibold text-primaryText">
            {formatMoneyStringWithSign(balance ?? 0)}
          </span>
        </span>
      )}

      <ProfileMenu
        user={user}
        userPicture={userPicture}
        accountId={accountId}
      />

      {user.accountIds.length > 1 && !isLoadingAccount && (
        <div className="lg:flex">
          <span
            data-testid="USER_BALANCE_CONTAINER"
            className="items-center justify-center hidden px-3 text-sm font-normal tracking-wide lg:flex text-secondaryText"
          >
            Account Select
          </span>
          <DropdownSelector
            id="accountSelect"
            value={accountId}
            options={user.accountIds.map((id) => {
              const account = accountInfo.find((info) => info.data?.id === id);
              return {
                label: account
                  ? account?.data?.company || "Unknown"
                  : "Unknown",
                value: id,
              };
            })}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

type ProfileContainerProps = {
  user?: User;
  userPicture?: string;
  accountId: string;
  setAccountId: (accountId: string) => void;
};

/**
 * This component shows the profile and deals with Login/Logout after check the state with Auth0
 * This container is primary introduced to be used in navbar
 */
export const ProfileContainer = ({
  user,
  userPicture,
  accountId,
  setAccountId,
}: ProfileContainerProps) => {
  if (user) {
    return (
      <AuthenticatedProfileContainer
        user={user}
        userPicture={userPicture}
        accountId={accountId}
        setAccountId={setAccountId}
      />
    );
  }

  return <LoginButton />;
};
