import classNames from "classnames";

export const CircularCheckIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-5 h-5")}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4619_31304)">
      <path
        d="M6.25033 10.0001L8.75033 12.5001L13.7503 7.50008M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4619_31304">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
