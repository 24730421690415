import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "./queryClient";

import { genericCacheHandler } from "../utils/cacheHandler";

import {
  User,
  ChangeUserNameRequest,
  ChangeUserTypeRequest,
  UserNotificationPreference,
} from "../types/user";

export enum UserKey {
  /** query key to fetch all users for admin */
  USER_LIST_ALL = "user-list-all",

  /** query key to get user info */
  USER_INFO = "user-info",

  /** query key to get user notification preference */
  USER_NOTIFICATION_PREFERENCE = "user-notification-preference",

  /** query key to download all users */
  DOWNLOAD_USER_LIST = "download-user-list",

  /** change user name */
  CHANGE_USER_NAME = "change-user-name",

  /** change user type */
  CHANGE_USER_TYPE = "change-user-type",

  /** change user notification preference */
  CHANGE_USER_NOTIFICATION_PREFERENCE = "change-user-notification-preference",
}

/** retrieve the list of users from the server */
const getUsers = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/user`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const users: User[] | undefined = response.data?.users?.map((item: any) => {
    return {
      id: item.id,
      accountIds: item.accountIDs,
      name: item.name,
      email: item.email,
      type: item.type,
      company: item.company ?? "",
    };
  });
  return { data: users || [] };
};

/** retrieve the list of users */
export const useGetUsers = () => {
  return useQuery<{ data: User[] }, Error>([UserKey.USER_LIST_ALL], getUsers);
};

/** retrieve the user info from the server */
const getUserInfo = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/user/info`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const user: User = {
    id: response.data?.id,
    accountIds: response.data?.accountIDs,
    email: response.data?.email,
    type: response.data?.type,
    name: response.data?.name,
  };
  return user;
};

/**
 * !!! DO NOT USE unless you are calling it from AuthUserProvider !!!
 * if you need the userInfo you can do: const { userInfo } = useAuthentication();
 *
 * Retrieves the user info from the backend
 */
export const useGetUserInfo = () => {
  return useQuery<User, Error>([UserKey.USER_INFO], () => getUserInfo(), {
    enabled: false,
  });
};

/** retrieve the user notification preference from the server */
const getUserNotificationPreference = async (userId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/user/${userId}/notifications`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const notifications = response.data?.notifications || [];
  const convertedResponse = notifications.reduce(
    (
      object: UserNotificationPreference,
      item: keyof UserNotificationPreference,
    ) => ((object[item] = true), object),
    {},
  );

  return {
    notificationPreference: convertedResponse,
    email: response.data?.email,
  };
};

/**
/** Retrieves the user notification preference from the backend */
export const useGetUserNotificationPreference = (userId: string) => {
  return useQuery<
    { notificationPreference: UserNotificationPreference; email?: string },
    Error
  >(
    [UserKey.USER_NOTIFICATION_PREFERENCE],
    () => getUserNotificationPreference(userId),
    /**
     * NOTE: by default react-query retries 3 times on error
     * leaving status === 'loading' & error === null until it fails on the third attempt
     * So, if user has opened unsubscribe?user={userToken}, turn off retry and show invalid route
     */
    userId ? { retry: false } : {},
  );
};

/** Send a change user company request to the server */
const changeUserName = async (params: ChangeUserNameRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/user/${params.id}`;
  await axios.patch(url, params);
  return params;
};

/** Change user Name */
export const useChangeUserName = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    ChangeUserNameRequest,
    Error,
    ChangeUserNameRequest
  >([UserKey.CHANGE_USER_NAME], changeUserName);
  return { changeUserName: mutate, isError, isSuccess, isLoading };
};

/** Send a change user type request to the server */
const changeUserType = async (params: ChangeUserTypeRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/user/${params.id}`;
  await axios.patch(url, params);
  return params;
};

/** Change user type */
export const useChangeUserType = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    ChangeUserTypeRequest,
    Error,
    ChangeUserTypeRequest
  >([UserKey.CHANGE_USER_TYPE], changeUserType);
  return { changeUserType: mutate, isError, isSuccess, isLoading };
};

/** Send a change user preference request to the server */
const changeUserNotificationPreference = async (
  params: UserNotificationPreference,
  userId: string,
) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/user/${userId}/notifications`;

  const notifications: string[] = [];
  let key: keyof UserNotificationPreference;
  for (key in params) {
    if (params[key]) notifications.push(key);
  }
  await axios.post(url, { notifications: notifications });

  return params;
};

/** Change user preference */
export const useChangeUserNotificationPreference = (userToken: string) => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UserNotificationPreference,
    Error,
    UserNotificationPreference
  >(
    [UserKey.CHANGE_USER_NOTIFICATION_PREFERENCE],
    (params) => changeUserNotificationPreference(params, userToken),
    {
      onSuccess: (request) => {
        genericCacheHandler<UserNotificationPreference>(
          [UserKey.USER_NOTIFICATION_PREFERENCE],
          request,
        );
      },
    },
  );
  return {
    changeUserNotificationPreference: mutate,
    isError,
    isSuccess,
    isLoading,
  };
};

/** Refetch users list */
export const refetchUsers = (userId: string, canBeRedirectedToHome = false) => {
  const userInfo: User | undefined = queryClient.getQueryData([
    UserKey.USER_INFO,
  ]);
  const users: { data: User[] } | undefined = queryClient.getQueryData([
    UserKey.USER_LIST_ALL,
  ]);

  if (users?.data?.find((user) => user.id === userId)) {
    if (userInfo?.id === userId && canBeRedirectedToHome) {
      window.location.href = "/";
      return;
    }
    queryClient.invalidateQueries([UserKey.USER_LIST_ALL]);
  }
};

/** Refetch user info */
export const refetchUserInfo = (userId: string) => {
  const userInfo: User | undefined = queryClient.getQueryData([
    UserKey.USER_INFO,
  ]);

  if (userId && userInfo?.id === userId) {
    // refetch user info from the database.
    // special handling due to the fact that useQuery has the flag enabled:false
    queryClient.fetchQuery<User | undefined, Error>(
      [UserKey.USER_INFO],
      () => getUserInfo(),
      { staleTime: 0 },
    );
  }
};

/** Clear user info */
export const clearUserInfo = () => {
  queryClient.removeQueries([UserKey.USER_INFO]);
};
