import classNames from "classnames";

export const AddUserIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-5 h-5")}
    viewBox="0 0 20 20"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 12.9167H6.25009C5.08711 12.9167 4.50563 12.9167 4.03246 13.0602C2.96713 13.3834 2.13345 14.217 1.81028 15.2824C1.66675 15.7555 1.66675 16.337 1.66675 17.5M15.8334 17.5V12.5M13.3334 15H18.3334M12.0834 6.25C12.0834 8.32107 10.4045 10 8.33342 10C6.26235 10 4.58341 8.32107 4.58341 6.25C4.58341 4.17893 6.26235 2.5 8.33342 2.5C10.4045 2.5 12.0834 4.17893 12.0834 6.25Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
