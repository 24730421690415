/**
 * This function returns "FirstName L." ie. first name followed by initials of last name
 */
export const getFirstNameAndLastNameInitial = (fullName: string) => {
  // Split the full name into words
  const words = fullName.split(" ");

  if (words.length === 1) {
    // If there's only one word, assume it's the first name
    return words[0];
  }
  // Extract the first name and the initial of the last name
  const firstName = words[0];
  const lastNameInitial = words[words.length - 1].charAt(0);

  return `${firstName} ${lastNameInitial}.`;
};

/**
 * Convert lowerCamelCase to UPPER_SNAKE_CASE
 */
export const camelCaseToSnakeCase = (value: string) =>
  value
    .replace(/\s/g, "")
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .toUpperCase();
