import axios from "axios";

import { useNotifications } from "../context/notification";
import { useAuthentication } from "../Components/auth/AuthUserProvider";

import { DEFAULT_RETRY_DELAY } from "../state/queryClient";

export const TokenConfig = {
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
};

/**
 *  Adds Axios interceptors:
 * - request interceptor which add Auth0 bearer token
 * - response interceptor which add error handler
 */
export const AxiosInterceptor = () => {
  const { getAccessToken } = useAuthentication();

  const { variants, notification, setNotification, clearNotification } =
    useNotifications();

  const handleError = (error: any) => {
    const errorMessage = error?.message ?? "";

    let errorData = error?.response?.data ?? "";
    let errorStatus = error?.response?.status ?? "";

    if (!errorData && !errorMessage) {
      return;
    }

    if (typeof errorData === "object") {
      if (errorData.status) errorStatus = errorData.status;
      if (errorData.message) errorData = errorData.message;
    }

    let message =
      (errorStatus ? `[${errorStatus}] ` : "") + errorData ?? errorMessage;

    if (message) {
      message = "Error: " + message;
    } else {
      if (navigator.onLine) {
        message = "Error: Something went wrong. Please try again!";
      } else {
        message =
          "Error: You appear to be offline. Please check your internet connection!";
      }
    }

    if (notification?.message === message) {
      clearNotification();
    }

    setNotification({
      variant: variants.error,
      message: message,
    });
    setTimeout(() => clearNotification(), DEFAULT_RETRY_DELAY);
  };

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      if (config?.headers && config?.method?.toLowerCase() === "post") {
        config.headers["Content-Type"] = "application/json";
      }
      return getAccessToken()
        .then((token) => {
          if (token && config?.headers) {
            config.headers.authorization = `Bearer ${token}`;
          }
          return config;
        })
        .catch(() => {
          return config;
        });
    },
    (error) => {
      Promise.reject(error);
    },
  );

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      handleError(error);
      throw error;
    },
  );

  return <></>;
};
