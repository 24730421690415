/**
 * This component shows the Login button for Auth0 and redirects back to the page
 */
import { Button } from "../LegacyButton";
import { useAuthentication } from "./AuthUserProvider";

export const LoginButton = () => {
  const { login } = useAuthentication();

  return (
    <Button
      data-testid="LOGIN_BUTTON"
      variant="transparent"
      variantClassName="ml-4 mr-2 text-sm min-w-[85px]"
      onClick={() => login()}
    >
      Login
    </Button>
  );
};
