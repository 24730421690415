import { useEffect, useState } from "react";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { AddUserIcon } from "../icons/AddUserIcon";

import { useInviteAccountUser } from "../../state/account";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";
import { DropdownSelector } from "../forms/DropdownSelector";

const keyToInput: InputKeyToInputType = {
  userEmail: "email",
};

const requiredKey: RequiredInputKeyType = {
  userEmail: true,
};

const originalState = {
  userEmail: "",
  role: "collaborator",
};

interface InviteAccountUserModalContentProps {
  onClose: () => void;
  accountId: string;
}

/**
 * This component shows content related to inviting a user to an account
 * Giving you title and buttons for action
 * And Inputs to accept email and type
 */
export const InviteAccountUserModalContent = ({
  onClose,
  accountId,
}: InviteAccountUserModalContentProps) => {
  const { inviteAccountUser, isSuccess } = useInviteAccountUser();

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onRoleChange = (role: string) => {
    setState({ ...state, role });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    inviteAccountUser({
      id: accountId,
      email: state.userEmail,
      role: state.role,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="p-3 rounded-full bg-brand bg-opacity-20 w-fit">
          <AddUserIcon className="text-brand" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="INVITE_USER_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Invite Team Member
      </h1>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col pt-2 pb-6 tracking-tight text-left ">
          <div className="grid grid-cols-2 gap-4">
            <GenericInputs
              state={state}
              originalState={originalState}
              inputs={keyToInput}
              required={requiredKey}
              errors={errors}
              onChange={onChange}
            />
            <div className="relative height-1/2">
              <DropdownSelector
                id="role-selector"
                value={state.role}
                required={true}
                options={[
                  {
                    value: "collaborator",
                    label: "Collaborator",
                  },
                  {
                    value: "account_admin",
                    label: "Admin",
                  },
                ]}
                extraCss=" absolute bottom-0 right-0" // i do this because i am no good at tailwind, css and i bring shame to my family
                onChange={onRoleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center gap-4 pb-1">
          <Button
            data-testid="CANCEL_BUTTON"
            type="button"
            variant="transparent"
            variantClassName="w-1/2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="INVITE_USER_BUTTON"
            type="submit"
            variant="dark"
            variantClassName="w-1/2"
            disabled={!isSubmitButtonEnabled}
          >
            Invite
          </Button>
        </div>
      </form>
    </>
  );
};
