import { CompaniesPanel } from "./CompaniesPanel";

/**
 * This component shows the full detail of the company page
 * It mainly shows the company information
 * Deals with creating company, updating and deleting it
 */
export const CompanyContainer = () => {
  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col p-4 pt-0">
          <div>
            <h1 className="pb-1 text-xl font-semibold leading-7">
              Company Info
            </h1>
            <span className="text-base font-normal leading-tight text-secondaryText">
              Update your company personal details and add team members.
            </span>
          </div>
          <CompaniesPanel />
        </div>
      </div>
    </div>
  );
};
