import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import classNames from "classnames";
import {
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  getExpandedRowModel,
  Row,
} from "@tanstack/react-table";

import { Loading } from "../Loading";

import { RowExpandIcon } from "../table/RowExpandIcon";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { AccountBalanceDetailsTable } from "./AccountBalanceDetailsTable";

import { useGetAccountBalances } from "../../state/account";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";

import { DashboardPathnames } from "../../pages/Dashboard";

import { BalanceHistory } from "../../types/account";

const columnHelper = createColumnHelper<BalanceHistory>();
const primaryColumns = [
  columnHelper.accessor("id", {
    id: "transactionId",
    header: "Transaction ID",
    cell: ({ cell }) => (
      <span className="font-semibold">{cell.getValue()}</span>
    ),
  }),
  columnHelper.accessor("createdAt", {
    id: "balanceTimestamp",
    header: "Timestamp",
    cell: ({ cell }) => {
      const date = cell.getValue();
      if (!date) {
        return "-";
      }
      return <span>{moment(date).format("L LT")}</span>;
    },
  }),
  columnHelper.accessor("type", {
    id: "transactionType",
    header: "Type",
    cell: ({ cell }) => cell.getValue(),
  }),
];
const secondaryColumns = [
  columnHelper.accessor("adminId", {
    id: "adminId",
    header: "Admin ID",
    cell: ({ cell }) => {
      const adminId = cell.getValue();
      if (!adminId) {
        return <span className="font-semibold">-</span>;
      }
      return (
        <Link
          to={`${DashboardPathnames.users}?search=${adminId}`}
          className="font-semibold hover:underline"
        >
          {adminId}
        </Link>
      );
    },
  }),
  columnHelper.accessor("updatedAmount", {
    id: "updatedAmount",
    header: "Change",
    cell: ({ cell }) => (
      <span
        className={classNames({
          "text-positive": cell.getValue() > 0,
          "text-negative": cell.getValue() <= 0,
        })}
      >
        {formatMoneyStringWithSign(cell.getValue(), { showPositiveSign: true })}
      </span>
    ),
  }),
  columnHelper.accessor("totalAmount", {
    id: "totalAmount",
    header: "Balance",
    cell: ({ cell }) => (
      <span className="text-tertiaryText">
        {formatMoneyStringWithSign(cell.getValue())}
      </span>
    ),
  }),
];

const DefaultData: BalanceHistory[] = [];

/**
 * This component show account balance history table
 * We use this table mostly as sub-component under Admin Accounts table
 */
export const AccountBalanceHistoriesTable = ({
  isAdmin = false,
  accountId,
}: {
  isAdmin?: boolean;
  accountId: string;
}) => {
  const { data, isLoading } = useGetAccountBalances(accountId);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "balanceTimestamp", desc: true },
  ]);

  const memoColumns = useMemo(
    () => [
      ...primaryColumns,
      columnHelper.accessor("orderId", {
        id: "orderId",
        header: "Order ID",
        cell: ({ cell }) => {
          const orderId = cell.getValue();
          if (!orderId) {
            return <span className="font-semibold">-</span>;
          }

          return (
            <Link
              to={`${
                isAdmin ? DashboardPathnames.orders : "/orders"
              }?orderId=${orderId}`}
              className="font-semibold hover:underline"
            >
              {orderId}
            </Link>
          );
        },
      }),
      ...secondaryColumns,
      {
        id: "expander",
        cell: ({ row }: { row: Row<BalanceHistory> }) => {
          if (row.original.type !== "gpus") {
            return <></>;
          }
          return <RowExpandIcon row={row} />;
        },
      },
    ],
    [data?.data, isAdmin],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,
    state: {
      sorting,
      columnVisibility: {
        transactionId: !isAdmin,
        transactionType: !isAdmin,
        adminId: isAdmin,
        totalAmount: isAdmin,
      },
    },
    getCoreRowModel: getCoreRowModel(),

    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),

    enableSorting: !isAdmin,
    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const getRowData = (cell: Cell<BalanceHistory, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const getRowActions = (row: Row<BalanceHistory>, isSubComponent = false) => {
    return {
      onClick: () => {
        if (
          isSubComponent ||
          !row.getCanExpand() ||
          row.original.type !== "gpus"
        ) {
          return;
        }
        if (!row.getIsExpanded()) {
          table.resetExpanded();
        }
        row.toggleExpanded();
      },
    };
  };

  if (isAdmin && isLoading) {
    return (
      <Loading
        className="flex justify-center p-6 m-2 font-semibold bg-white border rounded-lg shadow-md"
        description="Loading"
      />
    );
  }

  return (
    <div
      className={classNames("flex", {
        "m-2 bg-white border rounded-lg shadow-md": isAdmin,
        "mt-3": !isAdmin,
      })}
    >
      <div
        className={classNames("w-full", {
          "p-6": isAdmin,
        })}
      >
        {isAdmin && !!data && (
          <h3 className="pb-2 text-base font-semibold leading-normal">
            {`Total Transactions: ${data.data.length}`}
          </h3>
        )}
        <div
          className={classNames("relative rounded-lg shadow-md", {
            "max-h-[20rem] overflow-x-auto": isAdmin,
          })}
        >
          <table className="w-full text-sm text-left">
            {/* Table column names */}
            <thead className="border select-none whitespace-nowrap bg-background">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} scope="col" className="p-3 group">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <div
                          className={classNames("flex items-center w-fit", {
                            "cursor-pointer select-none mr-2.5":
                              header.column.getCanSort(),
                            "text-brand": header.column.getIsSorted(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ColumnHeaderIcon column={header.column} />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table data */}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.original.id}>
                  <tr
                    data-testid={`${row.original.id}_ACCOUNT_BALANCE_ROW`}
                    className={classNames("border group", {
                      "font-semibold": isAdmin,
                      "hover:cursor-pointer": row.original.type === "gpus",
                      "bg-white": !row.getIsExpanded(),
                      "hover:bg-background":
                        !row.getIsExpanded() && row.original.type === "gpus",
                      "bg-background": row.getIsExpanded(),
                    })}
                    {...getRowActions(row)}
                  >
                    {row.getVisibleCells().map((cell) => getRowData(cell))}
                  </tr>
                  {/* Table Sub Component */}
                  {row.getIsExpanded() && (
                    <tr
                      className={classNames("border", {
                        "bg-white hover:bg-background": !row.getIsExpanded(),
                        "bg-background": row.getIsExpanded(),
                      })}
                      {...getRowActions(row, true)}
                    >
                      <td
                        colSpan={row.getVisibleCells().length}
                        className="p-0"
                      >
                        <AccountBalanceDetailsTable
                          isAdmin={isAdmin}
                          ledgerId={row.original.id}
                        />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
