import { Fragment, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import classNames from "classnames";
import { Tab } from "@headlessui/react";

import { Button } from "../Components/LegacyButton";
import { Header } from "../Components/Header";
import { Loading } from "../Components/Loading";
import { GlobalSearch } from "../Components/GlobalSearch";
import { UsersTable } from "../Components/user/UsersTable";
import { AccountsTable } from "../Components/account/AccountsTable";
import { OrderbookPanel } from "../Components/order/OrderbookPanel";
import { AdminOrdersTable } from "../Components/order/AdminOrdersTable";
import { CreateOrderModal } from "../Components/order/CreateOrderModal";
import { AdminInstancesTable } from "../Components/instance/AdminInstancesTable";

import { DownloadIcon } from "../Components/icons/DownloadIcon";

import { useAuthentication } from "../Components/auth/AuthUserProvider";

import { camelCaseToSnakeCase } from "../utils/textFormatter";

export const DashboardPathnames = {
  orders: "/dashboard/orders",
  instances: "/dashboard/instances",
  accounts: "/dashboard/accounts",
  users: "/dashboard/users",
  inventory: "/dashboard/inventory",
};

export const DashboardLabels = {
  [DashboardPathnames.orders]: "Orders",
  [DashboardPathnames.instances]: "Instances",
  [DashboardPathnames.accounts]: "Accounts",
  [DashboardPathnames.users]: "Users",
  [DashboardPathnames.inventory]: "Inventory",
};

export const DashboardDescriptions = {
  [DashboardPathnames.orders]: "See all orders.",
  [DashboardPathnames.instances]: "See all instances.",
  [DashboardPathnames.accounts]: "See all accounts.",
  [DashboardPathnames.users]: "See all users.",
  [DashboardPathnames.inventory]: "Set the # of GPUs available",
};

const PanelComponent = ({ name }: { name: string }) => {
  switch (name) {
    case DashboardPathnames.orders:
      return <AdminOrdersTable />;
    case DashboardPathnames.instances:
      return <AdminInstancesTable />;
    case DashboardPathnames.accounts:
      return <AccountsTable />;
    case DashboardPathnames.users:
      return <UsersTable />;
    case DashboardPathnames.inventory:
      return <OrderbookPanel />;
  }
};

/**
 * This component handles everything related to:
 * - Title
 * - Tabs
 * - Tabs matching with pathname
 * - Content for the respective tab
 */
const DashboardContent = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const currentTabIndex = Object.values(DashboardPathnames).findIndex(
    (path) => path === pathname,
  );

  const [showModal, setShowModal] = useState<string | null>(null);

  const getSecondaryComponent = (pathname: string) => {
    if (pathname === DashboardPathnames.orders) {
      return (
        <div className="flex gap-1">
          <GlobalSearch />
          <Button
            data-testid="DOWNLOAD_ORDERS_BUTTON"
            variant="transparent"
            variantClassName="w-[175px] h-[20px] mr-3 flex justify-center items-center text-sm self-center"
          >
            <DownloadIcon className="mr-3 text-secondaryText" />
            Download CSV
          </Button>
          <Button
            data-testid="CREATE_ORDER_MODAL_BUTTON"
            type="submit"
            variant="dark"
            variantClassName="w-[175px] h-[20px] mr-3 flex justify-center items-center text-sm self-center"
            onClick={() => setShowModal("create-order-modal")}
          >
            Create Order
          </Button>
          {showModal === "create-order-modal" && (
            <CreateOrderModal
              isOpen={showModal === "create-order-modal"}
              onClose={() => setShowModal(null)}
            />
          )}
        </div>
      );
    }

    if (pathname === DashboardPathnames.instances) {
      return (
        <div className="flex gap-1">
          <GlobalSearch />
        </div>
      );
    }

    // if (pathname === DashboardPathnames.accounts) {
    //   return (
    //     <div className="flex gap-1">
    //       <GlobalSearch />
    //       <Button
    //         data-testid="DOWNLOAD_USERS_BUTTON"
    //         variant="transparent"
    //         variantClassName="w-[175px] h-[20px] mr-3 flex justify-center items-center text-sm self-center"
    //         onClick={() => downloadAccounts("csv")}
    //       >
    //         <DownloadIcon className="mr-3 text-secondaryText" />
    //         Download CSV
    //       </Button>
    //     </div>
    //   );
    // }

    if (pathname === DashboardPathnames.users) {
      return (
        <div className="flex gap-1">
          <GlobalSearch />
          <Button
            data-testid="DOWNLOAD_USERS_BUTTON"
            variant="transparent"
            variantClassName="w-[175px] h-[20px] mr-3 flex justify-center items-center text-sm self-center"
          >
            <DownloadIcon className="mr-3 text-secondaryText" />
            Download CSV
          </Button>
        </div>
      );
    }

    return <></>;
  };

  const getTabsComponent = () => {
    return (
      <Tab.Group
        selectedIndex={currentTabIndex ?? 0}
        onChange={(index) => {
          const newPathname = Object.values(DashboardPathnames)[index];
          if (newPathname) {
            push(newPathname);
          }
        }}
      >
        <Tab.List className="relative flex items-baseline overflow-x-auto bg-white border-t border-opacity-50 md:justify-center border-uiBorder">
          {Object.values(DashboardLabels).map((label) => (
            <Tab key={label} as={Fragment}>
              {({ selected }) => (
                <div
                  className={classNames("p-1 border-b-2", {
                    "border-brand": selected,
                    "border-white hover:border-brandHover": !selected,
                  })}
                >
                  <Button
                    data-testid={`${camelCaseToSnakeCase(label)}_TAB`}
                    textVariant={true}
                    variant={selected ? "brand" : "transparent"}
                    variantClassName={classNames(
                      "h-full truncate text-base md:text-sm",
                      {
                        "font-semibold": selected,
                        "lg:min-w-[170px]": label.includes(" "),
                        "lg:min-w-[130px]": !label.includes(" "),
                      },
                    )}
                  >
                    {label}
                  </Button>
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {Object.values(DashboardPathnames).map((pathname) => (
            <Tab.Panel key={pathname}>
              <Header
                title={`Manage ${DashboardLabels[pathname]}`}
                description={DashboardDescriptions[pathname]}
                secondaryComponent={getSecondaryComponent(pathname)}
              />
              <PanelComponent name={pathname} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    );
  };

  return (
    <div className="pb-4 bg-background min-h-page">
      <div className="flex flex-col">{getTabsComponent()}</div>
    </div>
  );
};

/**
 * This component show the Dashboard Table
 */
export const Dashboard = () => {
  const { userInfo, isLoading } = useAuthentication();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo?.type === "admin") {
    return <DashboardContent />;
  }

  return <Redirect to="/" />;
};
