import { Fragment, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import {
  Row,
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  ColumnFiltersState,
  getExpandedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { NameComponent } from "./NameComponent";
import { RowExpandIcon } from "../table/RowExpandIcon";
import { InstanceInfoComponent } from "./InstanceInfoComponent";
import { OrderStatusComponent } from "../order/OrderStatusComponent";
import { CustomOrderActionComponent } from "../order/CustomOrderActionComponent";

import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";

import { useSearchParams } from "../../hooks/useSearchParams";

import { useGetInstances } from "../../state/instance";

import { columnListFilter } from "../../utils/tableFilter";
import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { sortingFunctionForInstanceStatus } from "../../utils/tableSort";

import { Instance } from "../../types/instance";
import { useAuthentication } from "../auth/AuthUserProvider";

const columnHelper = createColumnHelper<Instance>();
const columns = [
  columnHelper.accessor("name", {
    id: "instanceName",
    header: "Instance Group",
    cell: ({ cell }) => (
      <div
        className="w-[160px]"
        onClick={(event) => event.stopPropagation() /** prevent parent event */}
      >
        <NameComponent instance={cell.row.original} />
      </div>
    ),
  }),
  columnHelper.accessor("numberOfGPUs", {
    id: "numberOfGPUs",
    header: "# of GPUs",
    cell: ({ cell }) => cell.getValue(),
  }),
  columnHelper.accessor("status", {
    id: "instanceStatus",
    header: "Status",
    cell: ({ cell }) => <OrderStatusComponent status={cell.getValue()} />,
    sortingFn: sortingFunctionForInstanceStatus,
    filterFn: columnListFilter,
  }),
];

const DefaultData: Instance[] = [];

const getDefaultColumnFilters = (instanceType?: string | null) => {
  return [
    {
      id: "instanceStatus",
      value:
        instanceType && instanceType.toLowerCase().includes("inactive")
          ? ["finished", "deleted"]
          : ["paused", "active", "closing"],
    },
  ];
};

/**
 * Display the list of users instances
 * This component is the heart of Management Instance page
 * We show the Instance Table here with it's subcomponents to be interacted over
 * This follows exactly the same pattern as OrderTable
 */
export const InstancesTable = () => {
  const { urlSearchParams } = useSearchParams();
  const instanceType = urlSearchParams.get("type");
  const { accountId } = useAuthentication();

  const { data } = useGetInstances(accountId!);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "instanceStatus", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    getDefaultColumnFilters(instanceType),
  );

  // Expand and Scroll to the row if instanceId is set in search params
  useEffect(() => {
    const instanceId = urlSearchParams.get("instanceId");
    if (!data || data.data.length === 0 || !instanceId) return;

    const instanceIndex = data.data.findIndex(
      (instance) => instance.id === instanceId,
    );
    if (instanceIndex < 0) return;

    try {
      const row = table.getRow(instanceIndex.toString());
      if (row && row.getCanExpand() && !row.getIsExpanded()) {
        table.resetExpanded();
        row.toggleExpanded();

        const element = document.getElementById(
          `${instanceIndex}-instance-row`,
        );
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    } catch (_) {
      urlSearchParams.delete("instanceId");
    }
  }, [urlSearchParams, data]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      {
        id: "action",
        header: "Action / Status Date",
        cell: ({ cell }: { cell: Cell<Instance, unknown> }) => (
          <CustomOrderActionComponent
            status={cell.row.original.status}
            startAt={cell.row.original.startAt}
            endAt={cell.row.original.endAt}
          />
        ),
      },
      {
        id: "expander",
        cell: ({ row }: { row: Row<Instance> }) => <RowExpandIcon row={row} />,
      },
    ],
    [data?.data],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,

    state: {
      sorting,
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
  });

  const getRowData = (cell: Cell<Instance, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const getRowActions = (row: Row<Instance>, isSubComponent = false) => {
    return {
      onClick: () => {
        if (isSubComponent || !row.getCanExpand()) {
          return;
        }
        if (!row.getIsExpanded()) {
          table.resetExpanded();
        }
        row.toggleExpanded();
      },
    };
  };

  return (
    <div className="px-4 pt-3 mx-auto sm:px-6 lg:px-8">
      <div className="relative overflow-x-auto rounded-lg shadow-md">
        <table className="w-full text-sm text-left">
          {/* Table column names */}
          <thead className="border select-none whitespace-nowrap">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} scope="col" className="p-3 group">
                    {header.isPlaceholder && <></>}
                    {!header.isPlaceholder && (
                      <div
                        className={classNames("flex items-center w-fit", {
                          "cursor-pointer select-none mr-2.5":
                            header.column.getCanSort(),
                          "text-brand": header.column.getIsSorted(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <ColumnHeaderIcon column={header.column} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Table data */}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.original.id}>
                <tr
                  id={`${row.id}-instance-row`}
                  data-testid={`${row.original.id}_INSTANCE_ROW`}
                  className={classNames("border group hover:cursor-pointer", {
                    "bg-white hover:bg-background":
                      row.original.status !== "finished" &&
                      !row.getIsExpanded(),
                    "bg-background": row.getIsExpanded(),
                    "text-secondaryText bg-uiBorder":
                      row.original.status === "finished",
                  })}
                  {...getRowActions(row)}
                >
                  {row.getVisibleCells().map((cell) => getRowData(cell))}
                </tr>
                {/* Table Sub Component */}
                {row.getIsExpanded() && (
                  <tr
                    className={classNames("border", {
                      "bg-white hover:bg-background":
                        row.original.status !== "finished" &&
                        !row.getIsExpanded(),
                      "bg-background": row.getIsExpanded(),
                      "bg-uiBorder": row.original.status === "finished",
                    })}
                    {...getRowActions(row, true)}
                  >
                    <td colSpan={row.getVisibleCells().length} className="p-0">
                      <InstanceInfoComponent
                        isAdmin={false}
                        instance={row.original}
                        accountId={accountId!}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
