import { useIsFetching } from "@tanstack/react-query";

import classNames from "classnames";

import { Button } from "../LegacyButton";

export type AccountOptionsComponentProps = {
  setShowModal: (type: string) => void;
};

/**
 * This component handles adding balance for the account
 * Used mainly in Admin Accounts Table to update accounts information
 */
export const AccountOptionsComponent = ({
  setShowModal,
}: AccountOptionsComponentProps) => {
  const isFetching = useIsFetching();

  return (
    <div
      className={classNames("flex space-x-2", {
        "pointer-events-none": isFetching,
      })}
    >
      <Button
        data-testid="UPDATE_ACCOUNT_BALANCE_MODAL_BUTTON"
        variant="transparent"
        variantClassName="min-w-[150px]"
        onClick={() => setShowModal("change-balance")}
      >
        Change Balance
      </Button>
    </div>
  );
};
