import { Column } from "@tanstack/react-table";

import classNames from "classnames";

import { TooltipMessage } from "../tooltip/const";
import { TooltipButton } from "../tooltip/TooltipButton";

import { AscendingArrowIcon } from "../icons/AscendingArrowIcon";

export type ColumnHeaderSortIconProps = {
  column: Column<any, unknown>;
};

/** Shows user the Arrow icon based on the current condition */
export const ColumnHeaderSortIcon = ({ column }: ColumnHeaderSortIconProps) => {
  const tooltipId = column.id;

  const isColumnSorted = column.getIsSorted();
  const columnCanSort = column.getCanSort();

  if (!isColumnSorted) {
    if (
      columnCanSort &&
      tooltipId &&
      TooltipMessage[tooltipId as keyof typeof TooltipMessage]
    ) {
      return <TooltipButton id={tooltipId} className="ml-1.5" />;
    }

    return (
      <AscendingArrowIcon
        className={classNames("opacity-0", {
          "group-hover:block group-hover:opacity-40": columnCanSort,
        })}
      />
    );
  }

  if (isColumnSorted === "asc") {
    return <AscendingArrowIcon />;
  }

  if (isColumnSorted === "desc") {
    return <AscendingArrowIcon className="rotate-180" />;
  }

  return <></>;
};
