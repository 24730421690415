import { Fragment, useEffect, useState } from "react";

import classNames from "classnames";
import { Combobox, Transition } from "@headlessui/react";

import { CrossIcon } from "../icons/CrossIcon";
import { FilterArrowIcon } from "../icons/FilterArrowIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

import { InputOptionType } from "./types";

export const getInvalidSearchableMessage = (
  value: string,
  options: InputOptionType[],
) => {
  if (options.find((option) => option.value === value)) {
    return "";
  }

  return "Please select a valid option";
};

/**
 * Bold the characters in a sentence that matched the passed query
 */
const BoldifySentence = ({
  sentence,
  query,
}: {
  sentence: string;
  query: string;
}) => {
  const parenthesisToUnicode = (text: string) =>
    text.replace(/\(/g, "\u200B").replace(/\)/g, "\u200A");
  const unicodeToParenthesis = (text: string) =>
    text.replace(/\u200B/g, "(").replace(/\u200A/g, ")");

  const sanitizedSentence = parenthesisToUnicode(sentence);
  const sanitizedQuery = parenthesisToUnicode(query);

  const normalCharacters = sanitizedSentence.split(
    RegExp(sanitizedQuery, "ig"),
  );
  const matchingCharacters = sanitizedSentence.match(
    RegExp(sanitizedQuery, "ig"),
  );

  return (
    <span>
      {normalCharacters.map((character, index) => (
        <Fragment key={index}>
          {unicodeToParenthesis(character)}
          {index !== normalCharacters.length - 1 && matchingCharacters && (
            <span className="font-semibold">
              {unicodeToParenthesis(matchingCharacters[index])}
            </span>
          )}
        </Fragment>
      ))}
    </span>
  );
};

type SearchableInputProps = {
  id?: string;
  value?: string;
  options: InputOptionType[];
  originalValue?: string;
  required?: boolean;
  isError?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

/**
 *  Allows user to enter searchable text
 */
export const SearchableInput = ({
  id,
  value,
  originalValue,
  required,
  options,
  isError,
  placeholder = "Search text",
  onChange,
}: SearchableInputProps) => {
  const [query, setQuery] = useState("");

  const currentOption =
    options.find((option) => option.value === value) ?? null;

  useEffect(() => {
    const newQuery = currentOption?.label ?? "";
    if (newQuery !== query) setQuery(newQuery);
  }, [currentOption]);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  const isCrossIconVisible = query || value !== originalValue;

  return (
    <Combobox
      nullable={true}
      value={currentOption}
      onChange={(option) => onChange(option?.value ?? "")}
    >
      <div className="w-full">
        <Combobox.Input
          className={classNames(
            "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border pr-8",
            {
              "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
                !isError,
              "border-negative focus:ring-negative focus:ring-offset-negative":
                !!isError,
            },
          )}
          id={id}
          data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
          required={required}
          placeholder={placeholder}
          displayValue={(option: InputOptionType | null) => option?.label ?? ""}
          onChange={(event) => setQuery(event.target.value)}
          onFocus={() => value && onChange(value)}
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer z-10"
          onClick={() => isCrossIconVisible && onChange(originalValue ?? "")}
        >
          {!isCrossIconVisible && <FilterArrowIcon className="text-name" />}
          {isCrossIconVisible && <CrossIcon className="text-name" />}
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white border rounded-md shadow-lg sm:text-sm max-h-36">
            {filteredOptions.length === 0 && (
              <div className="px-4 py-2 text-secondaryText">
                No results match this search!
              </div>
            )}
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className={({ active, selected }) =>
                  classNames("relative cursor-pointer py-2 pl-10 pr-4", {
                    "bg-brandHover text-background": active,
                    "bg-brand text-background": selected,
                    "text-primaryText": !active && !selected,
                  })
                }
              >
                <div className="flex flex-col">
                  {option.label && (
                    <span className="block truncate">
                      <BoldifySentence sentence={option.label} query={query} />
                    </span>
                  )}
                  {option.description && (
                    <span className="text-base font-light tracking-tight truncate text-tertiaryText">
                      {option.description}
                    </span>
                  )}
                </div>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
