import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Header } from "../Components/Header";
import { Loading } from "../Components/Loading";
import { InvalidRoute } from "../Components/auth/InvalidRoute";

import { useGetAccountBillingInfo } from "../state/account";
import { useAuthentication } from "../Components/auth/AuthUserProvider";

/**
 * This component awaits response from BE to redirect to customer portal page
 * It shows Loading while it await and if it failed shows the Invalid Route message
 */
const AddPaymentMethod = ({ accountId }: { accountId: string }) => {
  const {
    data: billingInfo,
    isLoading,
    isError,
  } = useGetAccountBillingInfo(accountId);

  useEffect(() => {
    if (!!billingInfo?.url) {
      window.location.href = billingInfo.url;
    }
  }, [billingInfo]);

  if (isLoading) {
    return (
      <Loading
        className="flex justify-center p-4 font-semibold"
        description="Loading"
      />
    );
  }

  if (isError) {
    return <InvalidRoute />;
  }

  return <></>;
};

/*
 * A page containing int18 CustomerPortal page at /customer-portal.
 */
export const CustomerPortal = () => {
  const { userInfo, isLoading, accountId } = useAuthentication();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo) {
    return (
      <div className="pb-4 bg-background min-h-page">
        <Header
          title="Customer Portal"
          description="Please wait while we redirect you!"
        />
        <div className="p-8 mb-48 lg:py-8 lg:px-60">
          <AddPaymentMethod accountId={accountId!} />
        </div>
      </div>
    );
  }

  return <Redirect to="/" />;
};
