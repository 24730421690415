import { useState } from "react";

import classNames from "classnames";

import { Button } from "../LegacyButton";
import { CrossIcon } from "../icons/CrossIcon";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";

const getMaxLength = (value: number) => (value > 0 ? 3 : 4);

export const getInvalidTimeLengthMessage = (value: string) => {
  if (
    !value ||
    (!Number.isNaN(+value) && value.length <= getMaxLength(+value))
  ) {
    return "";
  }

  if (value.length > 3) {
    return "Days is too long (maximum 3 characters)";
  }

  return "Please enter a valid number";
};

const valueToDisplay = (value?: string) => {
  return value && value.length
    ? `${value} ${+value === 1 ? "Day" : "Days"}`
    : "";
};

interface TimeLengthInputProps {
  id?: string;
  value?: string;
  originalValue?: string;
  required?: boolean;
  isError?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

/*
 * An input responsible for updating and displaying a users order/instance duration
 */
export const TimeLengthInput = ({
  id,
  value,
  originalValue,
  required,
  isError,
  placeholder = "Enter days",
  onChange,
}: TimeLengthInputProps) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <>
      {originalValue && value !== originalValue && (
        <div className="absolute inset-y-0 right-0 flex items-center p-2.5 cursor-pointer">
          <Button type="button" onClick={() => onChange(originalValue)}>
            <CrossIcon className="text-name" />
          </Button>
        </div>
      )}
      <input
        className={classNames(
          "text-base rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 block w-full p-1.5 border",
          {
            "border-uiBorder focus:ring-uiBorder focus:ring-offset-uiBorder":
              !isError,
            "border-negative focus:ring-negative focus:ring-offset-negative":
              isError,
          },
        )}
        required={required}
        id={id}
        data-testid={!!id ? `${camelCaseToSnakeCase(id)}_INPUT` : undefined}
        type="text"
        value={hasFocus ? value : valueToDisplay(value)}
        placeholder={placeholder}
        onChange={(e) =>
          /^[0-9]*$/.test(e.target.value) && onChange(e.target.value)
        }
        onFocus={() => {
          value && onChange(value);
          setHasFocus(true);
        }}
        onBlur={() => setHasFocus(false)}
      />
    </>
  );
};
