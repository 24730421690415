export type UserDetailComponentProps = {
  name?: string;
  email?: string;
};

/**
 * This component returns formatted user information based on the keys sent from the parent
 */
export const UserDetailComponent = ({
  name,
  email,
}: UserDetailComponentProps) => {
  return (
    <div className="flex flex-col truncate">
      <span className="font-semibold">{name || "-"}</span>
      {email && <span className="text-secondaryText">{email}</span>}
    </div>
  );
};
