import classNames from "classnames";

import { Button } from "./LegacyButton";
import { InfoIcon } from "./icons/InfoIcon";
import { CrossIcon } from "./icons/CrossIcon";

import { useNotifications } from "../context/notification";

const variantToStyles = {
  error: "bg-negative",
  info: "bg-background",
  success: "bg-positive",
  warning: "bg-brand",
};

/** Generic Notification header which is visible only when the context is updated */
export const Notification = () => {
  const { notification, clearNotification } = useNotifications();

  if (!notification) {
    return <></>;
  }

  return (
    <nav
      data-testid={`${notification.variant.toUpperCase()}_NOTIFICATION_VIEW`}
      className={classNames(
        "fixed top-0 left-0 z-50 w-full flex px-4 mx-auto sm:px-6 lg:px-8",
        variantToStyles[notification.variant],
      )}
    >
      <div className="flex items-center self-center justify-center w-full h-16">
        <InfoIcon className="text-background" />
        <div className="flex gap-1">
          <span className="text-sm font-medium leading-loose tracking-tight truncate text-background md:text-lg">
            {notification.message}
          </span>
          {notification.link && (
            <a
              href={notification.link}
              data-testid="NOTIFICATION_DETAIL_LINK"
              className="flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-lg bg-white truncate w-fit"
            >
              Explore
            </a>
          )}
        </div>
      </div>
      <Button onClick={() => clearNotification()}>
        <CrossIcon className="hover:scale-110 text-background" />
      </Button>
    </nav>
  );
};
