import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type ConfiguredURLSearchParams<T> = Omit<URLSearchParams, "append" | "sort"> & {
  deleteAll: () => void;
  replaceAll: (newObjectSearchParams: T) => void;
};

type DefaultObjectType = { [k: string]: string };

/**
 * Generic Hook to handle the basic search params action
 * By default in react-route-v5 there is no useSearchParams like v6
 * This hook tries to implement the read-only URLSearchParams
 * and adds custom logic to handle set, replaceAll, delete, deleteAll
 */
export function useSearchParams<T = DefaultObjectType>() {
  const history = useHistory();

  const { search: stringSearchParams } = useLocation();
  const urlSearchParams = useMemo(
    () =>
      new URLSearchParams(
        stringSearchParams,
      ) as unknown as ConfiguredURLSearchParams<T>,
    [stringSearchParams],
  );
  const objectSearchParams = Object.fromEntries(urlSearchParams) as T;

  urlSearchParams.set = (name: string, value: string) => {
    history.replace({
      search: new URLSearchParams({
        ...objectSearchParams,
        [name]: value,
      }).toString(),
    });
  };

  urlSearchParams.replaceAll = (newObjectSearchParams: T) => {
    history.replace({
      search: new URLSearchParams(
        newObjectSearchParams as DefaultObjectType,
      ).toString(),
    });
  };

  urlSearchParams.delete = (name: string) => {
    const newObjectSearchParams = Object.fromEntries(urlSearchParams);
    delete newObjectSearchParams[name];
    history.replace({
      search: new URLSearchParams(newObjectSearchParams).toString(),
    });
  };

  urlSearchParams.deleteAll = () => {
    history.replace({
      search: "",
    });
  };

  return {
    stringSearchParams,
    urlSearchParams,
    objectSearchParams,
  };
}
