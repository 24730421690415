import { Order } from "../types/order";
import { Instance } from "../types/instance";

export const isUnfulfilledOrder = (order: Order) =>
  ["not_ready", "large"].includes(order.status);

export const isFulfillingOrder = (order: Order) =>
  ["eligible", "allocating", "winning", "active", "paused", "closing"].includes(
    order.status,
  );

export const isRunningOrder = (order: Order) =>
  ["active", "closing"].includes(order.status);

export const isExpiredOrder = (order: Order) =>
  ["finished", "deleted"].includes(order.status);

export const isInstance = (instance: Instance) =>
  ["active", "paused", "closing", "finished", "deleted"].includes(
    instance.status,
  );
