import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";
import {
  Row,
  Cell,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  ColumnFiltersState,
  getFacetedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";

import { AccountsModal } from "./AccountsModal";
import { RowExpandIcon } from "../table/RowExpandIcon";
import { ColumnHeaderIcon } from "../table/ColumnHeaderIcon";
import { AccountOptionsComponent } from "./AccountOptionsComponent";
import { AccountBalanceHistoriesTable } from "./AccountBalanceHistoriesTable";

import { useSearchParams } from "../../hooks/useSearchParams";
import { useGetAccounts } from "../../state/account";

import { camelCaseToSnakeCase } from "../../utils/textFormatter";
import { columnListFilter, fuzzyFilter } from "../../utils/tableFilter";
import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";

import { DashboardPathnames } from "../../pages/Dashboard";

import { Account } from "../../types/account";

const columnHelper = createColumnHelper<Account>();
const columns = [
  columnHelper.accessor("id", {
    id: "accountId",
    header: "Account ID",
    cell: ({ cell }) => {
      const accountId = cell.getValue();
      return (
        <Link
          to={`${DashboardPathnames.users}?search=${accountId}`}
          className="font-semibold hover:underline"
        >
          {accountId}
        </Link>
      );
    },
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("company", {
    id: "accountCompany",
    header: "Company",
    cell: ({ cell }) => (
      <span>{!!cell.getValue() ? cell.getValue() : "-"}</span>
    ),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("paymentLimit", {
    id: "accountPaymentLimit",
    header: "Payment Limit",
    cell: ({ cell }) => formatMoneyStringWithSign(cell.getValue() ?? 0),
    filterFn: columnListFilter,
  }),
  columnHelper.accessor("balance", {
    id: "accountBalance",
    header: "Balance",
    cell: ({ cell }) => formatMoneyStringWithSign(cell.getValue() ?? 0),
    filterFn: columnListFilter,
  }),
];

const DefaultData: Account[] = [];

/**
 * Display the list of accounts for admin
 * This component is the heart of Account Dashboard page
 * We show the Accounts Table here with it's subcomponents to be interacted over
 * This follows exactly the same pattern as Instance Table
 */
export const AccountsTable = () => {
  const { urlSearchParams } = useSearchParams();

  const { data } = useGetAccounts();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "accountCompany", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [showModal, setShowModal] = useState<string | null>(null);
  const [currentAccount, setCurrentAccount] = useState<Account | null>(null);

  // Expand and Scroll to the row if accountId is set in search params
  useEffect(() => {
    const search = urlSearchParams.get("search");
    if (search) return;

    const accountId = urlSearchParams.get("accountId");
    if (!data || data.data.length === 0 || !accountId) return;

    const accountIndex = data.data.findIndex(
      (account) => account.id === accountId,
    );
    if (accountIndex < 0) return;

    try {
      const row = table.getRow(accountIndex.toString());
      if (row && row.getCanExpand() && !row.getIsExpanded()) {
        table.resetExpanded();
        row.toggleExpanded();

        const element = document.getElementById(`${accountIndex}-account-row`);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    } catch (_) {
      urlSearchParams.delete("accountId");
    }
  }, [urlSearchParams, data]);

  const memoColumns = useMemo(
    () => [
      ...columns,
      {
        id: "options",
        header: "Actions",
        cell: ({ cell }: { cell: Cell<Account, undefined> }) => (
          <div
            onClick={
              (event) => event.stopPropagation() /** prevent parent event */
            }
          >
            <AccountOptionsComponent
              setShowModal={(modalType) => {
                setShowModal(modalType);
                setCurrentAccount(cell.row.original);
              }}
            />
          </div>
        ),
      },
      {
        id: "expander",
        cell: ({ row }: { row: Row<Account> }) => <RowExpandIcon row={row} />,
      },
    ],
    [data?.data],
  );

  const table = useReactTable({
    data: data?.data ?? DefaultData,
    columns: memoColumns,
    filterFns: { fuzzy: fuzzyFilter },

    state: {
      sorting,
      globalFilter: urlSearchParams.get("search") || "",
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),

    enableSortingRemoval: false,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: (value) => urlSearchParams.set("search", value),
    onColumnFiltersChange: setColumnFilters,

    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const getRowData = (cell: Cell<Account, unknown>) => {
    return (
      <td
        data-testid={`${cell.row.original.id}_${camelCaseToSnakeCase(
          cell.column.id,
        )}`}
        key={cell.id}
        className="p-3 whitespace-nowrap"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const getRowActions = (row: Row<Account>, isSubComponent = false) => {
    return {
      onClick: () => {
        if (isSubComponent || !row.getCanExpand()) {
          return;
        }
        if (!row.getIsExpanded()) {
          table.resetExpanded();
        }
        row.toggleExpanded();
      },
    };
  };

  return (
    <>
      <div className="px-4 pt-3 mx-auto sm:px-6 lg:px-8">
        <div className="relative overflow-x-auto rounded-lg shadow-md">
          <table className="w-full text-sm text-left">
            {/* Table column names */}
            <thead className="border select-none whitespace-nowrap">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} scope="col" className="p-3 group">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <div
                          className={classNames("flex items-center w-fit", {
                            "cursor-pointer select-none mr-2.5":
                              header.column.getCanSort(),
                            "text-brand": header.column.getIsSorted(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ColumnHeaderIcon column={header.column} />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table data */}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.original.id}>
                  <tr
                    id={`${row.id}-account-row`}
                    data-testid={`${row.original.id}_ACCOUNT_ROW`}
                    className={classNames("border group hover:cursor-pointer", {
                      "bg-white hover:bg-background": !row.getIsExpanded(),
                      "bg-background": row.getIsExpanded(),
                    })}
                    {...getRowActions(row)}
                  >
                    {row.getVisibleCells().map((cell) => getRowData(cell))}
                  </tr>
                  {/* Table Sub Component */}
                  {row.getIsExpanded() && (
                    <tr
                      className={classNames("border", {
                        "bg-white hover:bg-background": !row.getIsExpanded(),
                        "bg-background": row.getIsExpanded(),
                      })}
                      {...getRowActions(row, true)}
                    >
                      <td
                        colSpan={row.getVisibleCells().length}
                        className="p-0"
                      >
                        <AccountBalanceHistoriesTable
                          isAdmin={true}
                          accountId={row.original.id}
                        />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {!!currentAccount && (
        <AccountsModal
          isOpen={showModal === "change-balance"}
          onClose={() => {
            setShowModal(null);
            setCurrentAccount(null);
          }}
          modalName={showModal}
          account={currentAccount}
        />
      )}
    </>
  );
};
