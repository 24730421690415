import { useEffect } from "react";

import { Button } from "../LegacyButton";

import { CloseIcon } from "../icons/CloseIcon";
import { DeleteIcon } from "../icons/DeleteIcon";

import { useDeleteAccountUser } from "../../state/account";

import { AccountUser } from "../../types/account";

interface DeleteAccountUserModalContentProps {
  onClose: () => void;
  accountId: string;
  user: AccountUser;
}

/**
 * This component shows content related to deleting an account user
 * Giving you title, description and buttons for action
 */
export const DeleteAccountUserModalContent = ({
  onClose,
  accountId,
  user,
}: DeleteAccountUserModalContentProps) => {
  const { deleteAccountUser, isSuccess } = useDeleteAccountUser();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex items-center justify-between mb-1">
        <div className="p-3 rounded-full bg-negative bg-opacity-20 w-fit">
          <DeleteIcon className="text-negative" />
        </div>
        <Button data-testid="CLOSE_MODAL_BUTTON">
          <CloseIcon className="text-name hover:scale-110" />
        </Button>
      </div>
      <h1
        data-testid="DELETE_ACCOUNT_USER_MODAL_HEADER"
        className="text-xl font-semibold leading-10 tracking-wide"
      >
        Delete Team Member
      </h1>
      <span className="font-normal leading-tight text-secondaryText">
        {`Are you sure you want to delete ${user.name}(${user.email})?`}
      </span>
      <div className="py-4 text-secondaryText">
        <span>
          This action cannot be undone.{" "}
          <span className="font-semibold underline">
            This will permanently delete this team member.
          </span>
        </span>
      </div>
      <div className="flex items-center justify-center gap-4 pb-1">
        <Button
          data-testid="CANCEL_BUTTON"
          type="button"
          variant="transparent"
          variantClassName="w-1/2"
          // onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="DELETE_ACCOUNT_USER_BUTTON"
          type="button"
          variant="red"
          variantClassName="w-1/2"
          onClick={() =>
            deleteAccountUser({
              id: accountId,
              userId: user.id,
            })
          }
        >
          Delete
        </Button>
      </div>
    </>
  );
};
