import classNames from "classnames";

export const CircularArrowIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-5 h-5")}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow-circle-right">
      <path
        id="Icon"
        d="M13.9997 18.6668L18.6663 14.0002M18.6663 14.0002L13.9997 9.3335M18.6663 14.0002H9.33301M25.6663 14.0002C25.6663 20.4435 20.443 25.6668 13.9997 25.6668C7.55635 25.6668 2.33301 20.4435 2.33301 14.0002C2.33301 7.55684 7.55635 2.3335 13.9997 2.3335C20.443 2.3335 25.6663 7.55684 25.6663 14.0002Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
