import { Redirect } from "react-router-dom";

import { SettingsPathnames } from "./Settings";

import { useSearchParams } from "../hooks/useSearchParams";

/*
 * A page containing int18 Unsubscribe page at /unsubscribe.
 */
export const Unsubscribe = () => {
  const { urlSearchParams } = useSearchParams();
  const userToken = urlSearchParams.get("user") ?? undefined;

  return <Redirect to={SettingsPathnames.notifications} />;
};
