import { Redirect } from "react-router-dom";

import { Header } from "../Components/Header";
import { Loading } from "../Components/Loading";
import { ActiveTabs } from "../Components/ActiveTabs";
import { InstancesTable } from "../Components/instance/InstancesTable";
import { useAuthentication } from "../Components/auth/AuthUserProvider";

/**
 * This component show the Management Instance Table
 */
export const Instances = () => {
  const { userInfo, isLoading } = useAuthentication();

  if (isLoading) {
    return <Loading className="pb-4 bg-background min-h-page" />;
  }

  if (userInfo) {
    return (
      <div className="pb-4 bg-background min-h-page">
        <Header
          title="Instances"
          description="See all instances you’ve won."
          secondaryComponent={
            <ActiveTabs
              primaryLabel="Active Instances"
              secondaryLabel="Inactive Instances"
            />
          }
        />
        <InstancesTable />
      </div>
    );
  }

  return <Redirect to="/" />;
};
