import { QueryKey } from "@tanstack/react-query";

import { queryClient } from "../state/queryClient";

export type OptionsType = {
  forceRefetch?: boolean;
  delete?: boolean;
  updater?: "array" | "object";
};

type RequestType = { id?: string } & object;

/**
 * This generic function accepts the Query Key and updates the cache based on the request
 * And when mutation has settled, refetch the data if applicable
 *
 * For key:
 *  - Passed key can only handled single state update, to update same request in multiple keys call this function multiple times
 *
 * For request:
 * - If passed request is empty or does not have id in it, data is refetched through API
 * - If passed request is not empty and:
 *   - ( Priority 1) if previous key state is array, update the array object if matching with passed request id
 *   - ( Priority 2) if previous key state is object, update object if matching with passed request id
 *   - ( Priority 3) if previous key state is object, and the data array exists in the object, update the data array if matching with passed request id
 *
 * For options:
 * - if the previous key is object, and the data array exists in the object, and you only need to update the data array if matching with passed request id, make sure to send updater as "array"
 * - if the previous key is object | array, make sure to send updater as "object" | "array" as required
 * - if you need to force refetch the key, send options as forceRefetch
 * - if you need to delete the data, send options as delete
 */
export const genericCacheHandler = <TRequest extends RequestType>(
  key: QueryKey,
  request?: TRequest,
  options?: OptionsType,
) => {
  queryClient.setQueryData(key, (previousData: any) => {
    if (Array.isArray(previousData) && options?.updater !== "object") {
      const updatedData = getUpdatedData<TRequest>(
        previousData,
        request,
        options,
      );
      return updatedData;
    }

    if (
      typeof previousData?.id === "string" &&
      typeof request?.id === "string" &&
      previousData.id === request.id &&
      options?.updater !== "array"
    ) {
      const updatedData = getUpdatedData<TRequest>(
        [previousData],
        request,
        options,
      );
      return updatedData[0];
    }

    if (
      previousData?.data &&
      Array.isArray(previousData.data) &&
      options?.updater !== "object"
    ) {
      const updatedData = getUpdatedData<TRequest>(
        previousData.data,
        request,
        options,
      );
      return { ...previousData, data: updatedData };
    }

    return previousData;
  });

  if (options?.forceRefetch === true || !request || !request.id) {
    queryClient.invalidateQueries(key);
  }
};

/**
 * This generic mappers takes the current request and previous data
 * and makes sure to update the request to respective list item
 */
const getUpdatedData = <TRequest extends RequestType>(
  previousList: RequestType[] = [],
  request?: TRequest,
  options?: OptionsType,
) => {
  const itemIndex = previousList.findIndex((item) => item.id === request?.id);

  const updatedList = [...previousList];

  if (itemIndex > -1) {
    if (options?.delete) {
      updatedList.splice(itemIndex, 1);
    } else {
      updatedList[itemIndex] = {
        ...updatedList[itemIndex],
        ...request,
      };
    }
  }

  return updatedList;
};
