import classNames from "classnames";

export const EditIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-5 h-5")}
    viewBox="0 0 20 20"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2807_17027)">
      <path
        d="M9.16663 3.33417H5.66663C4.26649 3.33417 3.56643 3.33417 3.03165 3.60666C2.56124 3.84634 2.17879 4.22879 1.93911 4.6992C1.66663 5.23398 1.66663 5.93404 1.66663 7.33417V14.3342C1.66663 15.7343 1.66663 16.4344 1.93911 16.9691C2.17879 17.4396 2.56124 17.822 3.03165 18.0617C3.56643 18.3342 4.26649 18.3342 5.66663 18.3342H12.6666C14.0668 18.3342 14.7668 18.3342 15.3016 18.0617C15.772 17.822 16.1545 17.4396 16.3941 16.9691C16.6666 16.4344 16.6666 15.7343 16.6666 14.3342V10.8342M6.6666 13.3342H8.06206C8.46971 13.3342 8.67353 13.3342 8.86535 13.2881C9.03541 13.2473 9.19798 13.18 9.3471 13.0886C9.5153 12.9855 9.65942 12.8414 9.94767 12.5531L17.9166 4.58417C18.607 3.89382 18.607 2.77453 17.9166 2.08417C17.2263 1.39382 16.107 1.39382 15.4166 2.08417L7.44765 10.0531C7.1594 10.3414 7.01527 10.4855 6.9122 10.6537C6.82082 10.8028 6.75348 10.9654 6.71265 11.1355C6.6666 11.3273 6.6666 11.5311 6.6666 11.9387V13.3342Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2807_17027">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
