import { Fragment, useEffect } from "react";

import { DatePicker } from "./DatePicker";
import { TimePicker } from "./TimePicker";

import { RadioOption } from "./RadioOption";
import { CheckboxOption } from "./CheckboxOption";

import { DropdownSelector } from "./DropdownSelector";

import { CurrencyInput } from "./CurrencyInput";
import { TimeLengthInput } from "./TimeLengthInput";

import { CSVInput } from "./CSVInput";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { DisabledInput } from "./DisabledInput";
import { SearchableInput } from "./SearchableInput";
import { DescriptionInput } from "./DescriptionInput";

import { InputWrapper } from "./InputWrapper";

import { validateErrorAndUpdateState } from "./validator";

import { inputKeyToLabel, inputKeyToPlaceholder } from "./const";

import {
  InputErrorType,
  InputKeyToInputType,
  InputKeyToValidatorType,
  InputKeyType,
  InputOptionType,
  InputSelectType,
  InputStateType,
  InputType,
  InputValueType,
  RequiredInputKeyType,
} from "./types";

const checkIfMultipleValue = (
  value?: InputValueType,
  options?: InputOptionType[],
) => {
  const isValueObject = !!(
    typeof value === "object" &&
    !(value instanceof Date) &&
    options &&
    options.length !== 0
  );

  if (isValueObject) return { value, options, isValueObject };
  return { value, options, isValueObject };
};

const CustomSelect = (props: InputComponentProps) => (
  <DropdownSelector
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    required={props.required}
    options={props.options ?? []}
    isError={!!props.error}
    onChange={props.onChange}
  />
);

const CustomRadio = (props: InputComponentProps) => {
  return (
    <div className="flex flex-col space-y-2">
      {props.options?.map((option) => (
        <RadioOption
          id={option.value}
          key={option.value}
          value={option.value}
          checked={option.required ? true : option.value === props.value}
          disabled={option.disabled}
          required={option.required}
          label={option.label}
          description={option.description}
          onChange={props.onChange}
        />
      ))}
    </div>
  );
};

const CustomCheckbox = ({ value, options, ...props }: InputComponentProps) => {
  const {
    isValueObject,
    value: checkedValue,
    options: checkOptions,
  } = checkIfMultipleValue(value, options);

  return (
    <div className="flex flex-col space-y-2">
      {checkOptions?.map((option) => (
        <CheckboxOption
          id={option.value}
          key={option.value}
          checked={isValueObject && !!checkedValue[option.value]}
          disabled={option.disabled}
          required={option.required}
          label={option.label}
          description={option.description}
          onChange={(newValue) =>
            props.onChange({
              ...(isValueObject ? checkedValue : {}),
              [option.value]: !!newValue,
            })
          }
        />
      ))}
    </div>
  );
};

const CustomCurrency = (
  props: InputComponentProps & {
    isFixed: boolean;
    isNegativeValueAllowed: boolean;
  },
) => (
  <div className="flex flex-col w-full">
    <CurrencyInput
      id={props.id}
      isError={!!props.error}
      setAmount={props.onChange}
      required={props.required}
      disabled={props.isFixed}
      allowNegativeValue={props.isNegativeValueAllowed}
      amount={typeof props.value === "string" ? props.value : ""}
      placeholder={props.id ? inputKeyToPlaceholder[props.id] + "" : ""}
      originalAmount={
        typeof props.originalValue === "string"
          ? +props.originalValue
          : undefined
      }
    />
  </div>
);

const CustomTimeRange = (props: InputComponentProps) => (
  <TimeLengthInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    originalValue={
      typeof props.originalValue === "string" ? props.originalValue : ""
    }
    required={props.required}
    isError={!!props.error}
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    onChange={props.onChange}
  />
);

const CustomDate = (props: InputComponentProps) => (
  <DatePicker
    id={props.id}
    value={props.value instanceof Date ? props.value : undefined}
    required={props.required}
    originalValue={
      props.originalValue instanceof Date ? props.originalValue : undefined
    }
    isError={!!props.error}
    onChange={props.onChange}
  />
);

const CustomTime = (props: InputComponentProps) => (
  <TimePicker
    id={props.id}
    value={props.value instanceof Date ? props.value : undefined}
    required={props.required}
    originalValue={
      props.originalValue instanceof Date ? props.originalValue : undefined
    }
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    isError={!!props.error}
    onChange={props.onChange}
  />
);

const CustomDescription = (props: InputComponentProps) => (
  <DescriptionInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    required={props.required}
    isError={!!props.error}
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    onChange={props.onChange}
  />
);

const CustomText = (props: InputComponentProps & { isEmail: boolean }) => (
  <TextInput
    id={props.id}
    isEmail={props.isEmail}
    value={typeof props.value === "string" ? props.value : ""}
    originalValue={
      typeof props.originalValue === "string" ? props.originalValue : ""
    }
    required={props.required}
    isError={!!props.error}
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    onChange={props.onChange}
  />
);

const CustomSearchableText = (props: InputComponentProps) => (
  <SearchableInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    originalValue={
      typeof props.originalValue === "string" ? props.originalValue : ""
    }
    options={props.options ?? []}
    required={props.required}
    isError={!!props.error}
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    onChange={props.onChange}
  />
);

const CustomNumber = (props: InputComponentProps) => (
  <NumberInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    originalValue={
      typeof props.originalValue === "string" ? props.originalValue : ""
    }
    required={props.required}
    isError={!!props.error}
    placeholder={props.id ? inputKeyToPlaceholder[props.id] : ""}
    onChange={props.onChange}
  />
);

const CustomCSVInput = (props: InputComponentProps) => (
  <CSVInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    required={props.required}
    onChange={props.onChange}
  />
);

const CustomDisabledInput = (props: InputComponentProps) => (
  <DisabledInput
    id={props.id}
    value={typeof props.value === "string" ? props.value : ""}
    required={props.required}
    isValueLarge={
      typeof props.value === "string" ? props.value.length > 500 : false
    }
  />
);

export type InputComponentProps = {
  id?: InputKeyType;
  value?: InputValueType;
  originalValue?: InputValueType;
  required?: boolean;
  options?: InputOptionType[];
  error?: string | null;
  onChange: (value: InputValueType) => void;
};

const getInputComponent = (
  inputKeyType: InputType,
  props: InputComponentProps,
) => {
  switch (inputKeyType) {
    case "select":
      return <CustomSelect {...props} />;
    case "radio":
      return <CustomRadio {...props} />;
    case "checkbox":
      return <CustomCheckbox {...props} />;
    case "any-currency":
    case "currency":
    case "positive-currency":
    case "fixed-currency":
      return (
        <CustomCurrency
          {...props}
          isFixed={inputKeyType === "fixed-currency"}
          isNegativeValueAllowed={inputKeyType === "any-currency"}
        />
      );
    case "time-length":
      return <CustomTimeRange {...props} />;
    case "description":
    case "ssh-key":
      return <CustomDescription {...props} />;
    case "any-date":
    case "future-date":
      return <CustomDate {...props} />;
    case "any-time":
    case "future-time":
      return <CustomTime {...props} />;
    case "text":
    case "email":
      return <CustomText {...props} isEmail={inputKeyType === "email"} />;
    case "searchable-text":
      return <CustomSearchableText {...props} />;
    case "any-number":
    case "number":
    case "positive-number":
    case "gpu-number":
      return <CustomNumber {...props} />;
    case "csv":
      return <CustomCSVInput {...props} />;
    case "disabled":
      return <CustomDisabledInput {...props} />;
  }
};

type InputComponentsProps = {
  state: InputStateType;
  originalState?: InputStateType;
  inputs: InputKeyToInputType;
  required?: RequiredInputKeyType;
  validators?: InputKeyToValidatorType;
  errors: InputErrorType;
  onChange: (state: InputStateType, errors: InputErrorType) => void;
  options?: InputSelectType;
  labelType?: "row" | "column";
};

/**
 * The component which acts as a generic form input
 * It accepts the state and error object and returns back updated value based on user input
 * The structure is defined by inputs objects only
 */
export const GenericInputs = ({
  state,
  originalState,
  inputs,
  required,
  validators,
  errors,
  onChange,
  options,
  labelType = "row",
}: InputComponentsProps) => {
  useEffect(() => {
    if (!!state || !!validators) {
      const { errors: updatedErrors, state: updatedState } =
        validateErrorAndUpdateState(inputs, state, errors, options, validators);
      onChange(updatedState, updatedErrors);
    }
  }, [JSON.stringify(state), JSON.stringify(validators)]);

  const onUpdate = (key: InputKeyType, value: InputValueType) => {
    const newState = {
      ...state,
      [key]: value,
    };

    const { errors: updatedErrors, state: updatedState } =
      validateErrorAndUpdateState(
        inputs,
        newState,
        errors,
        options,
        validators,
      );

    onChange(updatedState, updatedErrors);
  };

  return (
    <>
      {(Object.keys(inputs) as InputKeyType[]).map((key) => {
        const inputKey = inputs[key];
        const props = {
          id: key,
          value: state[key],
          originalValue: originalState?.[key],
          required: required?.[key],
          options: options?.[key],
          error: errors[key],
          onChange: (v: InputValueType) => onUpdate(key, v),
        };

        if (!inputKey) {
          return <Fragment key={key} />;
        }
        return (
          <InputWrapper
            key={key}
            id={props.id}
            label={inputKeyToLabel[props.id]}
            required={props.required}
            error={props.error}
            labelType={labelType}
          >
            {getInputComponent(inputKey, props)}
          </InputWrapper>
        );
      })}
    </>
  );
};
