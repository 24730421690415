import classNames from "classnames";

export const HourGlassIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className, "w-4 h-4")}
    viewBox="0 0 13 13"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="hourglass-03" clipPath="url(#clip0_2833_91398)">
      <path
        id="Icon"
        d="M5 9.75H8M3.8 1.5H9.2C9.48003 1.5 9.62004 1.5 9.727 1.5545C9.82108 1.60243 9.89757 1.67892 9.9455 1.773C10 1.87996 10 2.01997 10 2.3V3.33726C10 3.58185 10 3.70414 9.97237 3.81923C9.94787 3.92127 9.90747 4.01881 9.85264 4.10828C9.7908 4.2092 9.70432 4.29568 9.53137 4.46863L8.06569 5.93431C7.86768 6.13232 7.76867 6.23133 7.73158 6.34549C7.69895 6.44591 7.69895 6.55409 7.73158 6.65451C7.76867 6.76867 7.86768 6.86768 8.06568 7.06568L9.53137 8.53137C9.70432 8.70432 9.7908 8.7908 9.85264 8.89172C9.90747 8.98119 9.94787 9.07873 9.97237 9.18077C10 9.29586 10 9.41815 10 9.66274V10.7C10 10.98 10 11.12 9.9455 11.227C9.89757 11.3211 9.82108 11.3976 9.727 11.4455C9.62004 11.5 9.48003 11.5 9.2 11.5H3.8C3.51997 11.5 3.37996 11.5 3.273 11.4455C3.17892 11.3976 3.10243 11.3211 3.0545 11.227C3 11.12 3 10.98 3 10.7V9.66274C3 9.41815 3 9.29586 3.02763 9.18077C3.05213 9.07873 3.09253 8.98119 3.14736 8.89172C3.2092 8.7908 3.29568 8.70432 3.46863 8.53137L4.93432 7.06568C5.13232 6.86768 5.23133 6.76867 5.26842 6.65451C5.30105 6.55409 5.30105 6.44591 5.26842 6.34549C5.23133 6.23133 5.13232 6.13232 4.93431 5.93431L3.46863 4.46863C3.29568 4.29568 3.2092 4.2092 3.14736 4.10828C3.09253 4.01881 3.05213 3.92127 3.02763 3.81923C3 3.70414 3 3.58185 3 3.33726V2.3C3 2.01997 3 1.87996 3.0545 1.773C3.10243 1.67892 3.17892 1.60243 3.273 1.5545C3.37996 1.5 3.51997 1.5 3.8 1.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2833_91398">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
