import { useEffect, useState } from "react";

import { Dialog } from "@headlessui/react";

import { Button } from "../LegacyButton";

import { KeyIcon } from "../icons/KeyIcon";
import { CloseIcon } from "../icons/CloseIcon";

import { useAddSshKey } from "../../state/sshKey";

import { GenericInputs } from "../forms/GenericInputs";
import { canFormBeSubmitted } from "../forms/validator";

import {
  InputErrorType,
  InputKeyToInputType,
  RequiredInputKeyType,
} from "../forms/types";
import { useAuthentication } from "../auth/AuthUserProvider";

interface AddSshKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const keyToInput: InputKeyToInputType = {
  sshTitle: "text",
  sshKey: "ssh-key",
};

const requiredKey: RequiredInputKeyType = {
  sshTitle: true,
  sshKey: true,
};

const originalState = {
  sshTitle: "",
  sshKey: "",
};

/**
 * This modal takes the key title and public key and send the request through rect-query
 */
export const AddSshKeyModal = ({ isOpen, onClose }: AddSshKeyModalProps) => {
  const { addSshKey, isSuccess } = useAddSshKey();

  const [state, setState] = useState(originalState);
  const [errors, setErrors] = useState<InputErrorType>({});
  const { userInfo, accountId } = useAuthentication();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onChange = (newState: any, newErrors: InputErrorType) => {
    setErrors(newErrors);
    setState(newState);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    addSshKey({
      name: state.sshTitle,
      publicKey: state.sshKey,
      accountId: accountId!,
    });
  };

  const isSubmitButtonEnabled = canFormBeSubmitted({
    state,
    originalState,
    errors,
    required: requiredKey,
  });

  return (
    <Dialog
      id="add-ssh-key-modal"
      open={isOpen}
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
      onClick={(event) => event.stopPropagation()} // prevent parent event
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-black/[.35]"
        aria-hidden="true"
        onClick={onClose}
      />
      <div className="min-h-screen px-4 text-center rounded-md">
        {/* This element centers the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        />

        {/* The actual content on the modal */}
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
          <div className="flex items-center justify-between">
            <div className="p-3 mb-1 rounded-full bg-brand bg-opacity-20 w-fit">
              <KeyIcon className="text-brandHover" />
            </div>
            <Button data-testid="CLOSE_MODAL_BUTTON" onClick={onClose}>
              <CloseIcon className="text-name hover:scale-110" />
            </Button>
          </div>
          <h1
            data-testid="ADD_SSH_KEY_MODAL_HEADER"
            className="text-xl font-semibold leading-10 tracking-wide"
          >
            Add New SSH Key
          </h1>
          <form onSubmit={onSubmit}>
            <div className="flex flex-col pt-2 pb-6 tracking-tight text-left">
              <div className="grid grid-cols-1 gap-4">
                <GenericInputs
                  state={state}
                  originalState={originalState}
                  inputs={keyToInput}
                  required={requiredKey}
                  errors={errors}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex items-center justify-center gap-4 pb-1">
              <Button
                data-testid="CANCEL_BUTTON"
                type="button"
                variant="transparent"
                variantClassName="w-1/2"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                data-testid="ADD_SSH_KEY_BUTTON"
                type="submit"
                variant="dark"
                variantClassName="w-1/2"
                disabled={!isSubmitButtonEnabled}
              >
                Add SSH Key
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
