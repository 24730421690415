import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { PayOrderModal } from "../order/PayOrderModal";
import { isLocal, isDev } from "../../utils/env";
import {
  useGetAccountBalance,
  useGetAccountBillingInfo,
} from "../../state/account";

import { formatMoneyStringWithSign } from "../../utils/currencyFormatter";

function useQuery(paramName: string) {
  // Use get method to retrieve queryParam
  return new URLSearchParams(useLocation().search).get(paramName);
}
/**
 * This component shows the full detail of the billing page
 * It mainly redirects to the user's billing page
 */
export const BillingContainer = ({ accountId }: { accountId: string }) => {
  const { data: balance, isLoading: isBalanceLoading } =
    useGetAccountBalance(accountId);
  const { data: billingInfo, isLoading: isPaymentMethodsLoading } =
    useGetAccountBillingInfo(accountId);
  const isMounted = useRef(false);
  const success = useQuery("success");

  const [showModal, setShowModal] = useState<string | null>(null);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    isMounted.current = true;
    if (success === "true" && !isDev && !isLocal) {
      //@ts-ignore
      window.gtag("config", "AW-16455822329");
      //@ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-16455822329/nqeYCMjzz68ZEPnX36Y9",
      });
    }
  }, []);

  if (isBalanceLoading || isPaymentMethodsLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div>
          <div className="flex flex-col p-4 pt-0">
            <div>
              <h1 className="pb-1 text-xl font-semibold leading-7">Billing</h1>
              <span className="text-base font-normal leading-tight text-secondaryText">
                Add funds and manage your billing info here.
              </span>
            </div>
            <div className="flex flex-col p-4 pt-2 mt-3 space-y-4 bg-white border rounded-lg shadow-md">
              <div className="flex flex-col items-center justify-between gap-2 pt-3 md:flex-row">
                <div className="ml-3">
                  <h3 className="font-semibold">Balance</h3>
                  <span className="font-light text-secondaryText">
                    Your current balance that can be applied to orders.
                  </span>
                </div>
                <h2 className="flex justify-center mr-3 text-lg font-semibold">
                  {formatMoneyStringWithSign(balance ?? 0)}
                </h2>
              </div>
              <div className="flex flex-col items-center justify-between gap-2 pt-4 border-t md:flex-row">
                <div className="ml-3">
                  <h3 className="font-semibold">Billing Info</h3>
                  <span className="font-light text-secondaryText">
                    Manage your billing and payment info.
                  </span>
                </div>
                <a href={billingInfo?.url}>
                  <Button
                    data-testid="VIEW_BILLING_BUTTON"
                    variant="dark"
                    type="button"
                    disabled={!billingInfo?.url}
                    variantClassName="w-[220px] h-[20px] mr-3"
                  >
                    {!billingInfo?.paymentMethodCount
                      ? "Add Payment Method"
                      : "Manage Billing Info"}
                  </Button>
                </a>
              </div>
              <div className="flex flex-col items-center justify-between gap-2 pt-4 border-t md:flex-row">
                <div className="ml-3">
                  <h3 className="font-semibold">Add Funds</h3>
                  <span className="font-light text-secondaryText">
                    To be applied to future orders.
                  </span>
                </div>
                <div>
                  <Button
                    data-testid="WIRE_INSTRUCTIONS_MODAL_BUTTON"
                    variant="transparent"
                    variantClassName="w-[180px] h-[20px] mr-3"
                    onClick={() => setShowModal("pay-order")}
                  >
                    Wire Instructions
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal === "pay-order" && (
        <PayOrderModal
          isOpen={showModal === "pay-order"}
          onClose={() => setShowModal(null)}
        />
      )}
    </>
  );
};
