import { Fragment, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import moment from "moment";
import classNames from "classnames";
import { Virtualizer, useVirtualizer } from "@tanstack/react-virtual";
import {
  Cell,
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { Button } from "../LegacyButton";
import { Loading } from "../Loading";
import { statusToTitle } from "./OrderStatusComponent";
import { TimerComponent } from "../table/TimerComponent";

import { UpArrowIcon } from "../icons/UpArrowIcon";
import { HourGlassIcon } from "../icons/HourGlassIcon";

import { useGetOrderbook } from "../../state/order";
import { useAuthentication } from "../auth/AuthUserProvider";

import { DefaultOrderBook } from "../../utils/orderbook";
import { formatMoneyString } from "../../utils/currencyFormatter";

import { DashboardPathnames } from "../../pages/Dashboard";

import { OrderBook, OrderBookStatus } from "../../types/order";

export const isColoredOrderBook = (status: OrderBookStatus) =>
  ["active", "paused", "winning", "allocating"].includes(status);

const statusToBackgroundColor = {
  eligible: "138, 151, 172", // #8A9FAC
  winning: "34, 197, 94", // #22C55E
  allocating: "34, 197, 94", // #22C55E
  paused: "252, 138, 0", // #FC8A00
  active: "252, 138, 0", // #FC8A00
  closing: "156, 163, 175", // #9CA3AF
  large: "138, 151, 172", // #8A9FAC
};

const userBackgroundColor = "#FEC84B";

const MaxTableHeight = 384; // max-h-96 ie. tailwind standard height
const MinTableHeaderHeight = 32; // min-h-8 ie. tailwind standard height
const MinDescriptionHeight = 160; // min-h-40 ie. tailwind standard height

const getHeightOfRow = (numberOfGPUs = 8) => {
  // Calculate the height based on the multiples of 8 and dynamically adjust the scaling factor
  const scalingFactor = 1 - (Math.min(numberOfGPUs, 800) - 8) / 800; // Dynamic scaling factor
  const height = Math.floor((numberOfGPUs / 8) * 20 * scalingFactor); // For example, returning the input divided by 8 multiplied by 20 and dynamically scaled
  return Math.max(40, height); // Ensure the height is at least 40
};

const getHeightOfOrderBookTable = ({
  upperOrderBooks,
  lowerOrderBooks,
}: {
  upperOrderBooks: OrderBook[];
  lowerOrderBooks: OrderBook[];
}) => {
  // sum of table's rows
  const heightOfUpperOrderBookTableRows = upperOrderBooks.reduce(
    (acc, orderBook) => acc + getHeightOfRow(orderBook.numberOfGPUs),
    0,
  );

  // sum of table's rows
  const heightOfLowerOrderBookTableRows = lowerOrderBooks.reduce(
    (acc, orderBook) => acc + getHeightOfRow(orderBook.numberOfGPUs),
    0,
  );

  // sum of upper table's (rows) + (table header + border) + (padding-top)
  const heightOfUpperOrderBookTable =
    heightOfUpperOrderBookTableRows + MinTableHeaderHeight + 2 + 12;
  const maxHeightOfUpperOrderBookTable =
    upperOrderBooks.length === 1
      ? heightOfUpperOrderBookTable
      : Math.min(heightOfUpperOrderBookTable, MaxTableHeight);

  // sum of lower table's (rows) + (padding-bottom)
  const heightOfLowerOrderBookTable = heightOfLowerOrderBookTableRows + 12;
  const maxHeightOfLowerOrderBookTable =
    lowerOrderBooks.length === 1
      ? heightOfLowerOrderBookTable
      : Math.min(heightOfLowerOrderBookTable, MaxTableHeight);

  return {
    heightOfUpperOrderBookTable,
    maxHeightOfUpperOrderBookTable,

    heightOfLowerOrderBookTable,
    maxHeightOfLowerOrderBookTable,
  };
};

const FABButton = ({
  visible = true,
  position = "bottom",
  rowVirtualizer,
}: {
  visible?: boolean;
  position?: "top" | "bottom";
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
}) => {
  const isArrowUp = useMemo(() => {
    const { scrollOffset, getVirtualItemForOffset, getTotalSize } =
      rowVirtualizer;

    const centralHeight = getTotalSize() / 2;
    const endOfOffsetItem = getVirtualItemForOffset(scrollOffset).end;

    return endOfOffsetItem >= centralHeight;
  }, [rowVirtualizer.isScrolling]);

  if (!visible) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        "sticky z-50 right-0 flex px-3 items-end justify-end",
        {
          "top-9": position === "top",
          "bottom-3": position === "bottom",
        },
      )}
    >
      <Button
        className="absolute flex items-center justify-center p-1 text-white rounded-full shadow-xl bg-[#334155]"
        onClick={() => {
          rowVirtualizer.scrollToOffset(
            isArrowUp ? 0 : rowVirtualizer.getTotalSize(),
          );
        }}
      >
        <UpArrowIcon
          className={classNames({
            "rotate-180": isArrowUp,
          })}
        />
      </Button>
    </div>
  );
};

const ActiveOrderDescription = () => (
  <div
    className="flex flex-col justify-start gap-3 px-3 text-secondaryText"
    style={{ minHeight: `${MinDescriptionHeight}px` }}
  >
    <p>
      <span className="font-semibold">Jobs in gray</span> are under the market
      minimum. Gray jobs above are active jobs that are now closing because the
      market has moved above their maximum bid.
    </p>
    <p>
      The gray jobs to the right have failed to beat the market price, and won’t
      allocate unless the market falls or their bids improve.
    </p>
  </div>
);

const PendingOrderDescription = ({
  currentPricePerGPU = 0,
}: {
  currentPricePerGPU?: number;
}) => (
  <div
    className="flex flex-col justify-end gap-3 px-3 text-secondaryText"
    style={{ minHeight: `${MinDescriptionHeight}px` }}
  >
    <p>
      At left, the <span className="font-semibold">jobs in orange</span> have
      bid above the current market price, and their machines are allocated and
      active. Customers are all paying{" "}
      <span className="font-semibold">{`${formatMoneyString(
        currentPricePerGPU,
      )}/GPU hr`}</span>
      .
    </p>
    <p>
      <span className="font-semibold">Jobs in green</span> below are recent
      orders above the market minimum, and they have been allocated and are
      being set up. They will go live soon.
    </p>
  </div>
);

interface RowDataProps {
  cell: Cell<OrderBook, unknown>;
  rowsLength: number;
}

// Function to get the row information within the table body
const RowData = ({ cell, rowsLength }: RowDataProps) => {
  const orderBook = cell.row.original;

  const { accountId } = useAuthentication();
  const isUsersOrder = accountId && accountId === orderBook.accountId;

  const { data } = useGetOrderbook();

  if (orderBook.id === "default") {
    if (!cell.id.includes("status")) {
      return <></>;
    }
    return <></>;
    return (
      <td
        className="flex items-center w-full px-1 py-1.5 bg-[#D0D5DD] text-secondaryText justify-center border"
        style={{
          height: getHeightOfRow(orderBook.numberOfGPUs),
        }}
      >
        {`There are no ${orderBook.status} orders`}
      </td>
    );
  }

  if (orderBook.id === "unallocated") {
    if (!cell.id.includes("status") || !data) {
      return <></>;
    }

    return (
      <td
        className="flex items-center w-full px-1 py-1.5 bg-[#667085] text-white pl-4"
        style={{
          height: getHeightOfRow(orderBook.numberOfGPUs),
        }}
      >
        {`${
          data.unallocatedNumberOfGPUs + data.allocatedNumberOfGPUs
        } Total GPUs Available on Exchange`}
      </td>
    );
  }

  const backgroundRGB = statusToBackgroundColor[orderBook.status];
  const backgroundOpacity = isColoredOrderBook(orderBook.status)
    ? 1 - cell.row.index / rowsLength
    : (cell.row.index + 1) / rowsLength;

  const wrapperStyle: React.CSSProperties = {
    height: getHeightOfRow(orderBook.numberOfGPUs),
    backgroundColor: `rgba(${backgroundRGB}, ${backgroundOpacity})`,
  };

  if (isUsersOrder) {
    const boxShadowStyle: Record<string, string> = {
      numberOfGPUs: "inset 5px 5px 5px -5px #FFF, inset 5px -5px 5px -5px #FFF",
      status: "inset 0px 5px 5px -5px #FFF, inset 0px -5px 5px -5px #FFF",
      startAt: "inset -5px 5px 5px -5px #FFF, inset -5px -5px 5px -5px #FFF",
      endAt: "inset -5px 5px 5px -5px #FFF, inset -5px -5px 5px -5px #FFF",
    };
    wrapperStyle.boxShadow = boxShadowStyle[cell.column.id];
    wrapperStyle.backgroundColor = userBackgroundColor;
  }

  return (
    <td
      key={cell.id}
      className={classNames("flex items-center px-2 py-1.5", {
        "min-w-[80px]": cell.id.includes("numberOfGPUs"),
        "min-w-[165px]": cell.id.includes("endAt"),
        "min-w-[100px]": cell.id.includes("startAt"),
        "w-full justify-center": cell.id.includes("status"),
      })}
      style={wrapperStyle}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const DefaultData: OrderBook[] = [];

const columnHelper = createColumnHelper<OrderBook>();

interface OrderBookTableProps {
  type: "active" | "pending";
  orderBook: OrderBook[];
  isHeaderVisible?: boolean;
  isDetailsVisible?: boolean;
  maxHeight: number;
}

const OrderBookTable = ({
  type,
  orderBook,
  isHeaderVisible = true,
  isDetailsVisible = true,
  maxHeight,
}: OrderBookTableProps) => {
  const { pathname } = useLocation();
  const isAdmin = pathname === DashboardPathnames.inventory;

  const { push } = useHistory();

  const { accountId } = useAuthentication();

  // The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const memoColumns = useMemo(
    () => [
      columnHelper.accessor("numberOfGPUs", {
        id: "numberOfGPUs",
        header: "# of GPUs",
        cell: ({ cell }) => cell.getValue(),
      }),
      columnHelper.accessor("status", {
        id: "status",
        header: "",
        cell: ({ cell, row: { original } }) => {
          const getCellComponent = () => {
            if (isAdmin || (accountId && accountId === original.accountId)) {
              return `${formatMoneyString(original.pricePerGPU)} (${
                isAdmin ? statusToTitle[original.status] : "Your"
              } Order: ${original.id})`;
            }
            if (!!isDetailsVisible) {
              if (cell.getValue() === "closing") {
                return "Closing Soon";
              }
              if (cell.getValue() === "winning") {
                return "Winning";
              }
              return "Allocating";
            }

            return <></>;
          };

          return (
            <span
              className={classNames("mix-blend-difference", {
                "hover:underline hover:cursor-pointer":
                  isAdmin || (accountId && accountId === original.accountId),
                "text-gray-300": !isColoredOrderBook(cell.getValue()),
                "text-gray-500": isColoredOrderBook(cell.getValue()),
              })}
              onClick={() => {
                if (isAdmin) {
                  push(`${DashboardPathnames.orders}?orderId=${original.id}`);
                }
                if (!isAdmin && accountId && accountId === original.accountId) {
                  push(`/orders?orderId=${original.id}`);
                }
              }}
            >
              {getCellComponent()}
            </span>
          );
        },
      }),
      columnHelper.accessor("startAt", {
        id: "startAt",
        header: "Estimated Start",
        cell: ({ cell, row: { original } }) => {
          if (
            !!isDetailsVisible ||
            isAdmin ||
            (accountId && accountId === original.accountId)
          ) {
            const date = cell.getValue();
            if (!date) {
              return <></>;
            }

            return (
              <div className="flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-full bg-background truncate">
                <HourGlassIcon className="mr-1 text-name" />
                <span title={moment(date).format("LLL")}>
                  {moment(date).format("MMM DD")}
                </span>
              </div>
            );
          }

          return <></>;
        },
      }),
      columnHelper.accessor("endAt", {
        id: "endAt",
        header: "Time Remaining",
        cell: ({ cell, row: { original } }) => {
          if (
            !!isDetailsVisible ||
            isAdmin ||
            (accountId && accountId === original.accountId)
          ) {
            const date = cell.getValue();
            if (!date) {
              return <></>;
            }

            return (
              <TimerComponent deadline={date} renderAfterDeadline={<></>} />
            );
          }

          return <></>;
        },
      }),
    ],
    [isDetailsVisible, accountId, isAdmin],
  );

  const table = useReactTable({
    data: orderBook ?? DefaultData,
    columns: memoColumns,
    state: {
      columnVisibility: {
        endAt: type === "active",
        startAt: type === "pending",
      },
    },
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: (index) => getHeightOfRow(rows[index].original.numberOfGPUs), //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: rows.length,
  });

  useEffect(() => {
    if (accountId && rowVirtualizer.getVirtualItems().length !== 0) {
      const accountIds = rows.map(({ original }) => original.accountId);
      const scrollToIndex = isHeaderVisible
        ? accountIds.lastIndexOf(accountId)
        : accountIds.indexOf(accountId);
      if (scrollToIndex >= 0) {
        rowVirtualizer.scrollToIndex(scrollToIndex);
      }
    }
  }, [accountId, rowVirtualizer.getVirtualItems().length]);

  return (
    <div
      ref={tableContainerRef}
      className={classNames("relative z-10 overflow-x-auto bg-background", {
        "rounded-t-lg": isHeaderVisible,
        "rounded-b-lg": !isHeaderVisible,
      })}
      style={{ maxHeight: `${maxHeight}px` }}
    >
      <FABButton
        visible={
          !isHeaderVisible &&
          rowVirtualizer.getVirtualItems().length > 1 &&
          maxHeight < rowVirtualizer.getTotalSize()
        }
        position="top"
        rowVirtualizer={rowVirtualizer}
      />
      <table
        className={classNames("grid px-3 text-sm text-left", {
          "pt-3": isHeaderVisible,
          "pb-3": !isHeaderVisible,
        })}
      >
        {/* Table column names */}
        {isHeaderVisible && (
          <thead className="sticky top-0 z-10 grid border select-none bg-background">
            {isHeaderVisible &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="flex justify-between">
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="px-2 py-1.5">
                      {header.isPlaceholder && <></>}
                      {!header.isPlaceholder && (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
        )}
        {/* Table data */}
        <tbody
          className="relative grid"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                className="absolute flex justify-between w-full bg-white"
                style={{
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Fragment key={cell.id}>
                    <RowData cell={cell} rowsLength={rows.length} />
                  </Fragment>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <FABButton
        visible={
          isHeaderVisible &&
          rowVirtualizer.getVirtualItems().length > 1 &&
          maxHeight < rowVirtualizer.getTotalSize()
        }
        position="bottom"
        rowVirtualizer={rowVirtualizer}
      />
    </div>
  );
};

const OrderBookTableContainer = ({
  title,
  upperTable,
  lowerTable,
}: {
  title: string;
  upperTable: JSX.Element;
  lowerTable?: JSX.Element;
}) => {
  return (
    <div>
      <div className="border-8 rounded-t-lg border-slate-700 bg-slate-700">
        <h3
          className="relative z-10 pb-2 text-base font-semibold leading-normal text-center capitalize text-background bg-slate-700"
          style={{ minHeight: `${MinTableHeaderHeight}px` }}
        >
          {title}
        </h3>
        {upperTable}
      </div>
      {lowerTable && (
        <div className="border-8 border-t-0 rounded-b-lg border-slate-300">
          {lowerTable}
        </div>
      )}
    </div>
  );
};

const CurrentPrice = ({
  currentPricePerGPU,
}: {
  currentPricePerGPU: number;
}) => {
  return (
    <div
      className={classNames(
        "relative",
        `before:content-[""] before:bg-[#334155] before:z-0 before:h-2 before:w-[30vw]`,
        `after:content-[""] after:bg-[#334155] after:z-0 after:h-2 after:w-[30vw]`,
        `before:absolute before:-translate-y-1/2 before:right-full before:top-1/2`,
        `after:absolute after:-translate-y-1/2 after:left-full after:top-1/2`,
      )}
      style={{ minHeight: `${MinDescriptionHeight}px` }}
    >
      <div className="border-8 rounded-lg border-slate-700 bg-slate-700">
        <h3
          className="relative z-10 pb-2 text-base font-semibold leading-normal text-center capitalize text-background bg-slate-700"
          style={{ minHeight: `${MinTableHeaderHeight}px` }}
        >
          Current Price
        </h3>
        <div className="relative z-10 px-6 pb-3 font-semibold text-center rounded-lg bg-background">
          <div className="text-2xl leading-loose">
            {formatMoneyString(currentPricePerGPU ?? 0)}
          </div>
          <div className="text-sm leading-none text-tertiaryText">(GPU/hr)</div>
        </div>
      </div>
    </div>
  );
};

/**
 * Display the table of gpu order book
 * This component shows the current price differentiated by locked in and estimates
 */
export const OrderBookContainer = () => {
  const { accountId } = useAuthentication();
  const { data, isLoading } = useGetOrderbook(accountId!);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <Loading
        className="flex justify-center p-4 font-semibold"
        description="No orders found!"
      />
    );
  }

  const runningOrderBooks: OrderBook[] =
    data.runningOrderBooks.length === 0
      ? [{ ...DefaultOrderBook, status: "active", id: "default" }]
      : data.runningOrderBooks;
  const closingOrderBooks: OrderBook[] = [
    ...data.closingOrderBooks,
    {
      ...DefaultOrderBook,
      numberOfGPUs: data.unallocatedNumberOfGPUs,
      status: "closing",
      id: "unallocated",
    },
  ];

  const {
    maxHeightOfUpperOrderBookTable: maxHeightOfRunningTable,
    maxHeightOfLowerOrderBookTable: maxHeightOfClosingTable,
  } = getHeightOfOrderBookTable({
    upperOrderBooks: runningOrderBooks,
    lowerOrderBooks: closingOrderBooks,
  });

  const allocatingOrderBooks: OrderBook[] =
    data.allocatingOrderBooks.length === 0
      ? [{ ...DefaultOrderBook, status: "allocating", id: "default" }]
      : data.allocatingOrderBooks;
  const notRunningOrderBooks: OrderBook[] =
    data.notRunningOrderBooks.length === 0
      ? [{ ...DefaultOrderBook, status: "eligible", id: "default" }]
      : data.notRunningOrderBooks;

  const {
    maxHeightOfUpperOrderBookTable: maxHeightOfAllocatingTable,
    maxHeightOfLowerOrderBookTable: maxHeightOfNotRunningTable,
  } = getHeightOfOrderBookTable({
    upperOrderBooks: allocatingOrderBooks,
    lowerOrderBooks: notRunningOrderBooks,
  });

  // sum of:
  //   (height of allocating table)
  // + (height of description + gap)
  const heightOfAllocatingTableAndDescription =
    maxHeightOfAllocatingTable + (MinDescriptionHeight + 12);

  // difference of:
  //   (height of allocating table + description)
  // - (height of running table)
  const topOfActiveOrderBookContainer = Math.max(
    heightOfAllocatingTableAndDescription - maxHeightOfRunningTable,
    0,
  );

  // difference of:
  //   (height of running table)
  // - (height of allocating table + description)
  const topOfPendingOrderBookContainer = Math.max(
    maxHeightOfRunningTable - heightOfAllocatingTableAndDescription,
    0,
  );

  // difference of:
  //   (top of pending orderbook container)
  // + (height of allocating table + description)
  // + (current price's container header height + border)
  // - (height of current price / 2 - transform height)
  const topOfCurrentPrice =
    topOfPendingOrderBookContainer +
    heightOfAllocatingTableAndDescription +
    (MinTableHeaderHeight + 8) -
    (MinDescriptionHeight / 2 - 4);

  return (
    <div className="flex justify-between gap-4 my-3 overflow-x-auto">
      <div
        className="flex flex-col w-full h-full gap-3 min-w-[635px]"
        style={{ marginTop: topOfActiveOrderBookContainer }}
      >
        <OrderBookTableContainer
          title="Active Orders"
          upperTable={
            <OrderBookTable
              type="active"
              isDetailsVisible={false}
              orderBook={runningOrderBooks}
              maxHeight={maxHeightOfRunningTable}
            />
          }
          lowerTable={
            <OrderBookTable
              type="active"
              isHeaderVisible={false}
              orderBook={closingOrderBooks}
              maxHeight={maxHeightOfClosingTable}
            />
          }
        />
        <ActiveOrderDescription />
      </div>
      <div
        className="flex w-full h-full gap-3"
        style={{ marginTop: topOfCurrentPrice }}
      >
        <CurrentPrice currentPricePerGPU={data.currentPricePerGPU} />
      </div>
      <div
        className="flex flex-col w-full h-full gap-3 min-w-[575px]"
        style={{ marginTop: topOfPendingOrderBookContainer }}
      >
        <PendingOrderDescription currentPricePerGPU={data.currentPricePerGPU} />
        <OrderBookTableContainer
          title="Pending Orders"
          upperTable={
            <OrderBookTable
              type="pending"
              orderBook={allocatingOrderBooks}
              maxHeight={maxHeightOfAllocatingTable}
            />
          }
          lowerTable={
            <OrderBookTable
              type="pending"
              isHeaderVisible={false}
              isDetailsVisible={false}
              orderBook={notRunningOrderBooks}
              maxHeight={maxHeightOfNotRunningTable}
            />
          }
        />
      </div>
    </div>
  );
};
