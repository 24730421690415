import classNames from "classnames";

import { OrderStatus } from "../../types/order";

const statusToStyles = {
  not_ready:
    "text-negative bg-negative bg-opacity-10 border-negative border-opacity-30",
  eligible: "bg-white",
  large:
    "text-negative bg-negative bg-opacity-10 border-negative border-opacity-30",
  allocating: "bg-white",
  winning: "bg-white",
  paused: "bg-white",
  active:
    "text-positive bg-positive bg-opacity-10 border-positive border-opacity-30",
  closing:
    "text-negative bg-negative bg-opacity-10 border-negative border-opacity-30",
  finished: "",
  deleted: "bg-background",
};

const statusToDotStyles = {
  not_ready: "bg-negative",
  eligible: "bg-tertiaryText",
  large: "bg-negative",
  allocating: "bg-positive",
  winning: "bg-positive",
  paused: "bg-tertiaryText",
  active: "bg-positive",
  closing: "bg-negative",
  finished: "bg-brand",
  deleted: "bg-tertiaryText",
};

export const statusToTitle = {
  not_ready: "Not Ready",
  eligible: "Eligible",
  large: "Too Large",
  allocating: "Allocating",
  winning: "Winning",
  paused: "Paused",
  active: "Active",
  closing: "Closing",
  finished: "Finished",
  deleted: "Deleted",
};

/**
 * This component handles how status is shown on the Orders Table
 */
export const OrderStatusComponent = ({ status }: { status: OrderStatus }) => {
  return (
    <div className="flex flex-col max-w-[150px] gap-1">
      <span
        className={classNames(
          "w-fit flex items-center px-2 py-0.5 text-sm font-medium text-center border rounded-lg truncate",
          statusToStyles[status],
        )}
      >
        <div
          className={classNames(
            "w-1.5 h-1.5 mr-2 rounded-full",
            statusToDotStyles[status],
          )}
        />
        {statusToTitle[status]}
      </span>
    </div>
  );
};
